<style>
  .ColorPickerInlinePopupElement .v-btn { min-width: 0 !important; width:fill-available; border-radius: 20px; }
</style>

<template>
  <element :class="classname">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn :color="color" :dark="isDark" v-on="on" class="pa-0 mt-3"> <v-icon>mdi-palette</v-icon> </v-btn>
      </template>
      <v-color-picker v-model="color" _hide-canvas hide-inputs show-swatches class="mx-auto"></v-color-picker>
    </v-menu>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "ColorPickerInlinePopupElement";

    const APP = window.APP;
    const _h = APP.helper;

    //const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    //const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:function(){ return { demo:"Hello World" } } },
        value: { type:String, default:function(){ return "#28566e"; } },        
      },

      data: function(){ return {
        classname: classname,

        color: undefined,
      } },

      computed: {
        isDark: function(){
          let method="isDark"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          //console.log(preLog, self.color, _h.getContrastYIQ(self.color), _h.getContrastYIQ(self.color)=="black" );
          return _h.getContrastYIQ(self.color)=="white";
        }
      },

      watch: {
        value: {
          immediate : true,
          handler: function(newVal,oldVal){
            let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            self.color = newVal;
          }
        },
        color: function(newVal, oldVal){
          let method="color"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
          const self = this;
          this.$emit('input', newVal);
          this.$emit('change', newVal);
        }
      },

      methods: {},

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
