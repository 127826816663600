<style>
  .LoginView {  }
</style>

<template>
  <element :class="classname">

      <v-row class="h100" align="center" justify="center">
        <v-col cols=12 sm=8 md=5 lg=4 xl=3>

          <v-sheet elevation="24">
            <v-row align="center" justify="center">
              <v-col cols=12> <a href="/"><v-img contain max-height="100" style="margin-top: -10%;" src="../../assets/img/logo.png"></v-img></a> </v-col>

              <v-col cols=12 >
                <v-row align="center" justify="center">
                  <v-col cols=12 v-if="user&&user.image&&user.image.src" class="text-center"> <v-avatar size="100"> <v-img :src="user.image.src"></v-img> </v-avatar> </v-col>
                  <v-col cols=12 v-if="user" class="text-center"> <h3>{{user.username}}</h3> </v-col>
                  <v-col cols=10> <ComponentsElement ref="loginForm" :key="loginForm_key" :view="loginFormModel" :error="loginForm_error" v-model="loginFormData"></ComponentsElement> </v-col>
                  <v-col cols=10 sm=8 md=6 lg=5 xl=4 class="mt-0 text-center"> <v-btn width=100% color="accent" @click="login"> {{ $t("LoginView.login") }} </v-btn> </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-sheet>

        </v-col>
      </v-row>

  </element>
</template>


<script type="text/javascript">
/*
*	@author		HM
*	@date: 		2020-05-08
*	@version	0.0.1
*	@updated	2020-05-08
*
* @link       http://
*
*	@param		{Object} 	args 			Default argument object.
*	@param		{type} 		args.key 		description
*
*	@return 	{Object} 	_t.public 			Instance of this class.
*/
'use strict';
export default (function(args){
  // Private
  //------------
  const classname = "LoginView";

  const APP = window.APP;
  const _h = APP.helper;
  const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
  const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

  // Public
  //------------
  const _public = {
    template: '#'+classname,

    components: {
      ComponentsElement : APP.Templates.ComponentsElement
    },

    props: [],
    data: function(){ return {
        classname: classname,

        loginForm_key: 0,
        loginFormModel: {
          username: { is:"v-text-field", cbind: { class:"" }, bind: { label:"Benutzername", type:"text", 'prepend-icon' : "mdi-id-card" } },
          password: { is:"v-text-field", cbind: { }, bind: { label:"Passwort", type:"password", 'prepend-icon' : "mdi-dialpad", }, },
        },
        loginFormData: {
          username: "",
          password: "",
        },
        loginForm_error: undefined,

    }; },

    computed: {
      appState: function(){
        let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
        const self=this;
        let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
        return appState;
      },

      user: function(){
        let method="user"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
        const self=this;
        const appState = self.appState;
        let user = undefined;
        const params = self.$route?.params; //console.log(preLog, "params:",params);
        if (typeof(params?.id)!=="undefined"){
          user = JSON.parse(JSON.stringify( (appState.device?.allowedUsers||{})[params.id] || {} ));
          if ( !user.image ){ user.image={} }
          user.image.src = "/assets/img/user.png";
          let resourceImage = (appState?.app_resources||[]).find(function(item,index){ return item.id==user.image.id });
          if ( user?.image?.id && resourceImage ){
            user.image = resourceImage
            user.image.src = "data:"+user.image.contentType+";base64,"+user.image.base64EncodedContent;
          }
          if ( user.username) {
            self.loginFormModel.username.cbind.class = "hidden";
            self.loginFormData.username = user.username;
          }
        }
        //console.log(preLog, user);
        return user;
      }
    },

    watch: {},

    methods: {
      login: function(){
        let method="login"; let preLog=classname+"."+method; //console.log(preLog, this.loginFormData);
        const self = this;
        const appState = self.appState;
        const allowedUsers = appState.device?.allowedUsers;
        const formData = self.loginFormData;

        APP.global.getOverlay().show();
        if ( allowedUsers && Object.keys(allowedUsers).length>0 ){

          let allowedUser = undefined;
          for ( let allowedUserIndex in allowedUsers ){
            allowedUser = allowedUsers[allowedUserIndex];
            if ( allowedUser.username===formData.username ){ break; }
            else{ allowedUser=undefined; }
          }

          if ( allowedUser ){
            //console.log( "allowedHash:"+allowedUser.passwordHash,"===",_h.SHA1(formData.password||"")+":entered hash", formData.password );
            if ( allowedUser.passwordHash===_h.SHA1(formData.password||"") ){
              APP.global.getOverlay().show();
              appState.local.userActive = allowedUser;
              self.$store.update();
              notify({type:"success", text:i18n(classname+".loginSuccessful"), timeout:1000 });
              setTimeout( function(){ APP.global.route.change("BookingView"); }, 1000 );
            }else{
              let msg = i18n(classname+".invalidPassword");
              notify({type:"error", text:msg});
            }
          }else{
            let msg = i18n(classname+".invalidUsername");
            notify({type:"error", text:msg});
          }

        }else{ notify({type:"error", text:i18n(classname+".invalidUser") }); }

        self.loginFormData.password = "";
        APP.global.getOverlay().hide();
      },

    },

    //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    created: async function(){
      let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
      const self=this; if( self && self._uid ){ _public.component=self; }
      APP.global.getOverlay().clear();
      APP.global.getOverlay().show();
    },

    //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    mounted: async function(){
      let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called", "!!! ");
      const self=this;

      APP.global.basepage.change("BlankBasePage");

      if(typeof(self.$nextTick)==="function"){
        self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
          let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
          APP.global.getOverlay().hide();
        });
      }
    },
    //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

  }

  return _public;
})();
</script>
