<style>
    .BlankBasePage .container { padding: 0px; height: 100vh; }
</style>


<template>
  <element :class="classname">
    <v-main>
      <v-container fill-height fluid>
        <v-row align="stretch" justify="center" dense no-gutters style="height:100%; max-width:100%;">
          <v-col cols=12>
            <!-- component matched by the route will render here -->
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </element>
</template>


<script type="text/javascript">
    /*
    *	@author		HM
    *	@date: 		2019-02-10
    *	@version	0.1.0
    *	@updated	2019-02-10
    *
    * @link       http://
    *
    *	@param		{Object} 	args 			Default argument object.
    *	@param		{type} 		args.key 		description
    *
    *	@return 	{Object} 	_t.public 			Instance of this class.
    */
    'use strict';
    export default (function(args){
        // Private
        //------------
        const classname = "BlankBasePage";

        const APP = window.APP;
        const _h = APP.helper;

        // Public
        //------------
        const _public = {
            classname: classname,
            template : '#'+classname,
            props: [],
            data: function(){ return {
                classname: classname,
            }; },
            computed: {},
            watch: {},
            methods: {},
            components: {},
            created: async function(){
              let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
              const self = this;  _public.component=this;
            },
            mounted: function(){
                let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
                const self = this;
            },
        }

        // MAIN
        //------------
        //if (typeof(args)!=="object"){ console.warn(classname, "Missing important parameter."); return; }

        //if ( typeof(args.title)==="string" || typeof(args.title)==="number" ){ _protected.title=args.title; }
        //else{ console.warn(classname, "Missing important parameter. args.title"); return; }

        return _public;
    })()
</script>
