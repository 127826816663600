<style>
  .DeviceRegisterView {  }
</style>

<template>
  <element :class="classname">

    <v-sheet class="view">
      <v-row class="h100" align="center" justify="center">
        <v-col cols=12 sm=8 md=5 lg=4 xl=3>

          <v-sheet elevation="24">
            <v-row align="center" justify="center">
              <v-col cols=12> <a href="/"> <v-img contain max-height="100" style="margin-top: -10%;" src="../../assets/img/logo.png"></v-img> </a> </v-col>
              <v-col cols=10> <ComponentsElement ref="loginForm" :view="loginFormModel" v-model="loginFormData"></ComponentsElement> </v-col>

            </v-row>
            <v-row align="center" justify="center">
              <v-col cols=10 sm=8 md=6 lg=5 xl=4 class="mt-0 text-center"> <v-btn width=100% color="accent" @click="registerDevice"> {{$t("DeviceRegisterView.register")}}  </v-btn> </v-col>
              <v-col cols=12 class="text-center"> <v-btn color="primary" text to="QrScannerView">{{$t("DeviceRegisterView.scanQrCode")}}</v-btn> </v-col>
            </v-row>
          </v-sheet>

        </v-col>
      </v-row>
    </v-sheet>

  </element>
</template>


<script type="text/javascript">
/*
*	@author		HM
*	@date: 		2020-05-08
*	@version	0.0.1
*	@updated	2020-05-08
*
* @link       http://
*
*	@param		{Object} 	args 			Default argument object.
*	@param		{type} 		args.key 		description
*
*	@return 	{Object} 	_t.public 			Instance of this class.
*/
'use strict';
export default (function(args){
  // Private
  //------------
  const classname = "DeviceRegisterView";

  const APP = window.APP;
  const _h = APP.helper;

  const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
  const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

  // Public
  //------------
  const _public = {
    template: '#'+classname,

    components: {
      ComponentsElement : APP.Templates.ComponentsElement
    },

    props: [],
    data: function(){ return {
        classname: classname,

        loginFormModel: {
          licenseId: { is:"v-text-field", bind: { label: i18n(classname+".licenseId"), type:"text", 'prepend-icon' : "mdi-account" } },
          registerKey: { is:"v-text-field", bind: { label:i18n(classname+".registerKey"), type:"text", 'prepend-icon' : "mdi-devices", }, on: { 'keyup': this.onKeyup } },
        },
        loginFormData: {},

    }; },

    computed: {
      appState: function(){
        let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
        const self=this;
        let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
        return appState;
      },
    },

    watch: {
      //local_appstate: function(){ let method="local_appstate"; let preLog=classname+".watch."+method; console.log(preLog, "called", "this:",this.local_appstate); }
    },

    methods: {
      onKeyup: function(event){
        let method="onKeyup"; let preLog=classname+"."+method; //console.log(preLog, event);
        const self = this;
        if ( event.keyCode == 13 || event.code=="Enter" ){
          self.registerDevice();
        }
      },

      checkCookieState: function(){
        let method="checkCookieState"; let preLog=classname+".methods."+method; console.log(preLog, "called");
        const self=this;
        APP.api.send("Auth","Info", null, {
          success: function(res){
            if ( res.success ){
              APP.api.send("Auth","Logout", null, {
                success: function(res){},
                error: function(err){ console.error(preLog, err); },
                //complete: function(){},
              });
            }
          },
          error: function(err){ console.error(preLog, err); },
          //complete: function(){},
        }, "rest" );
      },

      registerDevice: function(){
        let method="registerDevice"; let preLog=classname+".methods."+method; console.log(preLog, "called");
        const self=this;

        //if ( typeof(APP.api.clientSaasRest.isOnline)!=="function" && !APP.api.clientSaasRest.isOnline() ){
        if ( !_h.isOnline() ){
          notify( { type:"info", text:i18n("error.serviceOnlineOnly") });
          return;
        }

        APP.global.getOverlay().show();

        let formData = JSON.parse(JSON.stringify(self.loginFormData));
        APP.api.send("Auth", "RegisterDevice", formData, {
          success: async function(response){
            //console.log(preLog, response);
            if ( response && typeof(response)==="object" && response.deviceKey && response.deviceId ){
              if ( !self.appState.local ){ self.appState.local = {} }
              self.appState.local["auth_registerdevice"] = response;
              self.$store.update(self.appState);
              APP.global.route.change(APP.config.startpage, true);
            }else{
              let eMsg= i18n("error.invalidServerResponseData");
              console.error(preLog, response);
              notify({type:"error", text:eMsg});
            }
          },
          error: function(errorMsg, eCode){
            console.error(preLog, errorMsg, eCode);
            notify({type:"error", text:errorMsg});
          },
          complete: function(){ APP.global.getOverlay().hide(); }
        }, "rest" );
      },

    },

    //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    created: async function(){
      let method="created"; let preLog=classname+"."+method; console.log(preLog, "called");
      const self = this; if( this && this._uid ){ _public.component=this; }
      APP.global.getOverlay().clear();
      APP.global.getOverlay().show();
    },

    //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    mounted: async function(){
      let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called", "!!! ");
      const self=this;

      if ( self.appState?.local?.auth_registerdevice?.deviceId && self.appState?.local?.auth_registerdevice?.deviceKey ){
        // has NO "appState.auth_registerdevice" Data
        //console.log(preLog, "has no 'appState.auth_registerdevice' Data", appState.auth_registerdevice);
        APP.global.route.change(APP.config.startpage);
        return;
      }

      APP.global.basepage.change("BlankBasePage");

      if(typeof(self.$nextTick)==="function"){
        self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
          let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");

          self.checkCookieState();

          const params=self.$route?.params; //console.log(preLog, "params:",params);
          self.loginFormData = {
            licenseId: params?.licenseId||"",
            registerKey: params?.registerKey||"",
          }
          //console.log(preLog, params, self.loginFormData);
          if (params?.licenseId && params?.registerKey){ self.registerDevice(); }

          APP.global.getOverlay().hide();
        });
      }
    },

    //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

  }

  return _public;
})();
</script>
