<style>
  .SplashscreenView .wallpaper{
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
  }

</style>

<template>
  <element :class="classname">
    <div class="wallpaper" v-resize="onResize" :style="getWallpaperStyle">
      <div class="absolute-middel-center text-center" style="top: 65%">

        <v-sheet v-if="data_errorMsg" class="pa-6 rounded" style="background-color: rgba( 0, 0, 0, 0.6);">
          <p class="mb-8 title error--text">{{data_errorMsg}}</p>
          <v-btn color="primary darken-1" class="mt-2" elevation=5 @click="reloadPage"> {{ $t("global.retry")}} <v-icon class="ml-2">mdi-reload</v-icon> </v-btn>
          <v-btn v-if="unkownDeviceState" class="ml-2 mt-2" color="accent darken-1" elevation=5 to="UnkownDeviceStateView"> {{ $t("SplashscreenView.deviceState")}} <v-icon class="ml-2">mdi-cellphone-erase</v-icon> </v-btn>
        </v-sheet>
        <v-sheet v-else class="pa-6 rounded" style="background-color: rgba( 0, 0, 0, 0.6);">
          <v-progress-circular indeterminate color="accent"></v-progress-circular>
          <p class="mt-8 white--text" v-html="data_splashtext"></p>
        </v-sheet>

      </div>
    </div>

  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-01-01
  *	@version	0.0.1
  *	@updated	2020-02-19
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "SplashscreenView";
    var _public = undefined;

    //const Vue = window.Vue;
    const APP = window.APP;
    const _h = APP.helper;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    _public = {
      template: '#'+classname,

      props: [],

      data: function(){ return {
        classname: classname,

        vertical: false,

        data_splashtext: undefined,
        data_errorMsg: undefined,
        unkownDeviceState: undefined,
      } },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self=this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },

        getWallpaperStyle: function(){
          let method="getWallpaperStyle"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self=this;
          let result = "background-image: url('../../assets/img/wallpaper-landscape.png')";
          if ( self.vertical ){ result = "background-image: url('../../assets/img/wallpaper.png')"; }
          return result;
        }
      },

      watch: {},

      methods: {
        loadModelImages: function(callback){
          let method="loadModelImages"; let preLog=classname+":"+method; console.log(preLog, "called");
          const self=this;
          const appState=self.appState;
          const device = appState.device||{};
          const currentResources = appState.app_resources;

          let imageList = [];
          if ( appState.general?.logo ){ imageList.push(appState.general.logo); }
          if ( device.allowedUsers ){
            for ( let userIndex in device.allowedUsers ){
              let user = device.allowedUsers[userIndex];
              if ( user && user.image ){ imageList.push(user.image); }
            }
          }

          if (APP){ return; } // ToDo: add loadResource
          return APP.app.loadResource(imageList, currentResources, {
            error: function(){},
            complete: callback
          });
        },

        loginDevice: function(){
          let method="loginDevice"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          const appState = self.appState;

          let activeLogin = false;
          let runAuthLoginLoopCounter = 0;

          const runAuthLogin = function(){
            self.data_splashtext = i18n(classname+".appIsLoading")+"<br />"+"Auth/Login";
            APP.api.send("Auth","Login", appState.local.auth_registerdevice, {
              success: function(res){
                if ( res.success ){
                    //console.log(preLog, res);
                    if ( typeof(APP.api.clientSaasSocket)==="function" ){ APP.api.clientSaasSocket = APP.api.clientSaasSocket(); }
                    //if ( typeof(APP.api.clientSaasSocket?.disconnect)==="function" ){ APP.api.clientSaasSocket?.disconnect(); }
                    if ( typeof(APP.api.clientSaasSocket.connect)==="function" ){ APP.api.clientSaasSocket.connect(); }
                  }
                  else{
                    let eMsg = res.error||i18n("error.invalidServerResponseData");
                    notify({ type:"error", text:eMsg} )
                    console.error(preLog, "eMsg="+eMsg, "response=",res);
                  }
              },
              error: function(err,errorCode){
                let eMsg = err; //i18n("error.failedDevicelogin")
                //notify({ type:"error", text:eMsg} )
                console.error(preLog, "eMsg="+eMsg, "error=",err, "errorCode:",errorCode);
              },
              complete: function(){
                if ( !activeLogin ){
                  if ( appState?.device ){ self.loginUser(); }
                  else{
                    self.data_splashtext = i18n(classname+".appIsLoading")+"<br />"+"Auth/State";
                    APP.api.send("App","State", null, {
                      //success: function(res){},
                      error: function(err){ },
                      complete: function(){ 
                        runAuthLoginLoopCounter++;
                        setTimeout( function(){ 
                          if ( runAuthLoginLoopCounter<2 ){ self.runAuthLogin(); }
                          else{ self.loginUser(); }
                        }, 1000 )
                      }
                    }, "rest");
                  }
                }
              },
            }, "rest" );
          }
          
          if ( appState?.device ){
            activeLogin = true;
            self.loginUser();
          }
          runAuthLogin();
        },


        loginUser: async function(){
          let method="loginUser"; let preLog=classname+"."+method; //console.log(preLog, "called");
          const self=this;
          var appState = self.appState;

          if ( appState.local?.userActive?.id && appState.device?.allowedUsers && appState.device.allowedUsers[appState.local.userActive.id] ){
            APP.global.route.change("BookingView");
            return;
          }

          const device = appState?.device;
          if ( !device ){
            let eMsg = i18n("error.invalidDeviceState");
            console.error( preLog, eMsg, "appState.device:", device);
            self.data_errorMsg = eMsg;
            self.unkownDeviceState = true;
            setTimeout( function(){
              self.loginDevice();
            }, 3000 )
            return;
          }

          // load device images
          self.data_splashtext = i18n(classname+".resourceIsLoading");
          await self.loadModelImages();

          // device?.deviceUsage: 0=SingleUser | 1=MultiUser | 2=MultiUser
          if ( device?.deviceUsage!=0 ){
            APP.global.route.change("LoginMultiUserView");
          }else{
            let user = device?.allowedUsers;
            user = user[Object.keys(user)[0]];
            APP.global.route.change("LoginView", { id: user?.id });
          }
        },

        onResize: function(){
          let method="onResize"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          if ( window.innerWidth>=window.innerHeight ){ this.vertical=false; }
          else{ this.vertical=true; }
        },

        reloadPage: function(){
          let method="reloadPage"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          window.location.reload();
        },

      },

      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self = this; if( this && this._uid ){ _public.component=this; }

        if ( APP.global.basepage.change("BlankBasePage") ){ return; }

        APP.global.getOverlay().clear();
        self.data_splashtext = i18n(classname+".appIsLoading");

        if(_public && _public.component && typeof(_public.component.$nextTick)==="function"){
          _public.component.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered", ".gradient-wrap:"+$(".gradient-wrap").length);
            setTimeout(function(){
              if ( !self.appState?.local?.auth_registerdevice?.deviceId || !self.appState?.local?.auth_registerdevice?.deviceKey ){
                // has NO "appState.auth_registerdevice" Data
                //console.log(preLog, "has no 'appState.auth_registerdevice' Data", appState.auth_registerdevice);
                APP.global.route.change("DeviceRegisterView");
              }else{
                self.loginDevice();
              }
            }, 1000 );
          });
        }
      },

    }

    return _public;
  })();
</script>
