<style>
    .v-speed-dailElement .v-speed-dial { text-align: center; }
</style>

<template>
  <element :class="classname">
    <v-speed-dial v-if="itemsComp.length>0" v-model="isVisible" v-bind="bindComp">
      <template v-slot:activator>
        <v-btn v-model="isVisible" v-bind="bbindComp">
          <v-icon v-if="isVisible && iconClose"> {{iconClose}} </v-icon>
          <v-icon v-else> {{icon}} </v-icon>
        </v-btn>
      </template>

      <v-btn v-for="(item, index) in itemsComp" :key="'item-'+index"
        v-bind="getItemBind(item.bind)"
        v-on="item.on"
      >
        <v-icon>{{item.icon}}</v-icon>
      </v-btn>

    </v-speed-dial>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "v-speed-dailElement";

    const APP = window.APP;
    const _h = APP.helper;

    //const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    //const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:function(){ return { demo:"Hello World" }; } },
        absolute: { type:Boolean, default:function(){ return false; } },
        bottom: { type:Boolean, default:function(){ return false; } },
        direction: { type:String, default:function(){ return 'auto'; } },
        fixed: { type:Boolean, default:function(){ return false; } },
        left: { type:Boolean, default:function(){ return false; } },
        mode: { type:String, default:function(){ return undefined; } },
        'open-on-hover': { type:Boolean, default:function(){ return false; } },
        origin: { type:String, default:function(){ return undefined; } },
        right: { type:Boolean, default:function(){ return false; } },
        top: { type:Boolean, default:function(){ return false; } },
        transition: { type:String, _default:function(){ return ["scale-transition", "slide-y-reverse-transition"][1]; } }, //
        value: { type:Boolean, default:function(){ return false; } },

        bbind: { type:Object, default:function(){ return {}; } }, // btn-bind
        icon: { type:String, default:function(){ return 'mdi-dots-vertical'; } },
        iconClose: { type:String, default:function(){ return 'mdi-close'; } },

        items: { type: Array, default:function(){ return []; } },
        //itemsTemplatename: { type:String, default:function(){ return; }}
      },

      data: function(){ return {
        classname: classname,
        isVisible: undefined,

        itemList: undefined,

        directionCalculated: undefined,

        /*
        itemsTemplates: {
          addEditDelete: [
            { name:"add", icon:"mdi-plus", bind: { color:"green" }, on:{} },
            { name:"edit", icon:"mdi-pencil", bind: { color:"indigo" }, on:{} },
            { name:"delete", icon:"mdi-delete", bind: { color:"red" }, on:{} },
          ],
          editDelete: [
            { name:"edit", icon:"mdi-pencil", bind: { color:"indigo" }, on:{} },
            { name:"delete", icon:"mdi-delete", bind: { color:"red" }, on:{} },
          ]
        }
        */
      } },

      computed: {
        bindComp: function(){
          let method="bindComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let bind = {
            absolute: self.absolute,
            bottom: self.bottom,
            direction: self.directionCalculated||self.getDirection(),
            fixed: self.fixed,
            left: self.left,
            mode: self.mode,
            'open-on-hover': self['open-on-hover'],
            origin: self.origin,
            right: self.right,
            top: self.top,
            transition: self.transition,
          }
          //console.log(preLog, bind);
          return bind;
        },

        bbindComp: function(){ // btn-bind
          let method="bbindComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let bind = self.bbind||{}
          if ( !('fab' in bind) ){ bind.fab=true; }
          if ( !('text' in bind) ){ bind.text=true; }
          if ( !('small' in bind) ){ bind.small=true; }
          if ( !('color' in bind) ){ bind.color="accent2"; }
          return bind;
        },

        itemsComp : function(){
          let method="getItemList"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let items = self.items;
          /*
          if (self.itemsTemplatename && self.itemsTemplates[self.itemsTemplatename] && items.length<=0 ){
            items = JSON.parse(JSON.stringify(self.itemsTemplates[self.itemsTemplatename]));
            let on = self._events;
            //console.log(self, on, items);
            for ( let onKey in on ){
              let item = items.find(function(item,index){ return item.name == onKey; });
              let onFunction = on[onKey]; if (Array.isArray(onFunction)){ onFunction = onFunction[0]; }
              if ( item && typeof(onFunction)==="function" ){
                if (!item.on){ item.on={}; }
                item.on['click'] = onFunction;
              }
            }
          }
          */
          //console.log(preLog, items);
          return items;
        }
      },

      watch: {
        isVisible: function(newVal, oldVal){
          let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
          const self = this;
          self.$emit('input', self.newVal);
        },
        value: {
          immediate: true,
          handler: function(newVal, oldVal){
            let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            self.isVisible = self.newVal;
          }
        },
      },

      methods: {
        getDirection: function(event){
          let method="getDirection"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          let direction = (self.direction||"auto").toLowerCase();
          if ( direction=="top" || direction=="bottom" || direction=="left" || direction == "right" ){ return direction; }
          var counter = 0;
          var minTopSpace = (52*self.itemsComp.length)+16+16;
          const setDirection = function(context){
            setTimeout(function(){
              counter++;
              if ( self.$el ){ if (self.$el.firstElementChild.offsetTop<minTopSpace && self.direction ){ self.directionCalculated="bottom"; } }
              else{ if (counter<=10){ self.setDirection(); } }
            }, 100);
          }
          setDirection();
          return "top";
        },

        getItemBind: function(bind){
          let method="getItemBind"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          if (!bind){ bind={}; }
          if ( !('fab' in bind) ){ bind.fab=true; }
          if ( !('dark' in bind) ){ bind.dark=true; }
          if ( !('small' in bind) ){ bind.small=true; }
          return bind;
        },

        /*
        templateItemsInit: function(){
          let method="templateItems"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this; console.log(preLog, self);
          if (self.itemsTemplatename && self.itemsTemplates[self.itemsTemplatename] && self.items.length<=0 ){
            let items = JSON.parse(JSON.stringify(self.itemsTemplates[self.itemsTemplatename]));
            let on = self._events;
            //console.log(self, on, items);
            for ( let onKey in on ){
              let item = items.find(function(item,index){ return item.name == onKey; });
              let onFunction = on[onKey]; if (Array.isArray(onFunction)){ onFunction = onFunction[0]; }
              //console.log(preLog, onKey, item ,"&&", typeof(onFunction)==="function" )
              if ( item && typeof(onFunction)==="function" ){
                if (!item.on){ item.on={}; }
                item.on[onKey] = function(){ console.log("!"); onFunction.call(); };
              }
            }
            console.log(preLog, items);
            self.itemList = items;
          }
        }
        */
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
