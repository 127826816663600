<style>
  .SelectMaskSimpleElement .v-progress-linear__indeterminate.primary {
    background-color: var(--accent) !important;
    border-color: var(--accent) !important;
  }
  .SelectMaskSimpleElement .v-data-table__wrapper {
    max-height: 500px;
    min-height: 300px;
    border: 1px solid #80808036;
  }
</style>

<template>
  <element :class="classname">

      <v-card class="tdcard">

        <div class="card-header" v-if="title || searchbarTarget=='header'">
          <v-card-title> {{$t(title)}} </v-card-title>
          <v-card-subtitle v-if="searchbarTarget=='header'"> <v-text-field v-model="filter" dark append-icon="mdi-magnify" :label="$t(filterLabel)" clearable single-line hide-details></v-text-field> </v-card-subtitle>
        </div>

        <div class="card-body">

          <v-card-text v-if="searchbarTarget=='body'"> <v-text-field v-model="filter" append-icon="mdi-magnify" :label="$t(filterLabel)" clearable single-line hide-details></v-text-field> </v-card-text>

          <v-row justify="center" align="center" dense v-if="groupItems.length>1">
            <v-col cols=10><v-select :label="$t(groupsLabel).upperCaseFirstLetter()" clearable v-model="groupValue" :items="translateItems(groupItems, 'text')" :disabled="isLoading"></v-select></v-col>
          </v-row>

          <v-row justify="center" align="center" class="mt-0">
            <v-col cols="10">
              <v-data-table style="max-height: 500"
                ref="itemsTable"
                :show-select="showSelect"
                :single-select="singleSelect"
                :loading="isLoading"
                :disable-pagination="true"
                :hide-default-footer="true"

                :search="filter"
                :custom-filter="customFilter"

                :headers="translateItems(headerList,'text')"
                fixed-header

                :item-key="itemKey"
                :sort-by="sortBy||itemKey"
                :group-by="groupByComp"

                v-model="selectedItemList"
                :items="itemsComp"
                :options.sync="options"

                @click:row="rowClicked"
              >

               <!-- eslint-disable-next-line -->
                <template v-slot:group.header="{items, isOpen, toggle, group}">
                  <th class="hidden-xs-only"> <v-btn icon @click="selectGroupItems(items, 'sourceSelectedItems')"><v-icon color="primary lighten-4"> {{getGroupIcon(items, 'sourceSelectedItems')}} </v-icon></v-btn> </th>
                  <th class="hidden-xs-only" @click="toggle"> {{ group }} </th>
                  <th class="hidden-xs-only text-right" @click="toggle"> <v-icon :ref="'sourceTabel_group_'+group" :data-open="isOpen">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> </th>
                  <th class="hidden-sm-and-up">
                    <v-row>
                      <v-col cols=2> <v-btn icon @click="selectGroupItems(items, 'sourceSelectedItems')"><v-icon color="primary lighten-4"> {{getGroupIcon(items, 'sourceSelectedItems')}} </v-icon></v-btn> </v-col>
                      <v-col cols=8 class="pt-5" @click="toggle"> {{ group }} </v-col>
                      <v-col cols=2 class="pt-5 text-right" @click="toggle"> <v-icon :ref="'sourceTabel_group_'+group" :data-open="isOpen">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> </v-col>
                    </v-row>
                  </th>
                </template>

              </v-data-table>
            </v-col>
          </v-row>

          <v-row justify="center" align="center" v-if="selectedItemList.length>=0  && !acceptOnSelect" class="mb-1">
            <v-col cols="10" style="min-height: 56px">
              <v-row justify="center" align="center">
                <v-col cols="auto">
                  <v-chip v-for="(item,index) in selectedItemList" :key="'chip-'+index" close color="accent2" dark class="ml-1 mb-1" @click:close="chipCloseClicked(item)"> {{getChipTextParent(item)}} </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </div>


        <div class="card-footer mx-4 mt-0 pb-5">
          <!--
          <v-card-actions>
            <v-row align="center" justify="center"><v-col cols="auto">
              <v-btn v-if="onCancel" color="secondary" dark class="mx-2" @click="cancelClicked"> {{$t("global.cancel")}} </v-btn>
              <v-btn v-if="onAccept && !acceptOnSelect" color="accent" class="mx-2" :disabled="(selectedItemList.length<=0)" @click="acceptClicked"> {{$t("global.apply")}} </v-btn>
            </v-col></v-row>
          </v-card-actions>
          -->
          <v-card-actions class="mt-5">
            <v-btn if="onCancel" class="mr-2" color="secondary" @click="cancelClicked">{{$t("global.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="(onAccept||_events.accept) && !acceptOnSelect" :disabled="selectedItemList.length<=0" color="accent" @click="acceptClicked"> {{$t("global.apply")}} </v-btn>
          </v-card-actions>
        </div>

      </v-card>

  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "SelectMaskSimpleElement";

    const APP = window.APP;
    const _h = APP.helper;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); }else{ return args; } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {
      },

      props: {
        //value: { type:Boolean, default:function(){ return false; } }, // show dialog
        title: { type:String, default:function(){ return "" } }, //"SearchmaskSnipped.title
        filterLabel: { type:String, default:function(){ return "global.search" } },
        groupsLabel: { type:String, default:function(){ return "global.groups" } },
        groupItems: { type:Array, default: function(){ return [ ]; } }, // [ { text: "global.groups", value:"groups" }, { text: "global.terminals", value:"terminals" } ]
        groupBy: { type:String, default:function(){ return ""; } },
        sortBy: { type:String, default:function(){ return ""; } },
        showSelect: { type:Boolean, default:function(){ return false; } },
        itemKey: { type:String, default:function(){ return "id" } },
        singleSelect: { type:Boolean, default: function(){ return true; } },

        headers: { type:Array, default:function(){ return []; } },
        searchbarTarget: { type:String, default:function(){ return "header"; } }, // header | body | none
        items: { type:Array, default:function(){ return []; } },
        selectedItems: { type:Array, default:function(){ return []; } },

        acceptOnSelect: { type:Boolean, default: function(){ return false; } },

        apiRequest: { type:Function, _default: function(){ return function(context){}; }},
        onOptions: { type:Function, _default: function(){ return function(newOptions, oldOptions, context){}; }},
        getChipText: { type:Function, _default: function(){ return function(item){}; }},
        onAccept: { type:Function, _default: function(){ return function(items, context){}; }},
        onCancel: { type:Function, _default: function(){ return function(context){}; } },
      },

      data: function(){ return {
        classname: classname,

        filter: '',
        isLoading: false,

        category: undefined,

        headerList: [],
        groupValue: this.groupBy, // category||"" -> { category: "cat" }

        itemList: [],
        options: {},
        selectedItemList: [ ],

        initStateStr : undefined

      } },

      computed: {
        groupByComp: function(){
          let method="groupByComp"; let preLog=classname+".methods."+method; //console.log(preLog, "called", this.groupValue);
          const self = this;
          let group = [];
          if (self.groupValue){ group="groupName"; }
          return group;
        },

        itemsComp: function(){
          let method="itemsComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called", this.itemList);
          const self = this;
          let items = JSON.parse(JSON.stringify(self.itemList));
          //let items = JSON.parse(JSON.stringify( _h.reduceByProperty(self.itemList, self.selectedItemList, self.itemKey) ));
          let groupedItems = [];
          if ( self.groupValue ){
            items.map(function(item, index){
              if ( Array.isArray(item[self.groupValue]) && item[self.groupValue].length>0){
                item[self.groupValue].forEach(function(groupItem, groupIndex){
                  item = JSON.parse(JSON.stringify(item));
                  item["groupName"] = groupItem;
                  //item["itemKey"] = method+"_"+groupItem+"_"+item[self.itemKey];
                  groupedItems.push(item);
                });
              }else{
                item["groupName"] = item[self.groupValue];
                //item["itemKey"] = method+"_"+item[self.groupValue]+"_"+item[self.itemKey];
                groupedItems.push(item);
              }
            });
          }
          if (groupedItems.length>0){
            return groupedItems;
          }else{
            //sourceItems.map(function(item, index){ item["itemKey"] = item[self.itemKey]; });
            return items;
          }
        },
      },

      watch: {
        options: {
          deep: true,
          handler: function(newVal, oldVal){
            let method="options"; let preLog=classname+".watch."+method; //console.log(preLog, "called", newVal, oldVal );
            const self = this;
            //if ( JSON.stringify(newVal) !== JSON.stringify(oldVal) ){ self.getDataFromApi(); }
          },
        },

        headers: {
          deep: true,
          immediate: true,
          handler: function(newVal, oldVal){
            let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
             self.headerList =newVal
          }
        },

        items: {
          deep: true,
          immediate: true,
          handler: function(newVal, oldVal){
            let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            if ( typeof(self.apiRequest)==="function" ){ self.getDataFromApi(); }
            else { self.setTable(newVal); }
          }
        },

        selectedItems: {
          deep: true,
          immediate: true,
          handler: function(newVal,oldVal){
            let method="selectedItems"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            self.selectedItemList = newVal;
          }
        },

        selectedItemList: {
          immediate: true,
          handler: function(newVal, oldVal){
            let method="selectedItemList"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            self.selectedItemList.forEach(function(item,index){
              if (typeof(item)!=="object"){
                self.selectedItemList[index] = self.itemList.find(function(sItem,sIndex){ return sItem[self.itemKey]==item });
              }
            })
            if ( self.acceptOnSelect && newVal.length>0 ){ self.acceptClicked(); }
          }
        }

      },


      methods: {
        acceptClicked: function(event){
          let method="acceptClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          self.itemList.map(function(item,index){
            if ( item?.groupName ){ delete item.groupName; }
            if ( item?.itemKey ){ delete item.itemKey; }
          });

          if ( typeof(self.onAccept)==="function" ){ self.onAccept(self.selectedItemList, self); return; }
          if ( self._events.accept ){ self.$emit("accept", self.selectedItemList, self); return; }
          console.log(preLog, self.itemList, self);
          //self.close();
        },

        cancelClicked: function(){
          let method="cancelClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          self.$emit("cancel", self);
          if ( typeof(self.onCancel)==="function" ){ self.onCancel(self); }
          if ( self._events.cancel ){ self.$emit("cancel", self); return; }
          self.restoreInit();
        },

        chipCloseClicked: function(data){
          let method="chipCloseClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data, this.itemKey);
          const self = this;
          self.selectedItemList = self.selectedItemList.filter(function(item, index){ return item[self.itemKey]!=data[self.itemKey]; });
          /*
          let rowIndex = self.selectedItemList.findIndex(function(item,index){
            if ( data && item && data[self.itemSelectionKey] == item[self.itemSelectionKey] ){ return true; }
          });
          if ( rowIndex>=0 ){ self.selectedItemList.splice(rowIndex,1); }
          */
        },

        clear: function(){
          let method="clearSelected"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data);
          const self = this;
          self.selectedItemList = [];
          self.filter = "";
        },

        customFilter: function(value, search, item){
          let method="customFilter"; let preLog=classname+".methods."+method; //console.log(preLog, "called", value, search, item);
          let self = this;
          value = value.toString().toLowerCase();
          search = search.toString().toLowerCase();
          if ( value.includes(search) ){ return true; }
          if ( self.groupValue && item[self.groupValue] ){
            if ( typeof(item[self.groupValue])==="string" && item[self.groupValue].toLowerCase().includes(search) ){ return true; }
            if ( Array.isArray(item[self.groupValue]) ){
              item[self.groupValue].map(function(groupItem){
                //console.log(preLog, self.groupValue, groupItem)
                return groupItem.toString().toLowerCase(); }).includes(search);
              }
          }
        },

        getDataFromApi: function(){
          let method="apiRequest"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          self.isLoading = true;
          if ( typeof(self.apiRequest)==="function" ){ self.apiRequest(self); }
          else{ self.isLoading = false; }
        },

        restoreInit : function(){
          let method="restoreInit"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          let initStr = self.initStateStr||{};
          Object.assign( self.$data, JSON.parse(initStr) );
          self.initStateStr = initStr
        },

        rowExpandAll(refTable) {
          const self = this;
          if (!refTable){ refTable="sourceTabel"; }
          if (self.$refs[refTable]?.openCache){
            for (const name of Object.keys(self.$refs[refTable].openCache)) {
              self.$refs[refTable].openCache[name] = true;
            }
          }
        },
        rowCollapseAll(refTable) {
          const self = this;
          if (!refTable){ refTable="sourceTabel"; }
          if (self.$refs[refTable]?.openCache){
            for (const name of Object.keys(self.$refs[refTable].openCache)) {
              self.$refs[refTable].openCache[name] = false;
            }
          }
        },

        setTable: function(data){
          let method="setTable"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data);
          const self = this;
          if (data.headers){ self.headerList = data.headers; }
          let hasListItems = !!self.itemList?.length;
          self.itemList = data.items||data||[];
          if ( !hasListItems ){ setTimeout(function(){ self.rowCollapseAll(); }, 0); }
        },

        submitClicked: function(event){
          let method="submitClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          if ( typeof(self.onSubmit)==="function" ){ self.onSubmit(self.selectedItemList, self); }
          else{
            console.log(preLog, self.selectedItemList);
            self.restoreInit()
          }
        },

        translateItems: function(items, key){
          let result = JSON.parse(JSON.stringify(items));
          for (let itemIndex in result ){
            let item = result[itemIndex];
            item[key] = i18n(item[key]);
          }
          return result;
        },

        getChipTextParent: function(data){
          let method="getChipTextParent"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data);
          const self = this;
          if ( typeof(self.getChipText)==="function" ){ return self.getChipText(data); }
          if ( data[self.itemKey] ){ return data[self.itemKey]; }
          console.warn(preLog, "Missing valid chip text pattern.");
          return "";
        },

        filterInputChange: function(data){
          let method="filterInputChange"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data);
          const self = this;
          let elementVue = self.$refs["filterinput"];
          elementVue.blur();
        },
        rowClicked: function(item, body){
           let method="rowClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", item, body);
           const self = this;
           body.select(!body.isSelected);
        },

      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        self.initStateStr = JSON.stringify(self.$data);

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
