import Vue from 'vue'
const APP = window.APP;

const components = [
  // Elements
  'AppOverlayElement',
  'NotificationAlertElement',

  // Snippets

  // Views
  //'BlankView',

]

// Helper
function createComponent(name){
  let componentType = undefined;
  if ( name.endsWith('Element') ){ componentType="elements"; }
  if ( name.endsWith('View') ){ componentType="views"; }
  if ( name.endsWith('BasePage') ){ componentType="basePages"; }
  APP.Templates[name] = Vue.component(name, require('./'+componentType+'/'+name+'.vue').default);
}

//  MAIN
// ------
for ( let index in components ){
  let componentName = components[index];
  createComponent(componentName);
}

/*
//  Elements
// ----------
import AppOverlayElement from './elements/AppOverlayElement.vue'
if( !APP.Templates.AppOverlayElement ){ APP.Templates.AppOverlayElement = Vue.component('AppOverlayElement', AppOverlayElement); }

import ComponentsElement from './elements/ComponentsElement.vue'
if( !APP.Templates.ComponentsElement ){ APP.Templates.ComponentsElement = Vue.component('ComponentsElement', ComponentsElement); }

import DialogAgreeElement from './elements/DialogAgreeElement.vue'
if( !APP.Templates.DialogAgreeElement ){ APP.Templates.DialogAgreeElement = Vue.component('DialogAgreeElement', DialogAgreeElement); }

import DialogElement from './elements/DialogElement.vue'
if( !APP.Templates.DialogElement ){ APP.Templates.DialogElement = Vue.component('DialogElement', DialogElement); }

import NotificationAlertElement from './elements/NotificationAlertElement.vue'
if( !APP.Templates.NotificationAlertElement ){ APP.Templates.NotificationAlertElement = Vue.component('NotificationAlertElement', NotificationAlertElement); }

import NotificationSnackbarElement from './elements/NotificationSnackbarElement.vue'
if( !APP.Templates.NotificationSnackbarElement ){ APP.Templates.NotificationSnackbarElement = Vue.component('NotificationSnackbarElement', NotificationSnackbarElement); }



//  Views
// -----------
import BlankView from './views/BlankView.vue'
if( !APP.Templates.BlankView ){ APP.Templates.BlankView = Vue.component('BlankView', BlankView); }

import ConfigView from './views/ConfigView.vue'
if( !APP.Templates.ConfigView ){ APP.Templates.ConfigView = Vue.component('ConfigView', ConfigView); }

import LoginView from './views/LoginView.vue'
if( !APP.Templates.LoginView ){ APP.Templates.LoginView = Vue.component('LoginView', LoginView); }

import SplashscreenView from './views/SplashscreenView.vue'
if( !APP.Templates.SplashscreenView ){ APP.Templates.SplashscreenView = Vue.component('SplashscreenView', SplashscreenView); }

import UiAdminView from './views/UiAdminView.vue'
if( !APP.Templates.UiAdminView ){ APP.Templates.UiAdminView = Vue.component('UiAdminView', UiAdminView); }



//  BasePages
// -----------
import BlankBasePage from './basePages/BlankBasePage.vue'
if( !APP.Templates.BlankBasePage ){ APP.Templates.BlankBasePage = Vue.component('BlankBasePage', BlankBasePage); }

import BaselineBasePage from './basePages/BaselineBasePage.vue'
if( !APP.Templates.BaselineBasePage ){ APP.Templates.BaselineBasePage = Vue.component('BaselineBasePage', BaselineBasePage); }
*/
