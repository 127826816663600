<template type="text/x-template" id="AppView">
  <v-app>

    <component v-if="basePage_is" ref="basePage" v-bind:is="basePage_is" v-bind="basePage_bind"></component>
    <AppOverlay ref="appOverlay"/>
    <Dialog ref="dialog"></Dialog>
    <Notification ref="notification"></Notification>

  </v-app>
</template>

<script>
  //import BlankBasePage_template from '@/templates/basePages/BlankBasePage.vue'

  export default (function(){
    const classname = "AppView";
    const APP = window.APP;
    //const _h = APP.helper;

    //const getStore = function(){ if(APP.vue && APP.vue.store){ return APP.vue.store; } }

    const _public = {
      name: classname,

      components: {
        AppOverlay: APP.Templates.AppOverlayElement,
        Dialog: APP.Templates.DialogElement,
        Notification: APP.Templates.NotificationAlertElement,
      },

      data: function(){ return {
        basePage_is : undefined,
        basePage_bind: undefined,
      } },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{};
          return appState;
        },
      },

      watch: {
        appState: {
          //deep: true,
          immediate: true,
          handler: function(newState, oldState){
            let method="appState"; let preLog=classname+".watch."+method; console.log(preLog, "called", "newState:",newState);
            const self = this;
            if (typeof(APP?.js?.setAppStateParameter)==="function"){
              APP.js.setAppStateParameter(newState);
            }
          }
        }

      },

      methods: {
        basepage_getCurrentName: function(){ return this.basePage_is?.component?.classname||this.basePage_is?.options?.name; },
        basepage_change: function(basepagename, args){
          let method="basepage_change"; let preLog=classname+".methods."+method; //console.log(preLog, "CALLED", basepagename, args);
          const self = this;
          let hasBasePageChanged = false;
          if ( typeof(basepagename)!=="string" || !basepagename.endsWith("BasePage") || !APP.Templates[basepagename] ){ console.error(preLog, "Invalid BasePagename:",basepagename); return; }
          if ( (self.basePage_is?.component?.classname||self.basePage_is?.options?.name) != basepagename ){ self.basePage_is = APP.Templates[basepagename]; hasBasePageChanged=true; }
          //self.basePage_bind = { args: args, initData: (APP?.initData||{})[basepagename] };
          let bindObj = { initData: (APP?.initData||{})[basepagename] };
          if ( typeof(self.basePage_is?.component?.setVm)==="function" ){ self.basePage_is.component.setVm(args); }
          else{ bindObj.args = args }
          self.basePage_bind = bindObj;
          //if ( hasBasePageChanged ){console.log(preLog, "hasBasePageChanged:",hasBasePageChanged); }
          return hasBasePageChanged;
        },
      },

      //beforeCreate: function(){},
      //created: function(){ let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called"); },
      //beforeMount: function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this; _public.component=self;
        //if ( !self.basePage_is ){ self.basePage_is = APP.Templates["BlankBasePage"]; }

        if(typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
             if ( !self.basePage_is ){ self.basePage_is = APP.Templates["BlankBasePage"]; }
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); alert(2); },
      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    return _public;
  })();
</script>
