<style>
    /* .DialogElement .v-card__title, .DialogElement .v-card__text { word-break: break-word; } */
  .DialogElement .v-dialog { box-shadow: none !important; }
  .DialogElement .v-card { padding-bottom: 15px; }
  .DialogElement .v-card .card-header {
    position: relative;
    top: -20px;
    margin: 20px 20px 0px 20px;
    border-radius: 5px;
    _box-shadow: 0 5px 6px -3px rgba(0,0,0,.2),0 9px 12px 1px rgba(0,0,0,.14),0 3px 16px 2px rgba(0,0,0,.12)!important;
  }
  .DialogElement .v-card .card-header > .v-card__title { color: white;  }
  .DialogElement .v-card .card-header > .v-card__subtitle { color: hsla(0,0%,100%,.8);  }
</style>

<template>
    <element v-bind:class="classname">
        <v-dialog :attach="'.'+classname" v-if="dialog" v-model="dialog.active" v-bind="dialog.bind">
          <v-card v-bind="dialog.cardBind">

              <div class="card-header accent">
                <v-card-title v-if="dialog.title" class="title">{{dialog.title}}</v-card-title>
                <v-card-subtitle v-if="dialog.subtitle" class="subtitle-1">{{dialog.subtitle}}</v-card-subtitle>
              </div>

              <v-card-text v-if="dialog.body" v-html="dialog.body" class="primary--text"></v-card-text>
              <v-card-text v-if="dialog.component">
                  <!-- <component v-for="(element, index) in (dialog.component.elements||dialog.component)" v-bind:key="index" v-if="!!element.template" v-on="element.template.on" v-model="element.template.model" v-bind="element.template.bind">
                      <div v-if="element.template.html" v-html="element.template.html">{{element.template.text}}</div>
                  </component> -->
                  <component v-if="dialog.component" v-bind:is="dialog.component"></component>
              </v-card-text>
              <v-card-text v-if="dialog.bodyPost" v-html="dialog.bodyPost"></v-card-text>

              <v-card-actions v-if="dialog.cancel||dialog.accept">
                  <v-spacer></v-spacer>
                  <v-btn  v-if="dialog.cancel" v-bind="dialog.cancel.bind" @click="cancelClicked" v-html="dialog.cancel.contentHtml||dialog.cancel.text"></v-btn>
                  <v-btn  v-if="dialog.accept" v-bind="dialog.accept.bind" @click="acceptClicked" v-html="dialog.accept.contentHtml||dialog.accept.text"></v-btn>
              </v-card-actions>
          </v-card>

        </v-dialog>
    </element>
</template>


<script type="text/javascript">
    /*
    *	@author		HM
    *	@date: 		2020-02-29
    *	@version	0.1.2
    *	@updated	2021-05-18
    * @link       http://
    *
    *	@param		{Object} 	args 			Default argument object.
    *	@param		{type} 		args.key 		description
    *
    *	@return 	{Object} 	_t.public 			Instance of this class.
    */
    'use strict';
    export default (function(args){
        // Private
        //------------
        const classname = "DialogElement";

        const APP = window.APP;
        const _h = APP.helper;

        const global_layouts = {
            confirm: {
                layoutName: "confirm",
                title: "Bestätigung erforderlich", subtitle: "",
                body: "Wollen Sie diese Aktion wirklich durchführen?",
                cancel: { text: "Abbrechen", bind: { color:"warning" },
                    /*on: {
                        click: function(event){
                            let method="dialog.cancel.on.click"; let preLog=classname+":"+method; //console.log(preLog, "called");
                            if ( typeof(this.dialog.cancelOnClick)==="function" ){ this.dialog.cancelOnClick(this.dialog, event, _public.clearVm); }
                            else{ _public.clearVm(); }
                        }
                    }*/
                },
                accept: { text: "Bestätigen", bind: { color:"success" },
                    /*on: {
                        click: function(event){
                            let method="dialog.accept.on.click"; let preLog=classname+":"+method; //console.log(preLog, "called");
                            if ( typeof(this.dialog.acceptOnClick)==="function" ){ this.dialog.acceptOnClick(this.dialog, event, _public.clearVm); }
                            else{ _public.clearVm(); }
                        }
                    }*/
                },
                //bind: { dark:"dark" },
                //cardBind: { color:"secondary" },
                //cancelOnClick: function(data, event, callback){ let method="cancelOnClick"; let preLog=classname+"."+method; console.log(preLog, "called"); if(typeof(callback)==="function"){ callback(); } },
                //acceptOnClick: function(data, event, callback){ let method="acceptOnClick"; let preLog=classname+"."+method; console.log(preLog, "called"); if(typeof(callback)==="function"){ callback(); } },
            }
        }

        // Protected
        //------------
        const _protected = {
            getDefaultDialog : function(layoutName){
                let method="_protected.getDefaultDialog"; let preLog=classname+"."+method; // console.log(preLog, "called", "layoutName:",layoutName);
                if ( typeof(layoutName)!=="string" || !global_layouts[layoutName] ){ layoutName="default"; }

                const layouts = {
                    default: {
                        layoutName: "default",
                        active : false,
                        bind: { },
                        cardBind: { color:"" },
                        title : "title",
                        subtitle : "subtitle",
                        body : "body",
                        component : null,
                        component_example: {
                            bind: { class: "compact-form", },
                            elements: [
                                { template : { bind: { is:"v-text-field", outlined:"outlined", label:"Nachname", name:"lastname", type:"text", } , model : "", } , },
                                { template : { bind: { is:"div" }, html:"Abc <b>defg</b> hijk." } },
                                { template : { bind: { is:"v-text-field", outlined:"outlined", label:"Vorname", name:"firstname", type:"text", } , model : "", } , },
                            ],
                        },

                        bodyPost: null,
                        cancel: {
                            bind:{ color: "warning", },
                            html: null,
                            text: "cancel.html||cancel.text",
                        },
                        accept: {
                            bind:{ color: "success", },
                            html: null,
                            text: "accept.html||accept.text",
                        },
                        cancelOnClick: null,
                        acceptOnClick: null,
                    }
                }
                Object.assign(layouts, global_layouts);

                let res = layouts[layoutName]||{};
                res.layoutName = layoutName;
                return JSON.parse(JSON.stringify(res));
            },

            addLayout: function(newLayout){
                let method="_protected.addLayout"; let preLog=classname+"."+method; // console.log(preLog, "called", "layoutName:",layoutName);
                if ( !newLayout || typeof(newLayout)!=="object" || !newLayout.layoutName || typeof(newLayout.layoutName)!=="string" || !newLayout.layoutName.trim() ){ console.warn(preLog, "Invalid Layout type. Must be Layout-Object with attr: 'layoutName'."); return; }
                if ( newLayout.layoutName.trim()==="default" ){ console.warn(preLog, "Its not allowed to override the 'default'-Layout."); return; }
                newLayout.layoutName = newLayout.layoutName.trim();
                //newLayout = Object.assign(_protected.getDefaultDialog("default"), newLayout);
                newLayout = Object.merge({}, _protected.getDefaultDialog("default"), newLayout);
                global_layouts[newLayout.layoutName] = newLayout;
            }
        }

        // Public
        //------------
        const _public = {
            template: '#'+classname,
            props: [],


            data: function(){ return {
                classname: classname,
                htmlId: _h.generateGUID(),
                dialog: null,
            } },

            computed: {
            },
            watch: {},
            methods: {
                addLayout: _protected.addLayout,

                getLayout: function(){ try{ return JSON.parse(JSON.stringify(global_layouts)); }catch(err){ console.error(preLog, err); return {}; } },

                setVm: function(obj){
                    let method="setVm"; let preLog=classname+"."+method; //console.log(preLog, "called", "args:",obj);

                    if ( typeof(obj)==="function" ){ obj={ acceptClicked: obj }; }
                    if ( obj && typeof(obj)==="string" && obj.trim() && global_layouts[obj.trim()] ){  obj={ layoutName: obj.trim() }; }
                    if ( !obj || typeof(obj)!=="object" || Object.keys(obj).length<=0 ){ obj={ layoutName: "default" }; }

                    if ( obj && typeof(obj)==="object" ){
                        if ( typeof(obj.layoutName)!=="string" && typeof(obj.template)==="string" ){ obj.layoutName=obj.template; delete obj.template; }
                        if ( typeof(obj.layoutName)!=="string" && typeof(obj.layout)==="string" ){ obj.layoutName=obj.layout; delete obj.layout; }
                        if ( typeof(obj.title)!=="string" && typeof(obj.header)==="string" ){ obj.title=obj.header; delete obj.header; }

                        if ( obj.component && (!obj.component.template || typeof(obj.component.template)!=="string" || !obj.component.template.startsWith("#") ) ){ obj.component = APP.Templates.ComponentsElement(obj.component); }
                    }
                    if ( typeof(obj.active)==="undefined" ){ obj.active=true; }

                    let dialog = this.dialog;
                    if ( obj.layoutName ){
                        let layoutDialog =  _protected.getDefaultDialog(obj.layoutName);
                        if ( obj.layoutName==="default" ){ layoutDialog.body = "body<br />\nfullDefaultModel="+JSON.stringify(layoutDialog); }
                        dialog = Object.assign(layoutDialog, dialog);
                    }

                    //let res = Object.assign( (dialog||{}), obj );
                    let res = Object.merge( (dialog||{}), obj );

                    if ( typeof(this?.$t)==="function" ){
                      if (res?.title){ res.title = this.$t(res.title); }
                      if (res?.subtitle){ res.subtitle = this.$t(res.subtitle); }
                      if (res?.body){ res.body = this.$t(res.body); }
                      if (res?.cancel?.text){ res.cancel.text = this.$t(res.cancel.text); }
                      if (res?.accept?.text){ res.accept.text = this.$t(res.accept.text); }
                    }

                    this.dialog=res;
                },
                show: function(obj){
                    let method="clearVm"; let preLog=classname+"."+method; //console.log(preLog, "called");
                    const self = this;
                    self.setVm(obj);
                },

                clearVm: function(){
                    let method="clearVm"; let preLog=classname+"."+method; //console.log(preLog, "called");
                    this.dialog = null;
                },


                acceptClicked: function(event){
                    let method="acceptClicked"; let preLog=classname+".methods."+method; //console.log(preLog, this.dialog, typeof(this.dialog.acceptClicked), this);
                    if ( typeof(this.dialog.acceptOnClick)==="function" ){ this.dialog.acceptOnClick(this.dialog, event, this.clearVm); }
                    else{ this.clearVm(); }
                },

                cancelClicked: function(event){
                    let method="acceptClicked"; let preLog=classname+".methods."+method; //console.log(preLog, this.dialog, typeof(this.dialog.acceptClicked), this);
                    if ( typeof(this.dialog.cancelOnClick)==="function" ){ this.dialog.cancelOnClick(this.dialog, event, this.clearVm); }
                    else{ this.clearVm(); }
                }
            },
            mounted : function(){
                let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
                if ( !_public.component ){_public.component=this; }
                //this.dialog = _protected.getDefaultDialog();
            },
        }

        // MAIN
        //------------
        let method="MAIN"; let preLog=classname+":"+method;  //console.log(preLog, "called");

        //_public.clearVm();
        //if ( args && typeof(args)==="object" ){ _public.setVm(args); }

        return _public;
    })();
</script>
