import Vue from 'vue'
const APP = window.APP;

const components = [
  // Elements
  'ClockAnalogElement',
  'ClockDigitalElement',
  'ComponentsElement',
  'ColorPickerInlinePopupElement',
  'DialogElement',
  'MultilanguageInputFormElement',
  'SelectMaskSimpleElement',
  'SelectMaskMultiElement',
  'SpreadsheetElement',
  'ToolbarElement',
  'v-date-pickerElement',
  'v-menuElement',
  'v-speed-dailElement',
  'v-time-pickerElement',

  // Snippeds
  'StatusbarConnectionSnipped',

  // Views
  'AboutView',
  'BookingView',
  'BookingStatsView',
  'DeviceRegisterView',
  'LoginView',
  'LoginMultiUserView',
  'LogoutView',
  'PasswordChangeView',
  'QrScannerView',
  'SaldenOverviewView',
  'SplashscreenView',
  'UiAdminView',
  'UnkownDeviceStateView',

  // BasePages
  "BaselineBasePage",
  "BlankBasePage",
]


// Helper
function createComponent(name){
  let componentType = undefined;
  if ( name.endsWith('Element') ){ componentType="elements"; }
  if ( name.endsWith('Snipped') ){ componentType="snipped"; }
  if ( name.endsWith('View') ){ componentType="views"; }
  if ( name.endsWith('BasePage') ){ componentType="basePages"; }
  //APP.Templates[name] = require('./'+componentType+'/'+name+'.vue'); Vue.component(name, APP.Templates[name].default );
  APP.Templates[name] = require('./'+componentType+'/'+name+'.vue').default; Vue.component(name, APP.Templates[name] );
  //Vue.component(name, require('./'+componentType+'/'+name+'.vue').default);

}

//  MAIN
// ------
for ( let index in components ){
  let componentName = components[index];
  createComponent(componentName);
}
