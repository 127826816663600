<style>
  .BookingView {  }
</style>

<template>
  <element :class="classname">
    <v-sheet class="view">

      <v-row dense no-gutter align="start" justify="center">
        <v-col sm=12 md=10>
          <v-card class="pa-2">

            <v-row v-if="userSections && userSections.bookingSection && userSections.bookingSection.isShowAnalogClock" align="center" justify="center">
              <v-col cols=auto> <ClockAnalogElement></ClockAnalogElement> </v-col>
            </v-row>

            <v-row align="center" justify="center" class="pt-2">
              <v-col cols=auto> <ClockDigitalElement></ClockDigitalElement> </v-col>
            </v-row>

            <v-row align="stretch" justify="center" v-if="userSections && userSections.bookingSection" class="mt-2">
              <v-col cols=12 sm=5 md=3 xl=2 v-for="(button, index) in userSections.bookingSection.buttons" :id="index" :key="index">
                <v-btn tile width="100%" min-height=100 :style="button.style" v-on:click="book(button)" :disabled="getLastBooking().codeA===button.params.codeA">
                  <span>{{button.title}}</span> <v-icon class="mx-2">{{button.icon}}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        </v-col>
      </v-row>

    </v-sheet>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-06-24
  *	@version	1.1.1
  *	@updated	2020-06-24
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "BookingView";

    const APP = window.APP;
    const _h = APP.helper;
    const moment = window.moment;

    //const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {
        ClockAnalogElement: APP.Templates.ClockAnalogElement,
        ClockDigitalElement: APP.Templates.ClockDigitalElement,
      },

      props: [],

      data: function(){ return {
          classname: classname,
      }; },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },

        userSections: function(){
          let method="userSections"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let userSections = undefined;
          if (self.appState.sections && typeof(self.appState.local.userActive?.id)!="undefined"){ userSections = self.appState.sections[self.appState.local.userActive.id]; }
          return userSections;
        },

        userBookings: function(){
          let method="userBookings"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let userId = self.appState.local?.userActive?.id;
          let localBookings = (self.appState.local?.bookings||[]).filter(function(bookingItem, bookingIndex){ return bookingItem.userId == userId; });
          let appStateUserBookings = ((self.userSections?.bookingSection?.groupedBookings||[])[(self.userSections?.bookingSection?.groupedBookings||[]).length-1]||{}).bookings||[];
          let userBookings = localBookings.concat(appStateUserBookings);
          /*
          let userId = self.appState.local?.userActive?.id;
          let userBookings = (self.appState.bookings||[]).filter(function(bookingItem, bookingIndex){ return bookingItem.userId == userId; });
          userBookings = userBookings.concat((self.appState.local?.bookings||[]).filter(function(bookingItem, bookingIndex){ return bookingItem.userId == userId; }));
          
          userBookings = userBookings.sort(function(a, b){
            if ( a.localBookingDate > b.localBookingDate ){ return -1; }
            if ( a.localBookingDate < b.localBookingDate ){ return 1; }
            return 0;
          });
          */
          return userBookings;
        }
      },

      watch: {},

      methods: {
        getLastBooking: function(){
          let method="getLastBooking"; let preLog=classname+".methods."+method; //console.log(preLog, "called", self.userSections);
          const self = this;
          //console.log(self, self.userBookings, self.userBookings[0]);
          let lastBooking = JSON.parse(JSON.stringify(self.userBookings[0]||{}));
          if ( lastBooking?.localBookingDate && moment(lastBooking.localBookingDate).isBefore(Date.now(),'day') ){ lastBooking.codeA="-"; }
          return lastBooking||{};
        },

       addLocalBooking: function(newBooking){
          let method="addLocalBooking"; let preLog=classname+".methods."+method; console.log(preLog, "called", newBooking);
          const self = this;
          const appState = self.appState;
          if ( !appState.local.bookings ){ appState.local.bookings  = []; }
          appState.local.bookings.push(newBooking);

          if ( !appState.local.bookingsLog ){ appState.local.bookingsLog  = []; }
          appState.local.bookingsLog.push({ id:newBooking.tmpId, booking: newBooking, request:[], response:[] });
          if ( appState.local.bookingsLog.length>10){ appState.local.bookingsLog.shift();}

          self.$store.update(appState);
        },

        book: function(button){
          let method="book"; let preLog=classname+".methods."+method; //console.log(preLog, "called!");
          const self = this;
          const appState = self.appState;
          APP.global.getOverlay().show();

          try{
            // clone function because position obj cannot JSON.stringify
            const cloneAsObject = function(obj) {
              if (obj === null || !(obj instanceof Object)){ return obj; }
              var temp = (obj instanceof Array) ? [] : {};
              // ReSharper disable once MissingHasOwnPropertyInForeach
              for (var key in obj){ temp[key] = cloneAsObject(obj[key]); }
              return temp;
            }

            var performLock = false;
            const performBooking = function(position){
              if ( performLock ){ return; }else{ performLock=true; }
              if ( position && position.coords ){ position = cloneAsObject(position.coords); }
              //let bookingDate = new Date();
              let bookingTimestamp = moment();
              let sendObj = {
                "tmpId": Date.now(),
                "userId": appState.local.userActive.id,
                "codeA": button.params.codeA,
                "codeB": button.params.codeB,
                "localBookingDate": bookingTimestamp.format("YYYY-MM-DDTHH:mm:ss"),
                "bookingDateDisplay": bookingTimestamp.format("HH:mm"),
                "bookingDateUtc": bookingTimestamp.utc().format("YYYY-MM-DDTHH:mm:ss"),
                "comment": undefined,
                "isOfflineGenerated": true,
                "deliveryState": 1, //"BookingDeliveryStateType -> OnMobileDevice=1, OnTerminalHost=2, OnTerminalService=4, OnTidePro=8"
                "style": button.style,
                "coordinates": position
              }
              if ( button?.params?.codeA=="KO" ){ sendObj.style = {
                'color': "white",
                'background-color': "rgb(147, 197, 75)",
              } }
              //console.log(preLog, "performBooking: sendObj=",sendObj);
              self.addLocalBooking(sendObj);
              APP.global.getOverlay().hide();
              //if ( typeof(APP.js.sendLocalData)==="function" ){ APP.js.sendLocalData(); }
              if ( typeof(APP?.global?.route?.change)==="function" ){ APP.global.route.change("BookingStatsView"); }
            }


            //ToDo: GPS Access only when enabled in backend.
            console.log(preLog, "isUseGps:", self.userSections?.bookingSection?.isUseGps);
            if ( navigator && navigator.geolocation && self.userSections?.bookingSection?.isUseGps ){
              if ( navigator.permissions && typeof(navigator.permissions.query)==="function" ){
                navigator.permissions.query({name:'geolocation'}).then(function(result){
                if (result.state == 'granted') {
                  //alert("granted");
                  console.log(preLog, "granted");
                  try{
                    navigator.geolocation.getCurrentPosition(performBooking, performBooking, {timeout: 5000 } );
                    setTimeout(performBooking, 6000);
                  }catch(err){
                    let eMsg = err;
                    if (!eMsg){ eMsg="Something went wrong!"; }
                    notify({type:"error", text:eMsg });
                    console.log(preLog, "eMsg:",eMsg, "err:",err);
                    performBooking();
                  }
                }else {
                    if (result.state == 'prompt'){
                      //alert("prompt");
                      console.log(preLog, "prompt");
                      navigator.geolocation.getCurrentPosition(performBooking, performBooking, { timeout: 5000 } );
                      setTimeout(performBooking, 6000);
                    }
                    else{
                      //alert("blocked");
                      console.log(preLog, "blocked");
                      performBooking();
                    }
                  }
                });
              }else{
                navigator.geolocation.getCurrentPosition(performBooking, performBooking, { timeout: 5000 } );
                setTimeout(performBooking, 6000);
              }
            }
            else{ performBooking(); }
          }catch(err){
            let eMsg = err;
            if (!eMsg){ eMsg="Something went wrong!"; }
            notify({type:"error", text:eMsg });
            console.log(preLog, "eMsg:",eMsg, "err:",err);
            setTimeout( function(){ window.location.reload(); }, 2000 );
          }
        }

      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self=this; if( self && self._uid ){ _public.component=self; }
        APP.global.getOverlay().clear();
        APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this;

        if ( APP.global.basepage.change("BaselineBasePage") ){ return; }

        if ( APP.js?.sendLocalData ){ APP.js.sendLocalData(); }

        if( self && typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
            APP.global.getOverlay().hide();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    return _public;
  })();
</script>
