<style>
  .MultiSelectMaskElement .v-progress-linear__indeterminate.primary {
    background-color: var(--accent) !important;
    border-color: var(--accent) !important;
  }

  ._MultiSelectMaskElement .v-data-table__wrapper { border: 1px solid #80808036;}
  .MultiSelectMaskElement .v-data-table__wrapper {
    max-height: 500px;
    min-height: 500px;
    border: 1px solid #80808036;
  }
  .MultiSelectMaskElement .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row { min-height: 36px; }
  .MultiSelectMaskElement tbody .v-data-table__checkbox.v-simple-checkbox { margin-left: 20px; }

</style>

<template>
  <element :class="classname">
      <v-card class="tdcard">
        <div class="card-header">
          <v-card-title> {{$t(title)}} </v-card-title>
          <v-card-subtitle> <v-text-field v-model="filter" dark append-icon="mdi-magnify" :label="$t(filterLabel)" clearable single-line hide-details></v-text-field> </v-card-subtitle>
        </div>

        <div class="card-body">

          <v-row justify="center" align="center" dense v-if="groupItems.length>0">
            <v-col cols=10><v-select :label="$t(groupsLabel).upperCaseFirstLetter()" clearable v-model="groupValue" :items="translateItems(groupItems, 'text')" :disabled="isLoading"></v-select></v-col>
          </v-row>

          <v-row justify="center" align="center" class="mt-0">
            <v-col cols=11 sm=8 md=4 lg=4 xl=4>
              <v-data-table
                ref="sourceTabel"
                :show-select="true"
                :loading="isLoading"
                :disable-pagination="true"
                :hide-default-footer="true"

                :search="filter"
                :custom-filter="customFilter"

                :headers="translateItems(headerList,'text')"
                fixed-header

                :item-key="itemKey"
                :sort-by="sortBy||itemKey"
                :group-by="groupByComp"

                v-model="sourceSelectedItems"
                :items="sourceItemsComp"
                :options.sync="sourceOptions"

                @click:row="rowClicked"
              >

                <!-- eslint-disable-next-line -->
                <template v-slot:group.header="{items, isOpen, toggle, group}">
                  <th class="hidden-xs-only"> <v-btn icon @click="selectGroupItems(items, 'sourceSelectedItems')"><v-icon color="primary lighten-4"> {{getGroupIcon(items, 'sourceSelectedItems')}} </v-icon></v-btn> </th>
                  <th class="hidden-xs-only" :colspan="headerList.length-1" @click="toggle"> {{ $t(group).upperCaseFirstLetter() }} </th>
                  <th class="hidden-xs-only text-right" @click="toggle"> <v-icon :ref="'sourceTabel_group_'+group" :data-open="isOpen">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> </th>
                  <th class="hidden-sm-and-up">
                    <v-row>
                      <v-col cols=2> <v-btn icon @click="selectGroupItems(items, 'sourceSelectedItems')"><v-icon color="primary lighten-4"> {{getGroupIcon(items, 'sourceSelectedItems')}} </v-icon></v-btn> </v-col>
                      <v-col cols=8 class="pt-5" @click="toggle"> {{ $t(group).upperCaseFirstLetter() }} </v-col>
                      <v-col cols=2 class="pt-5 text-right" @click="toggle"> <v-icon :ref="'sourceTabel_group_'+group" :data-open="isOpen">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> </v-col>
                    </v-row>
                  </th>
                </template>


              </v-data-table>
            </v-col>

            <v-col cols=8 sm=5 md=3 lg=2 xl=2 class="text-center">
              <v-row justify="center" align="center">
                <v-col cols="12"> <v-btn block :color="sourceSelectedItems.length==sourceItemsComp.length ? 'primary' : 'secondary'" :disabled="sourceItemsComp.length<=0" @click="addItemsAll"> Alle hinzufügen <v-icon>mdi-chevron-double-right</v-icon> </v-btn> </v-col>
                <v-col cols="12"> <v-btn block color="accent2 white--text" :disabled="sourceSelectedItems.length<=0" @click="addItems"> Hinzufügen <v-icon>mdi-chevron-right</v-icon> </v-btn> </v-col>
                <v-col cols="12"> <v-btn block color="accent2 white--text" :disabled="targetSelectedItems.length<=0" @click="removeItems"> <v-icon>mdi-chevron-left</v-icon> Entfernen</v-btn> </v-col>
                <v-col cols="12"> <v-btn block :color="targetSelectedItems.length==targetItemsComp.length ? 'primary' : 'secondary'" :disabled="targetItemsComp.length<=0" @click="removeItemsAll"> <v-icon>mdi-chevron-double-left</v-icon> Alle entfernen </v-btn> </v-col>
              </v-row>
            </v-col>

            <v-col cols=11 sm=8 md=4 lg=4 xl=4>
              <v-data-table
                ref="targetTabel"
                :show-select="true"
                :loading="false"
                :disable-pagination="true"
                :hide-default-footer="true"

                :search="filter"
                :custom-filter="customFilter"

                :headers="translateItems(headerList,'text')"
                fixed-header

                :item-key="itemKey"
                :sort-by="sortBy||itemKey"
                :group-by="groupByComp"

                v-model="targetSelectedItems"
                :items="targetItemsComp"

                @click:row="rowClicked"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:group.header="{items, isOpen, toggle, group}">
                  <th class="hidden-xs-only"> <v-btn icon @click="selectGroupItems(items, 'sourceSelectedItems')"><v-icon color="primary lighten-4"> {{getGroupIcon(items, 'sourceSelectedItems')}} </v-icon></v-btn> </th>
                  <th class="hidden-xs-only" :colspan="headerList.length-1" @click="toggle"> {{ $t(group).upperCaseFirstLetter() }} </th>
                  <th class="hidden-xs-only text-right" @click="toggle"> <v-icon :ref="'sourceTabel_group_'+group" :data-open="isOpen">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> </th>
                  <th class="hidden visible-xs-only">
                    <v-row>
                      <v-col cols=2> <v-btn icon @click="selectGroupItems(items, 'sourceSelectedItems')"><v-icon color="primary lighten-4"> {{getGroupIcon(items, 'sourceSelectedItems')}} </v-icon></v-btn> </v-col>
                      <v-col cols=8 class="pt-5" @click="toggle"> {{ $t(group).upperCaseFirstLetter() }} </v-col>
                      <v-col cols=2 class="pt-5 text-right" @click="toggle"> <v-icon :ref="'sourceTabel_group_'+group" :data-open="isOpen">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> </v-col>
                    </v-row>
                  </th>
                </template>

              </v-data-table>
            </v-col>
          </v-row>

        </div>


        <div class="card-footer mr-4 mt-10">
          <v-card-actions>
            <v-row align="center" justify="center"><v-col cols="auto">
              <v-btn v-if="onCancel" color="secondary" dark class="mx-2" @click="close">{{$t("global.cancel")}}</v-btn>
              <v-btn v-if="onAccept" color="accent" class="mx-2" :disabled="(targetItems.length<=0)" @click="acceptClicked">{{$t("global.apply")}}</v-btn>
            </v-col></v-row>
          </v-card-actions>
        </div>

      </v-card>

  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "MultiSelectMaskElement";

    const APP = window.APP;
    const _h = APP.helper;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); }else{ return args; } }

    /*
    const getMatchedByProperty = function(target, source, prop){
      let matchItems = [];
      target.forEach( function(item,index){
        let matchItem = source.find(function(sItem, sIndex){
          if (item[prop]==sItem[prop]){ return true; }
        });
        if (matchItem){ matchItems.push(matchItem); }
      })
      return matchItems;
    }
    */

    const getMatchedByProperty = function(target, source, prop){
      let matchItems = [];
      target.forEach( function(item,index){
        Object.assign(matchItems, source.filter(function(sItem, sIndex){
          if (item[prop]==sItem[prop]){ return true; }
        }));
      })
      return matchItems;
    }

    const mergeByProperty = function(target, source, prop){
        //let target = JSON.parse(JSON.stringify(_target));
        source.forEach( function(sourceElement){
          let targetElement = target.find( function(targetElement){
            return sourceElement[prop]===targetElement[prop];
          })
          targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
        })
        return target;
      }

    const reduceByProperty = function(target, source, prop){
      return target.filter(function(item, index, array){
        return !source.find(function(sItem,sIndex){
          if (sItem[prop] === item[prop]){ return true; }
        });
      });
    }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {
      },

      props: {
        //value: { type:Boolean, default:function(){ return false; } }, // show dialog
        title: { type:String, default:function(){ return "" } }, //"SearchmaskSnipped.title"
        filterLabel: { type:String, default:function(){ return "global.search" } },
        groupsLabel: { type:String, default:function(){ return "global.groups" } },
        groupItems: { type:Array, default: function(){ return [ ]; } }, // [ { text: "global.groups", value:"groups" }, { text: "global.terminals", value:"terminals" } ]
        groupBy: { type:String, default:function(){ return ""; } }, // "groups"
        sortBy: { type:String, default:function(){ return ""; } }, // "groups"
        itemKey: { type:String, default:function(){ return "id" } },
        //selectionKey: { type:String, default:function(){ return "id" } },
        /*
        tables: { type:Object, default:function(){ return {
          //categoryName: { headers: [], items: [] }
        }; } },
        */

        headers: { type:Array, default:function(){ return []; } },
        items: { type:Array, default:function(){ return []; } },

        apiRequest: { type:Function, _default: function(){ return function(context){}; }},
        onOptions: { type:Function, _default: function(){ return function(newOptions, oldOptions, context){}; }},
        onAccept: { type:Function, _default: function(){ return function(items, context){}; }},
        onCancel: { type:Function, _default: function(){ return function(context){}; } },
      },

      data: function(){ return {
        classname: classname,

        filter: '',
        isLoading: false,

        headerList: [],
        groupValue: this.groupBy, // category||"" -> { category: "cat" }

        sourceItems: [],
        sourceOptions: {},
        sourceSelectedItems: [],

        targetItems : [],
        targetSelectedItems: [],

      } },

      computed: {
        groupByComp: function(){
          let method="groupByComp"; let preLog=classname+".methods."+method; //console.log(preLog, "called", this.groupValue);
          const self = this;
          let group = [];
          if (self.groupValue){ group="groupName"; }
          return group;
        },

        sourceItemsComp: function(){
          let method="sourceItemsComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let sourceItems = JSON.parse(JSON.stringify( reduceByProperty(self.sourceItems, self.targetItems, self.itemKey) ));
          let groupedItems = [];
          if ( self.groupValue ){
            sourceItems.map(function(item, index){
              if ( Array.isArray(item[self.groupValue]) ){
                if ( item[self.groupValue].length<=0 ){ item[self.groupValue].push( i18n("global.notAssigned") ); }
                item[self.groupValue].forEach(function(groupItem, groupIndex){
                  item = JSON.parse(JSON.stringify(item));
                  item["groupName"] = groupItem;
                  //item["itemKey"] = method+"_"+groupItem+"_"+item[self.itemKey];
                  groupedItems.push(item);
                });
              }else{
                item["groupName"] = item[self.groupValue];
                //item["itemKey"] = method+"_"+item[self.groupValue]+"_"+item[self.itemKey];
                groupedItems.push(item);
              }
            });
          }
          if (groupedItems.length>0){
            //console.log(preLog, groupedItems);
            return groupedItems;
          }else{
            //sourceItems.map(function(item, index){ item["itemKey"] = item[self.itemKey]; });
            return sourceItems;
          }
        },
        targetItemsComp:function(){
          let method="targetItemsComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let targetItems = JSON.parse(JSON.stringify( self.targetItems ));
          let groupedItems = [];
          if ( self.groupValue ){
            targetItems.map(function(item, index){
              if ( Array.isArray(item[self.groupValue])){
                if ( item[self.groupValue].length<=0 ){ item[self.groupValue].push( i18n("global.notAssigned") ); }
                item[self.groupValue].forEach(function(groupItem, groupIndex){
                  item = JSON.parse(JSON.stringify(item));
                  item["groupName"] = groupItem;
                  //item["itemKey"] = method+"_"+groupItem+"_"+item[self.itemKey];
                  groupedItems.push(item);
                });
              }else{
                item["groupName"] = item[self.groupValue];
                //item["itemKey"] = method+"_"+item[self.groupValue]+"_"+item[self.itemKey];
                groupedItems.push(item);
              }
            });
          }
          if (groupedItems.length>0){ return groupedItems; }
          else{
            //targetItems.map(function(item, index){ item["itemKey"] = method+"_"+item[self.itemKey]; });
            return targetItems;
          }
        },
      },

      watch: {
        /*
        value: function(newVal, oldVal){
          let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called", newVal, oldVal);
          const self = this;
        },
        */

        groupValue: function(newVal, oldVal){
          const self = this;
          setTimeout(function(){
            self.rowCollapseAll("sourceTabel");
            self.rowCollapseAll("targetTabel");
          }, 0);
        },

        sourceOptions: {
          deep: true,
          handler: function(newVal, oldVal){
            let method="sourceOptions"; let preLog=classname+".watch."+method; //console.log(preLog, "called", newVal, oldVal );
            const self = this;
            //if ( JSON.stringify(newVal) !== JSON.stringify(oldVal) ){ self.getDataFromApi(); }
            if ( typeof(self.onUpdate)==="function" ){ self.onUpdate(newVal, oldVal, self); }
          },
        },

        sourceSelectedItems: function(newVal, oldVal){
          let method="sourceSelectedItems"; let preLog=classname+".watch."+method; //console.log(preLog, "called", newVal, oldVal );
          const self = this;
          /*
          if ( newVal.length > oldVal.length ){
            // itemAdded
            let getMatched = getMatchedByProperty(newVal, self.sourceItemsComp, self.itemKey);
            console.log(preLog, self.sourceItemsComp, getMatched);
          }else{
            // item removed
          }
          */
        }

      },

      methods: {
        getDataFromApi: function(){
          let method="getDataFromApi"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          self.isLoading = true;
          if ( typeof(self.apiRequest)==="function" ){ self.apiRequest(self); }
          else{ self.isLoading = false; }
        },

        /*
        getDataFromFakeApi: function(){
          let method="getDataFromFakeApi"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          if (self.sourceItems.length>0){ return; }
          self.isLoading = true;
          const randomStr = function(length=8){
            // Declare all characters
            let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            // Pick characers randomly
            let str = '';
            for (let i = 0; i < length; i++) {
              str += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return str;
          };
          let tmpItems = (function(){
            let items = [];
            while (items.length<5){
              items.push({
                id:randomStr(),
                btc:Math.random()*(Math.random()*10),
                category: "cat"+APP.helper.randomNumber(0,2),
                categories: [],
                deps: [ "depId1", "depId2" ],
                groups: [ "group1", "group2" ]
              });
            }
            return items;
          })();
          setTimeout(function(){
            self.setTable({
              headers: [
                { text:'Id', value:'id' },
                { text:'Bitcoin', value:'btc' },
              ],
              items: tmpItems,
              page: 1,
              total: 1,
            });
            self.isLoading = false;
          }, 500 )
        },
        */

        translateItems: function(items, key){
          let result = JSON.parse(JSON.stringify(items));
          for (let itemIndex in result ){
            let item = result[itemIndex];
            item[key] = i18n(item[key]);
          }
          return result;
        },

        getGroupIcon: function(groupItems, selectedItemsKeyname){
          let method="groupIconComp"; let preLog=classname+".methods."+method; //console.log(preLog, "called", items);
          const self = this;
          let selectedGroupItems = getMatchedByProperty(groupItems, self[selectedItemsKeyname], self.itemKey);
          let icon = "mdi-checkbox-blank-outline"
          if ( selectedGroupItems.length>0 && selectedGroupItems.length<groupItems.length){ icon = "mdi-minus-box"; }
          if ( selectedGroupItems.length>0 && selectedGroupItems.length==groupItems.length){ icon = "mdi-checkbox-marked"; }
          return icon;
        },
        selectGroupItems: function(groupItems, selectedItemsKeyname){
          let method="groupIconComp"; let preLog=classname+".methods."+method; //console.log(preLog, "called", items);
          const self = this;
          let selectedGroupItems = getMatchedByProperty(groupItems, self[selectedItemsKeyname], self.itemKey);
          if ( selectedGroupItems.length==0 || selectedGroupItems.length<groupItems.length ){
            self[selectedItemsKeyname] = mergeByProperty(self[selectedItemsKeyname], groupItems, self.itemKey);
          }else{
            self[selectedItemsKeyname] = reduceByProperty(self[selectedItemsKeyname], groupItems, self.itemKey);
          }
        },

        customFilter: function(value, search, item){
          let method="customFilter"; let preLog=classname+".methods."+method; //console.log(preLog, "called", value, search, item);
          let self = this;
          value = value.toString().toLowerCase();
          search = search.toString().toLowerCase();
          if ( value.includes(search) ){ return true; }
          if ( self.groupValue && item[self.groupValue] ){
            if ( typeof(item[self.groupValue])==="string" && item[self.groupValue].toLowerCase().includes(search) ){ return true; }
            if ( Array.isArray(item[self.groupValue]) ){
              item[self.groupValue].map(function(groupItem){
                //console.log(preLog, self.groupValue, groupItem)
                return groupItem.toString().toLowerCase(); }).includes(search);
              }
          }
        },

        rowExpandAll(refTable) {
          const self = this;
          if (!refTable){ refTable="sourceTabel"; }
          if (self.$refs[refTable]?.openCache){
            for (const name of Object.keys(self.$refs[refTable].openCache)) {
              self.$refs[refTable].openCache[name] = true;
            }
          }
        },
        rowCollapseAll(refTable) {
          const self = this;
          if (!refTable){ refTable="sourceTabel"; }
          if (self.$refs[refTable]?.openCache){
            for (const name of Object.keys(self.$refs[refTable].openCache)) {
              self.$refs[refTable].openCache[name] = false;
            }
          }
        },

        setTable: function(data){
          let method="setTable"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data);
          const self = this;
          if (data.headers){ self.headerList = data.headers; }
          let hasListItems = !!self.items?.length;
          self.sourceItems = data.items||data||[];
          if ( !hasListItems ){ setTimeout(function(){ self.rowCollapseAll(); }, 0); }
        },

        /*
        filterInputChange: function(data){
          let method="filterInputChange"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data);
          const self = this;
          let elementVue = self.$refs["filterinput"];
          elementVue.blur();
        },
        */

        rowClicked: function(item, body){
           let method="rowClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", item, body);
           const self = this;
           body.select(!body.isSelected);
        },

        addItems: function(event){
          let method="addItems"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          self.targetItems = mergeByProperty(self.targetItems, self.sourceSelectedItems, self.itemKey)
          self.sourceSelectedItems = [];
        },

        addItemsAll: function(){
          let method="addItemsAll"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          self.sourceSelectedItems = self.sourceItems;
          self.addItems();
        },

        removeItems: function(event){
          let method="removeItems"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          self.targetItems = reduceByProperty(self.targetItems, self.targetSelectedItems, self.itemKey);
          self.targetSelectedItems = [];
        },

        removeItemsAll: function(){
          let method="removeItemsAll"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          self.targetSelectedItems = self.targetItems;
          self.removeItems();
        },

        acceptClicked: function(event){
          let method="acceptClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event);
          const self = this;
          self.targetItems.map(function(item,index){
            if ( item?.groupName ){ delete item.groupName; }
            if ( item?.itemKey ){ delete item.itemKey; }
          });
          if ( typeof(self.onAccept)==="function" ){ self.onAccept(self.targetItems, self); }
          else{
            console.log(preLog, self.targetItems);
            self.close();
          }
        },

        close: function(){
          const self = this;
          if ( typeof(self.onCancel)==="function" ){ self.onCancel(self); }
        }
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        //self.getDataFromFakeApi();
        self.getDataFromApi();


        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
