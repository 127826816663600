<style>
  .UiAdminView { }
</style>

<template>
<element v-bind:id="htmlId" v-bind:class="classname">
  <v-row justify="space-around" class="h100">
    <v-col cols=12 md=10>
      <v-card class="pa-2">

        <v-btn v-if="backButtonVisible" fab color="primary" @click="goBack"> <v-icon>mdi-arrow-left</v-icon> </v-btn>

        <h1 class="title"> Ui-Admin Settings</h1>

        <v-card class="pa-2">
          <h1 class="subtitle-2 text-center">Infos</h1>
          <v-row v-for="(item, index) in uiInfo" :key="'uiInfo_'+index" v-on:click="uiInfo_clicked(item)">
            <v-col class="subtitle-1 text-right">{{item.name}} :</v-col>
            <v-col class="subtitle-2 grey--text mt-1">{{item.value}} </v-col>
          </v-row>
        </v-card>

        <br />

        <v-card v-if="bookingsLogComp.length>0" max-height="600px" style="overflow: auto;">
          <h1 class="subtitle-2 text-center">BookingsLog</h1>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(bookingLogItem, bookingLogIndex) in bookingsLogComp"
              :key="'bookingLog_'+bookingLogIndex"
              v-model="bookingsLogPanel"
              multiple
            >
              <v-expansion-panel-header :class="bookingLogItem.status+'--text'">{{bookingLogItem.booking.codeA}} -  {{bookingLogItem.booking.localBookingDate}}</v-expansion-panel-header>
              <v-expansion-panel-content _style="max-height: 500px; overflow-y: auto;">
                <pre>{{bookingLogItem}}</pre>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>

        <br />

        <v-card class="pa-2">
          <h1 class="subtitle-2 text-center">Actions</h1>
          <v-row v-if="isSuperAdminMode">
            <v-col cols=12>
              <v-menu offset-y>
                <template v-slot:activator="{ on }"> <v-btn v-on="on" color="secondary" width="100%"> <v-icon>mdi-chevron-down</v-icon> Views </v-btn> </template>
                <v-list>
                  <v-list-item v-for="(view, index) in getViews" :key="index" to="view.to">{{view.name}}</v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

         <v-row> <v-col cols=12> <v-btn color="success" width="100%" @click="uiReload"> UI-Reload <v-icon class="ml-2">mdi-reload</v-icon> </v-btn> </v-col> </v-row>
         <v-row> <v-col cols=12> <v-btn color="warning" width="100%" @click="foreceReloadApp"> Force UI-Update <v-icon class="ml-2">mdi-cellphone-arrow-down</v-icon> </v-btn> </v-col> </v-row>
         <v-row> <v-col cols=12> <v-btn color="error" width="100%" @click="resetAppData"> Reset App Data <v-icon class="ml-2">mdi-cellphone-erase</v-icon> </v-btn> </v-col> </v-row>
         <v-row v-if="isSuperAdminMode"> <v-col cols=9 sm=10 md=11 class="pr-0"> <v-text-field clearable v-model="jscmd" label="Runtime" @keydown.enter="runJsCmd"></v-text-field> </v-col> <v-col cols=3 sm=2 md=1 class=""> <v-btn width=100% color="primary" v-on:click="runJsCmd">run</v-btn> </v-col> </v-row>
      </v-card>

        <br />

        <v-card class="pa-2" v-if="typeof(remoteConsoleId)==='string'">
          <h1 class="subtitle-2 text-center">Settings</h1>
          <v-row v-if="typeof(remoteConsoleId)==='string'"> <v-col cols=12>
            <v-form>
              <v-row class=""> <v-col cols=9 sm=10 md=11 class="pr-0"> <v-text-field clearable @click:clear="clearRemoteConsoleId" label="Remote-Log Id" v-model="remoteConsoleId" @keydown.enter="setRemoteConsoleId"></v-text-field> </v-col>
              <v-col cols=3 sm=2 md=1 class=""> <v-btn width=100% color="success" v-on:click="setRemoteConsoleId">Save</v-btn> </v-col> </v-row>
            </v-form>
          </v-col> </v-row>
        </v-card>



      </v-card>
    </v-col>
  </v-row>
</element>
</template>

<script type="text/javascript">
/*
*	@author		HM
*	@date: 		2020-01-01
*	@version	0.0.1
*	@updated	2020-01-01
*
* @link       http://
*
*	@param		{Object} 	args 			Default argument object.
*	@param		{type} 		args.key 		description
*
*	@return 	{Object} 	_t.public 			Instance of this class.
*/
'use strict';
export default (function(args){

  // Private
  //------------
  const classname = "UiAdminView";
  const _t = {};
  var _protected = undefined;
  var _public = undefined;

  const APP = window.APP;
  const _h = APP.helper;
  //const i18n = APP.vue.i18n.getI18nMessage;
  const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

  const vm = {
    classname: classname,
    htmlId: _h.generateGUID(),
    isSuperAdminMode: false,

    backButtonVisible: false,

    bookingsLogPanel: [],

    jscmd: "",
    remoteConsoleId: "",

    uiInfo : [
      { id:"0", name:"Ui Build Version", value: APP.config.buildVersion },
      { id:"1", name:"Ui Build Date", value: APP.config.buildDate },
      { id:"2", name:"Ui location", value: window.location.origin },
      { id:"3", name:"SW location", value: (self||{origin:"-n/a-"}).location.origin },
      { id:"4", name:"SaaS Rest", value: ((APP.config.saasWeb||{}).getOrigin||function(){})() },
      { id:"5", name:"SaaS Socket", value: ((APP.config.saasSocket||{}).getOrigin||function(){})() },
      { id:"6", name:"Platform", value: navigator.platform },
      { id:"7", name:"U-A", value:  window.navigator.userAgent },
      { id:"8", name:"serviceWorkerStatus", value:window.servieWorkerStatus },
      { id:"9", name:"serviceWorkerVersion", value:(window.serviceWorkerVersion||"-not set-") },
      { id:"10", name:"Standalone", value: _h.platform.isInStandaloneMode() },
      { id:"11", name:"Caches", value: "empty" },
    ]
  }

  // Protected
  //------------
  _protected = {}
  Object.assign(_t,_protected);
  _protected=_t;

  // Public
  //------------
  _public = {
    template: '#'+classname,
    props: [],
    data: function(){ return vm; },
    computed: {
      appState: function(){
        let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
        const self = this;
        let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
        return appState;
      },

      getViews: function(){
        let method="computed.getViews"; let preLog=classname+"."+method; //console.log(preLog, "called");
        let views = [];
        if ( !APP.Templates || typeof(APP.Templates)!=="object" ){ return views; }
        let templates =  Object.keys(APP.Templates);
        for ( let index in templates ){
          let templatename = templates[index];
          if ( templatename.endsWith("View") ){ views.push(
            { name: templatename.replace( new RegExp("View$"), ""), to: templatename }
          ); }
        }
        return views;
      },

      bookingsLogComp: function(){
        let method="computed.bookingsLogComp"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
        let res = self.appState?.local?.bookingsLog||[]
        res.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        res = res.reverse();
        return res;
      },
     },
    watch: {},
    methods: {
      goBack: async function(){
        let method="methods.goBack"; let preLog=classname+"."+method; //console.log(preLog, "called");
        APP.global.getOverlay().show();
        await APP.global.route.back();
        APP.global.getOverlay().hide();
      },
      clearRemoteConsoleId: function(event){
        let method="methods.clearRemoteConsoleId"; let preLog=classname+"."+method; //console.log(preLog, "called");
        setTimeout(function(){
          vm.remoteConsoleId="";
          //_public.methods.setRemoteConsoleId();
        }, 10);
      },
      setRemoteConsoleId: function(){
        let method="methods.setRemoteConsoleId"; let preLog=classname+"."+method; //console.log(preLog, "called");
        if ( vm.remoteConsoleId==="" ){ if ( window.remoteconsole && typeof(window.remoteconsole.close)==="function" ){ window.remoteconsole.close(); } }
        else{ if ( window.remoteconsole && typeof(window.remoteconsole.register)==="function" ){ window.remoteconsole.register(vm.remoteConsoleId); } }
      },
      runJsCmd: function(){
        let method="methods.runJsCmd"; let preLog=classname+"."+method; //console.log(preLog, "called");
        if ( !vm.jscmd || typeof(vm.jscmd)!=="string" ){
          notify({ type:"warning", text:"Invalid cmd" });
          return;
        }
        try{
          eval(vm.jscmd);
          //vm.jscmd="";
        }
        catch(err){
          notify({ type:"error", text:err });
          console.error(preLog, err);
        }
      },

      uiInfo_clicked: function(item){
        let method="methods.uiInfo_clicked"; let preLog=classname+"."+method; //console.log(preLog, "called");
        function resetUImode(){
          clearTimeout(vm.uiModeTimeoutPid);
          item.timeoutPid=undefined;
          item.counter=0;
        }
        if ( item.id && item.id.toString()==="0" ){
          item.counter = (item.counter||0)+1;
          if ( !item.timeoutPid ){ item.timeoutPid=setTimeout(function(){ resetUImode(); }, 5000) }
          if ( item.counter>=9 ){
            resetUImode();
            let msg = "SuperAdminMode, enabled.";
            console.log(preLog, msg );
            notify({ type:"info", text: msg });
            vm.isSuperAdminMode=true;
          }
        }
      },

      foreceReloadApp: async function(){
        let method="methods.foreceReloadApp"; let preLog=classname+"."+method; //console.log(preLog, "called");
        // Cache clear all local cache
        if ( _h.isOnline() ){
          notify({ type:"info", text:"Clear Ui data." });
          if( caches){ await caches.keys().then(async function(names){ for (let name of names) { await caches.delete(name); } }); }
          notify({ type:"info", text:"Caches: "+JSON.stringify(await caches.keys()) });
          if ( navigator.serviceWorker ){
            await navigator.serviceWorker.getRegistrations().then(function(registrations){
              for (let registration of registrations){ registration.unregister(); }
            }).catch(function(err){
              console.error(preLog,err);
              notify({ type:"error", text:JSON.stringify(err) });
            });
          }
          notify({ type:"info", text:"App update." });
          window.location.reload(true);
        }else{ notify({ type:"warning", text:"Not possible, your are offline" }); }
      },

      uiReload: function(){
        let method="methods.uiReload"; let preLog=classname+"."+method; //console.log(preLog, "called");
        window.location.reload(true);
      },

      resetAppData: function(){
        let method="methods.resetAppData"; let preLog=classname+"."+method; console.log(preLog, "called");
        APP.global.getDialog().show({
          layoutName: "confirm",
          title: "Einstellungen zurücksetzen",
          body: "Sind sie sich sicher das Sie alle Geräteeinstellungen zurück zu setzen möchten?",
          cancel: { text:"Abbrechen" },
          accept: { text:"Löschen" },
          acceptOnClick: function(dialog, event, callback){
            APP.global.getOverlay().show();
            notify({ type:"info", text:"Device reset"} );
            APP.data.clear(true);
            if ( typeof(callback)==="function" ){ callback(); }
            setTimeout( function(){ APP.global.route.change(APP.config.startpage) }, 2000 );
          },
        });
      }
    },
    mounted: function(){
      //if ( APP.global.basepage.change("BasePage_footer") ){ return; }
      let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
      if ( !_public.component ){_public.component=this; }

      if ( !APP.global.basepage.get() || APP.global.basepage.get() === "BlankBasePage" ){ vm.backButtonVisible=true; }
      else{ vm.backButtonVisible=false; }

      APP.global.getOverlay().clear();
      //APP.global.getOverlay().show();

      //vm.active_user = undefined;
      if ( window.remoteconsole ){ vm.remoteConsoleId = window.remoteConsoleId||""; }
      else{ vm.remoteConsoleId=undefined; }

      (async function(){
        //vm.active_user = await APP.data.getOffline("User","Active");
        vm.uiInfo.find(function(item,index){ if(item.name.toString()==="Caches"){ return true; } }).value=(await window.caches.keys()).join(",")||"nothing cached";
        //APP.global.getOverlay().hide();
      })();

    },
  }
  _t.public = _public; _t._public = _public;

  // MAIN
  //------------
  let method = "MAIN"; let preLog = classname+":"+method; console.log( preLog, "called");

  return _public;
})();
</script>
