<style>
  .LoginMultiUserView {  }
</style>

<template>
  <element v-bind:class="classname">
    <v-sheet class="view">

      <v-row dense no-gutter align="center" justify="center" class="h100">
        <v-col cols=12 sm=10 md=8 lg=6 xl=4>
          <v-card class="pa-4" v-if="allowedUsers">
            <a href="/"><v-img contain max-height="100" style="margin-top: -10%;" :src="logo_src||'../../assets/img/logo.png'"></v-img></a>
            <v-list >
              <v-subheader class="text-h6 black--text text-darken-4 text-decoration-underline">Benutzerauswahl</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item v-for="(user, index) in allowedUsers" :key="index" v-on:click="li_userClicked(user)">
                  <v-list-item-avatar> <v-img :src="getUserImage(user)"></v-img> </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="user.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

          </v-card>
        </v-col>
      </v-row>

    </v-sheet>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-06-24
  *	@version	1.1.1
  *	@updated	2020-06-24
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "LoginMultiUserView";

    const APP = window.APP;
    const _h = APP.helper;

    //const i18n = APP.vue.i18n.getI18nMessage;
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      props: [],

      data: function(){ return {
          classname: classname,
          logo_src: undefined
      }; },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },
        allowedUsers: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let allowedUsers = self.appState.device?.allowedUsers;
          return allowedUsers;
        }
      },

      watch: {},

      methods: {
        li_userClicked: function(user){
          let method="li_userClicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called", "user:",user);
          const self = this;
          APP.global.getOverlay().show();
          let parameterData = { id: user.id };
          APP.global.route.change("LoginView", parameterData);
        },
        getUserImage: function(user){
          let method="methods.getUserImage"; let preLog=classname+"."+method; //console.log(preLog, "called", "user:",user, "file_ressources",vm.file_ressources);
          const self = this;
          let userImage = undefined;
          if ( user && user.image && user.image.id ){
            userImage = (self.appState.app_resources||[]).filter(function(item,index){
              if ( item.id===user.image.id ){ return item; }
            })[0];
            if ( userImage ){ userImage="data:"+userImage.contentType+";base64,"+userImage.base64EncodedContent; }
          }
          return userImage||"/assets/img/user.png";
        },
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this; if( self && self._uid ){ _public.component=self; }
        APP.global.getOverlay().clear();
        APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this;
        const appState = self.appState;

        APP.global.basepage.change("BlankBasePage");

        // set Logo
        if ( appState?.general?.logo?.id && appState?.app_resources ){
          let logoId = appState?.general?.logo?.id;
          let logo = appState.app_resources.find(function(item,index){ return item.id===logoId; });
          if ( logo?.contentType && logo.base64EncodedContent ){ self.logo_src = "data:"+logo.contentType+";base64,"+logo.base64EncodedContent; }
        }

        if( self && typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
            APP.global.getOverlay().hide();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    // MAIN
    //------------
    let method = "main"; let preLog = classname+":"+method; //console.log( preLog, "called" );

    return _public;
  })();
</script>
