<style>
    .ClockDigitalElement {}
</style>

<template>
  <element :class="classname">

    <v-row :id="htmlId" align="center" justify="center" class="display-1">
      <v-col cols=auto class="pa-0">
        <v-sheet dark color="blue-grey darken-4" class="hours pa-3">00</v-sheet>
      </v-col>
      <v-col cols=auto class="pa-0"> <v-sheet class="pa-0">:</v-sheet> </v-col>
      <v-col cols=auto class="pa-0">
        <v-sheet dark color="blue-grey darken-4" class="minutes pa-3">00</v-sheet>
      </v-col>
      <v-col cols=auto class="pa-0"> <v-sheet class="pa-0">:</v-sheet> </v-col>
      <v-col cols=auto class="pa-0">
        <v-sheet dark color="blue-grey darken-4" class="seconds pa-3">00</v-sheet>
      </v-col>
    </v-row>

  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "ClockDigitalElement";

    const APP = window.APP;
    const _h = APP.helper;

    const jQuery = window.jQuery;

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:{ demo:"Hello World" }, }
      },

      data: function(){ return {
        classname: classname,
        htmlId: _h.generateGUID(),
      } },

      computed: {},

      watch: {},

      methods: {
        setTime: function(timestamp){
          let method="setTime"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          if ( jQuery("#"+self.htmlId).length<=0 ){ return; }
          if ( !timestamp || typeof(timestamp)!=="object" || typeof(timestamp.getHours)!=="function" ){ timestamp = new Date(); }
          jQuery("#"+self.htmlId+" .hours").text( _h.addPreZeros(timestamp.getHours(),2) );
          jQuery("#"+self.htmlId+" .minutes").text( _h.addPreZeros(timestamp.getMinutes(),2) );
          jQuery("#"+self.htmlId+" .seconds").text( _h.addPreZeros(timestamp.getSeconds(),2) );
        },

        updateTimestamp: function(){
          let method="updateTimestamp"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          self.setTime();
          setTimeout( self.updateTimestamp, 1000);
        }
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
            self.updateTimestamp();
          });
        }
        self.setTime();
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
