<style>
    .ToolbarElement .sticky { }
</style>

<template>
  <element :class="classname">
    <v-app-bar v-if="toolbarItems && toolbarItems.length>0" app dense flat floating extenden width="100%">
      <element v-for="(item, index) in toolbarItems" :key="'toolbar-'+index">
        <v-tooltip v-if="item.tooltip" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-if="item.icon" color="accent2" text v-bind="item.bind" v-on="on" @click="item.click"> <v-icon>{{item.icon}}</v-icon> </v-btn>
            <component v-if="item.component" :is="item.component.is" v-model="item.component.value" v-bind="item.component.bind" v-on="item.component.on"></component>
          </template>
          <span>{{item.tooltip}}</span>
        </v-tooltip>
        <v-btn v-if="!item.tooltip && item.icon" color="accent2" v-bind="item.bind" text @click="item.click"> <v-icon>{{item.icon}}</v-icon> </v-btn>
        <component v-if="!item.tooltip && item.component" :is="item.component.is" v-model="item.component.value" v-bind="item.component.bind" v-on="item.component.on"></component>
        <span v-if="item.divider" class="text-h6 blue-grey--text text--lighten-1 noselect">|</span>
      </element>
    </v-app-bar>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  import * as AllVuetifyComponents from 'vuetify/lib'

  export default (function(){
    // Private
    //------------
    const classname = "ToolbarElement";

    const APP = window.APP;
    //const _h = APP.helper;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: (function(){
        let components = {};
        for ( let componentKey in AllVuetifyComponents){ components[componentKey] = AllVuetifyComponents[componentKey]; }
        return components;
      })(),

      props: {
        items: { type: Array, default:function(){ return []; }, }
      },

      data: function(){ return {
        classname: classname,
      } },

      computed: {
        toolbarItems: function(){
          const self = this;
          let items = JSON.parse(JSON.stringify(self.items));
          items.map(function(item,index){ if(typeof(item.click)!=="function"){ item.click=(self.items[index]||{}).click||function(){}; } });
          items.map(function(item,index){ if(item.on){ item.on=(self.items[index]||{}).on||{}; } });
          items.map(function(item,index){ if(item?.component?.on){ item.component.on=self.items[index].component.on; } });
          items.map(function(item,index){ if(typeof(item.tooltip)==="string"){ item.tooltip=i18n(item.tooltip); } });
          return items;
        }
      },

      watch: {},

      methods: {
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
