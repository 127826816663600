<style>
  .ComponentsElement .accent--text .v-messages__message { color: initial !important; }
  .ComponentsElement .v-input--selection-controls { margin-top: 8px; }
  .ComponentsElement .v-subheader { display: grid; }
  /*.ComponentsElement .required .v-text-field--filled > .v-input__control > .v-input__slot { background: rgba(209, 0, 25, 0.12); }*/
  .ComponentsElement .v-subheader { word-break: break-all; }
  .ComponentsElement .required .v-subheader { font-weight:bold; color:rgb(0, 0, 0, 0.8); _text-decoration:underline; }
  .theme--dark .ComponentsElement .required .v-subheader { font-weight:bold; color:rgb(255, 255, 255, 0.8); _text-decoration:underline; }
</style>

<template>
    <element v-bind:class="classname">

        <component v-bind:is="formComputed.is" v-bind="formComputed.bind">

          <v-row v-bind="formComputed.rbind">

            <v-col v-for="(element, elementKey) in formComputed.elements" :key="'form-'+elementKey" v-bind="element.cbind||{cols:12}">
            <!-- <v-col v-for="(element, elementKey) in formComputed.elements" :key="'form-'+elementKey" v-bind="element.cbind||{cols:12}" @keydown="keydown_col">
              <component :is="element.is" :ref="elementKey" :elementKey="elementKey" v-bind="element.bind" v-model="dataValues[elementKey]" v-on="element.on" @input="inputEvent" @change="changeEvent" @focus="focusEvent" @blur="blurEvent" @keydown="keydown" @click="clickEvent"> -->
              <component :is="element.is" :ref="elementKey" :elementKey="elementKey" v-bind="element.bind" v-model="dataValues[elementKey]" v-on="element.on" @input="inputEvent" @change="changeEvent" @blur="blurEvent" @keydown="keydown">
                <template v-for="(slotItem, slotKey) in element.slots">
                  <component :is="slotItem.is" :slotKey="slotKey" v-bind="slotItem.bind" v-model="(element.slotValues||{})[slotKey]" v-on="slotItem.on" :key="'slotItem-'+slotKey">
                    <template v-if="slotItem.text">{{slotItem.text}}</template>
                    <div v-if="slotItem.html" v-html="slotItem.html"></div>
                    <element v-if="slotItem.slots">
                      <template v-for="(slotItem2, slotKey2) in slotItem.slots">
                        <component :is="slotItem2.is" :slotKey="slotKey2" v-bind="slotItem2.bind" v-model="(element.slotValues||{})[slotKey2]" v-on="slotItem2.on" :key="'slotItem-'+slotKey2">
                          <template v-if="slotItem2.text">{{slotItem2.text}}</template>
                          <div v-if="slotItem2.html" v-html="slotItem2.html"></div>
                        </component>
                      </template>
                    </element>
                  </component>
                </template>
                <template v-if="element.text">{{element.text}}</template>
                <div v-if="element.html" v-html="element.html"></div>
              </component>
            </v-col>
          </v-row>

        </component>

    </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  *   @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  import * as AllVuetifyComponents from 'vuetify/lib'
  //import {VTextField} from 'vuetify/lib'

  export default (function(){
    // Private
    //------------
    const classname = "ComponentsElement";

    const APP = window.APP;
    const _h = APP.helper;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); }else{ return args; } }

    // Public
    //------------
    const _public = {
        template: '#'+classname,

        components: (function(){
          let components = {};
          for ( let componentKey in AllVuetifyComponents){ components[componentKey] = AllVuetifyComponents[componentKey]; }
          return components;
        })(),

        props: {
          /*
          view = {
              is: "v-form",
              bind: {
                class: "",
                rbind: { dense: true, 'no-gutter': true }
              },
              elements: {
                  fullname: { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Nachname", name:"fullname", type:"text", }, model : "", },
                  info: { is: "p", cbind: { cols:12 }, html: "" },
                  company: { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Firma", name:"company", type:"text", }, model : "", },
              }
          }
          view = {
              fullname: { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Nachname", name:"fullname", type:"text", }, model : "", },
              info: { is: "p", cbind: { cols:12 }, html: "" },
              company: { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Firma", name:"company", type:"text", }, model : "", },
          }
          view = {
              is: "v-form",
              bind: { class: ", },
              elements: [
                  { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Nachname", name:"fullname", type:"text", }, model : "", },
                  { is: "p", cbind: { cols:12 }, html: "" },
                  { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Firma", name:"company", type:"text", }, model : "", },
              ]
          }
          view = [
              { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Nachname", name:"fullname", type:"text", }, model : "", },
              { is: "p", cbind: { cols:12 }, html: "" },
              { is:"v-text-field", cbind: { cols:12 }, bind: { disabled:"disabled", label:"Firma", name:"company", type:"text", }, model : "", },
          ]
          */
          view: { type:Object, default:function(){ return {}; } },
          //value: { type:[Object, Array], default:function(){ return {}; } },
          value: { type:Object, default:function(){ return {}; } },
          error: { type:Object, default:function(){ return {}; } },
          //validation: { type:Object, default:function(){ return {}; } },
        },

        data: function(){ return {
            classname: classname,
            dataValues: {},
        } },

        computed: {
          formComputed: function(){
            let method="formComputed"; let preLog=classname+".computed."+method; //console.log(preLog, "called" , "!!!", this.value, this.view);
            const self = this;

            let resultForm = {
              key: self.key,
              is: "v-form", bind: { autocomplete:"off" },
              rbind: { dense:true, 'no-gutters':false },
              elements: {}
            }

            const dataValues = {}

            let value = self.value||{};
            value = JSON.parse(JSON.stringify(value));

            let error = self.error||{};
            error = JSON.parse(JSON.stringify(error));
            for ( let errorKey in error){
              console.log(preLog, "errorKey:", errorKey);
              if ( errorKey.includes(".") ){
                let errorKeyList = errorKey.split(".");
                let newRootKey = errorKeyList.shift()
                if (!error[newRootKey]) { error[newRootKey] = {} }
                error[newRootKey][errorKeyList.join("_")] = error[errorKey];
                delete error[errorKey];
              }
            }

            let viewmodel = self.view;
            let view_elements = viewmodel?.elements||viewmodel;
            viewmodel = JSON.parse(JSON.stringify(viewmodel));
            if ( !viewmodel?.elements ){ viewmodel = { elements: viewmodel } }

            if ( viewmodel?.is && typeof(viewmodel?.is)==="string" && Object.keys(viewmodel?.is).length>0 ){ resultForm.is = viewmodel.is; }
            if ( viewmodel?.bind && typeof(viewmodel?.bind)==="object" && Object.keys(viewmodel?.bind).length>0 ){ resultForm.bind = Object.assign( resultForm.bind, viewmodel.bind); }
            if ( viewmodel?.rbind && typeof(viewmodel?.rbind)==="object" && Object.keys(viewmodel?.rbind).length>0 ){ resultForm.rbind = viewmodel.rbind; }
            //if ( viewmodel?.elements && typeof(viewmodel?.elements)==="object" && Object.keys(viewmodel?.elements).length>0 ){ resultForm.elements = viewmodel.elements; }

            /*
            for ( let viewmodelElement_keyIndex in viewmodel.elements ){
              let viewmodelElement_item = viewmodel.elements[viewmodelElement_keyIndex];
              if ( !isNaN(viewmodelElement_keyIndex) ){
                viewmodel.elements["ComponentsElementItem"+viewmodelElement_keyIndex] = viewmodelElement_item;
                delete viewmodel.elements[viewmodelElement_keyIndex]
              }
            }

            for ( let valueKey in value ){
              console.log(preLog, JSON.stringify(value), valueKey);
              if ( valueKey && !isNaN(valueKey) ){
                  value["ComponentsElementItem"+valueKey] = value[valueKey];
                  delete value[valueKey];
              }
            }
            */
            //console.log(preLog, viewmodel, value)

            for ( let viewmodelElement_keyIndex in viewmodel.elements ){
                let viewmodelElement_item = viewmodel.elements[viewmodelElement_keyIndex];
                //console.log(preLog, viewmodelElement_keyIndex, viewmodelElement_item.on, "!!!", view_elements[viewmodelElement_keyIndex].on, self.view);
                //if ( viewmodelElement_item.on ){ viewmodelElement_item }

                if ( viewmodelElement_item?.bind?.view ){
                  let selfView = self.view[viewmodelElement_keyIndex]; //||self.view["ComponentsElementItem"+viewmodelElement_keyIndex];

                  if ( !value[viewmodelElement_keyIndex] ){
                    for ( let viewItemKey in selfView.bind.view ){
                      //if ( [viewItemKey] in value ){
                      if ( [viewItemKey] in value && !self.view[viewItemKey] ){
                        if ( !value[viewmodelElement_keyIndex] ){ value[viewmodelElement_keyIndex] = {} }
                        value[viewmodelElement_keyIndex][viewItemKey] = value[viewItemKey];
                        if ( !selfView.componentElementValueMergedKeys ){ selfView.componentElementValueMergedKeys=[]; }
                        if ( !selfView.componentElementValueMergedKeys.includes(viewItemKey) ){ selfView.componentElementValueMergedKeys.push(viewItemKey); }
                      }
                    }
                  }else{
                    if ( selfView.componentElementValueMergedKeys ){
                      for ( let valueKeyIndex in selfView.componentElementValueMergedKeys ){
                        let valueKey = selfView.componentElementValueMergedKeys[valueKeyIndex];
                        //self.value[valueKey] = value[viewmodelElement_keyIndex][valueKey];
                        value[valueKey] = value[viewmodelElement_keyIndex][valueKey];
                        //console.log(preLog, viewmodelElement_keyIndex, valueKey, value)
                      }
                    }
                  }
                  viewmodelElement_item.bind.view = selfView.bind.view;
                }
                if ( viewmodelElement_item?.viewTemplate ){
                  viewmodelElement_item.viewTemplate = self.view[viewmodelElement_keyIndex].viewTemplate;
                  //console.log(preLog, viewmodelElement_keyIndex, error[viewmodelElement_keyIndex], value, "xxxxxx", viewmodelElement_item.viewTemplate) // xxxxx
                }

                if ( view_elements[viewmodelElement_keyIndex]?.on ){ viewmodelElement_item.on = view_elements[viewmodelElement_keyIndex].on; }
//                if ( view_elements[viewmodelElement_keyIndex]?.itemsOn ){ viewmodelElement_item.itemsOn = view_elements[viewmodelElement_keyIndex].itemsOn; }
                if ( view_elements[viewmodelElement_keyIndex]?.slots ){
                  for (let slotKey in view_elements[viewmodelElement_keyIndex]?.slots){
                    let slotItem = view_elements[viewmodelElement_keyIndex]?.slots[slotKey];
                    //console.log(preLog, slotKey, slotItem, "!!!!!", self.view[viewmodelElement_keyIndex]);
                    if ( slotItem?.text ){ viewmodelElement_item.slots[slotKey].text = i18n(slotItem?.text); }
                    if ( slotItem?.html ){ viewmodelElement_item.slots[slotKey].html = i18n(slotItem.html); }
                    if ( slotItem && !slotItem.bind ){ slotItem.bind = {}; }
                    if ( slotItem?.is=="v-text-field" ){
                      slotItem.bind = Object.assign({ autocomplete:"off", autocorrect:"off", autocapitalize:"off" }, slotItem.bind);
                    }
                    if ( slotItem?.bind?.label ){ viewmodelElement_item.slots[slotKey].bind.label = i18n(viewmodelElement_item.bind.label); }
                    if ( slotItem?.bind?.items ){
                      for ( let itemIndex in slotItem?.bind?.items ){
                        let item = slotItem?.bind?.items[itemIndex];
                        if ( typeof(item)==="string" ){ slotItem.bind.items[itemIndex] = i18n(item); }
                        if ( typeof(item)==="object" && item.text ){ item.text = i18n(item.text); }
                      }
                    }
                    if ( slotItem.bind.rules ){ viewmodelElement_item.slots[slotKey].bind.rules = slotItem.bind.rules; }
                    if ( slotItem?.on ){ viewmodelElement_item.slots[slotKey].on = slotItem.on; }
                    //console.log(preLog, viewmodelElement_item.slots);
                  }
                }

                if ( !viewmodelElement_item.bind ){ viewmodelElement_item.bind = {}; }
                if ( viewmodelElement_item?.is=="v-text-field" ){
                  viewmodelElement_item.bind = Object.assign({ autocomplete:"off", autocorrect:"off", autocapitalize:"off" }, viewmodelElement_item.bind);
                }
                if ( viewmodelElement_item.bind.rules ){ viewmodelElement_item.bind.rules = view_elements[viewmodelElement_keyIndex].bind.rules; }
                if ( viewmodelElement_item?.is=="v-subheader" ){
                  viewmodelElement_item.text = i18n(viewmodelElement_item.text);
                  resultForm.elements[viewmodelElement_keyIndex]=viewmodelElement_item;
                }
                else{
                  //console.log(preLog, viewmodelElement_keyIndex, viewmodelElement_item, error, "!", typeof(value[viewmodelElement_keyIndex]));
                  if ( error[viewmodelElement_keyIndex] ){
                    //if ( viewmodelElement_item.is == classname && Array.isArray(self.value[viewmodelElement_keyIndex]) ){
                    if ( viewmodelElement_item.is == classname ){
                      viewmodelElement_item.bind.error = error[viewmodelElement_keyIndex];
                    }
                    else{
                      //console.log(preLog, viewmodelElement_keyIndex, error[viewmodelElement_keyIndex], "!!");
                      let errorItem = error[viewmodelElement_keyIndex];
                      if ( typeof(errorItem)!=="object" || Array.isArray(errorItem) ){ errorItem = { ["error-messages"]: errorItem } }
                      if ( typeof(errorItem["error-messages"])==="string" ){ errorItem["error-messages"] = [ errorItem["error-messages"] ] }
                      for ( let messageIndex in errorItem["error-messages"] ){
                        errorItem["error-messages"][messageIndex] = i18n(errorItem["error-messages"][messageIndex]);
                      }
                      if ( !viewmodelElement_item?.bind ){ viewmodelElement_item.bind = {} }
                      viewmodelElement_item.bind['error-messages'] = errorItem["error-messages"];
                    }
                  }

                  if ( viewmodelElement_item?.bind?.label && viewmodelElement_item?.bind?.labelleft  ){
                    let text = viewmodelElement_item?.bind?.labelleft;
                    if ( typeof(text)==="boolean" ){
                      text=viewmodelElement_item.bind.label;
                      delete viewmodelElement_item.bind.label;
                    }


                    let formCols = { label:{}, item: {} }
                    // name = cols | sm | md | lg | xl
                    // value = 1 - 12
                    const calcCols = function(name, value){
                      if (!value){ return; }
                      let maxCols = value;
                      let labelCols =  Math.round(maxCols/3);
                      Object.assign(formCols.label, { [name]: labelCols } );
                      Object.assign(formCols.item, { [name]: maxCols-labelCols } );
                      //return { label: labelCols, formItem: maxCols-labelCols };
                    }
                    calcCols( "cols", viewmodelElement_item?.cbind?.cols||12 );
                    calcCols( "sm", viewmodelElement_item?.cbind?.sm );
                    calcCols( "md", viewmodelElement_item?.cbind?.md );
                    calcCols( "lg", viewmodelElement_item?.cbind?.lg );
                    calcCols( "xl", viewmodelElement_item?.cbind?.xl );

                    //console.log(preLog, viewmodelElement_keyIndex, viewmodelElement_item);
                    if (formCols.label.cols){ resultForm.elements[viewmodelElement_keyIndex+'_label'] = { is:"v-subheader", cbind: Object.assign({}, viewmodelElement_item.cbind, formCols.label), bind: viewmodelElement_item?.bind?.labelBind||{}, text: i18n(text) }; }
                    else{  console.warn(preLog, "Not enough space for LabelCol, in viewModel:", viewmodelElement_item); }
                    resultForm.elements[viewmodelElement_keyIndex] = { cbind: Object.assign({}, viewmodelElement_item.cbind, formCols.item), bind: Object.assign({ _color:"accent", dense:true }, viewmodelElement_item.bind) }
                  }

                  if ( viewmodelElement_item?.bind?.items ){
                    for ( let itemIndex in viewmodelElement_item?.bind?.items ){
                      let item = viewmodelElement_item?.bind?.items[itemIndex];
                      if ( typeof(item)==="string" ){ viewmodelElement_item.bind.items[itemIndex] = i18n(item); }
                      if ( typeof(item)==="object" && item.text ){ item.text = i18n(item.text); }
                    }
                  }

                  resultForm.elements[viewmodelElement_keyIndex] = Object.assign( {}, viewmodelElement_item, resultForm.elements[viewmodelElement_keyIndex] );
                }
              }

            //dataValues = JSON.parse(JSON.stringify(value));
            //dataValues = value;
            for ( let elementKey in resultForm.elements ){
              //console.log(preLog, elementKey, "isIn value", !([elementKey] in dataValues), dataValues[elementKey] !== value[elementKey], value[elementKey], typeof(value[elementKey]) )

              //console.log(preLog, elementKey, (resultForm?.elements||{})[elementKey] );

              if ( (typeof((value||{})[elementKey])!=="object" || Object.keys((value||{})[elementKey]||{}).length<=0 ) && typeof((resultForm?.elements||{})[elementKey]?.viewTemplate)==="object" ){
                let viewSet = (resultForm?.elements||{})[elementKey].viewTemplate;
                let valueItem = {} // { id:0, from: "01.01.2000", to: "31.12.2005" },
                for ( let viewKey in viewSet ){
                  valueItem[viewKey] = "";
                }
                value[elementKey] = [ valueItem ];
              }

              //console.log(preLog, "dataValues", JSON.parse(JSON.stringify(self.dataValues)), JSON.parse(JSON.stringify(value)) );
              if ( (typeof((value||{})[elementKey])==="object" || Object.keys((value||{})[elementKey]||{}).length<=0 ) && typeof((resultForm?.elements||{})[elementKey]?.viewTemplate)==="object" ){
                //console.log(preLog, elementKey, "!!! is:", "object", JSON.parse(JSON.stringify(dataValues)) )
                //let viewTemplate = resultForm.elements[elementKey].viewTemplate||resultForm.elements[elementKey].bind.view;
                let viewTemplate = view_elements[elementKey].viewTemplate;
                resultForm.elements[elementKey].viewTemplate = viewTemplate;
                resultForm.elements[elementKey].bind.view = {}
                dataValues[elementKey] = {}
                let values = value[elementKey];

                if ( !Array.isArray(values) ){
                  let valueArray = [];
                  for ( let valueIndex in values ){
                    let valueIndexKeyMap = valueIndex.split("_");
                    if ( !valueArray[valueIndexKeyMap[0]] ){ valueArray[valueIndexKeyMap[0]] = {} }
                    valueArray[valueIndexKeyMap[0]][valueIndexKeyMap[1]] = values[valueIndex];
                  }
                  values = valueArray;
                }

                for ( let valueIndex in values ){
                  let valueItem = values[valueIndex];
                  //console.log(preLog, valueIndex, valueItem);
                  /*
                  try { valueIndex = parseInt(valueIndex); }catch(err){}
                  if ( typeof(valueIndex)!=="number" ){
                    vlalueIndex = valueIndex.split("_")[0];
                  }
                  */
                  //resultForm.elements[elementKey].bind.view[valueIndex] = JSON.parse(JSON.stringify(viewTemplate));
                  for ( let viewTemplateIndex in viewTemplate ){
                    let viewTemplateItem = viewTemplate[viewTemplateIndex];
                    //console.log(preLog, valueIndex,"_",viewTemplateIndex );
                    resultForm.elements[elementKey].bind.view[valueIndex+"_"+viewTemplateIndex] = JSON.parse(JSON.stringify(viewTemplateItem));
                    if ( typeof(dataValues[elementKey])!=="object" ){ dataValues[elementKey] = {} }
                    dataValues[elementKey][valueIndex+"_"+viewTemplateIndex] = valueItem[viewTemplateIndex];
                    if ( viewTemplateItem?.on ){
                      //resultForm.elements[elementKey].bind.view[valueIndex+"_"+viewTemplateIndex].bind.label = valueIndex+"_"+viewTemplateIndex // remove this
                      resultForm.elements[elementKey].bind.view[valueIndex+"_"+viewTemplateIndex].on = viewTemplateItem.on;
                    }
                    let createFn = viewTemplateItem.onCreate||viewTemplateItem.oncreate;
                    if ( typeof(createFn)==="function" ){
                      let element = resultForm.elements[elementKey].bind.view[valueIndex+"_"+viewTemplateIndex];
                      //console.log(preLog, createFn, elementKey, valueIndex, viewTemplateIndex, resultForm.elements[elementKey].bind.view[valueIndex+"_"+viewTemplateIndex], valueItem)
                      createFn(element, valueItem, valueIndex);
                    }
                  }
                }
                //console.log(preLog, JSON.parse(JSON.stringify(dataValues)), JSON.parse(JSON.stringify(resultForm.elements[elementKey])) );
              }else{
                if( ( !([elementKey] in dataValues) || JSON.stringify(dataValues[elementKey])!==JSON.stringify(value[elementKey]) ) && typeof(value[elementKey])!=="undefined" ){
                  //console.log(preLog, elementKey, "!!! set:", JSON.parse(JSON.stringify(dataValues)), JSON.parse(JSON.stringify(value)), value[elementKey] )

                  dataValues[elementKey] = value[elementKey];

                  //dataValues = Object.assign({}, dataValues, { [elementKey]: value[elementKey] });
                  //self.$emit('input', self.getData() );
                }
              }

            }


            //console.log(preLog, "dataValues:", JSON.stringify(dataValues) );
            /*
            console.log(preLog, JSON.stringify(self.getData()),JSON.stringify(value), JSON.stringify(self.getData())!==JSON.stringify(value) );
            if ( JSON.stringify(dataValues)!==JSON.stringify(value) ){
              //self.$emit('input', dataValues );
            }
            */
            self.dataValues = dataValues;
            //console.log(preLog, "dataValues", JSON.parse(JSON.stringify(self.dataValues)), JSON.parse(JSON.stringify(value)), JSON.parse(JSON.stringify(self.value)) );
            //console.log(preLog, "resultForm:", resultForm);
            //console.log( preLog, resultForm.elements['registerKey'].on?.keydown?.enter )
            return resultForm;
          }
        },

        watch: {
          values: {
            immediate: true,
            handler: function(){
              let method="dataValues"; let preLog=classname+".watch."+method; //console.log(preLog, "called", this.value, this.dataValues);
              const self = this;
            },
          }
          /*
          dataValues: {
            deep: true,
            handler: function(newValue, oldValue){
              let method="dataValues"; let preLog=classname+".watch."+method; //console.log(preLog, "called", this.value, this.dataValues);
              const self = this;
              let mergedData = self.getData();
              //console.log(preLog, mergedData, newValue, oldValue);
              //self.$emit('input', mergedData);
            }
          },
          */
        },

        methods: {
          //forceRerender() { this.componentKey += 1; },
          inputEvent: function(data){
            let method="inputEvent"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data);
            const self = this;
            let mergedData = self.getData();
            //self.$emit('input', mergedData );
            //console.log(preLog, "isArray",Array.isArray(mergedData) ,mergedData, self.dataValues );
            //if ( Array.isArray(mergedData) ){ self.$emit('input', mergedData ); }
            //else{ self.$emit('input', self.dataValues ); }
          },
          changeEvent: function(data){
            let method="changeEvent"; let preLog=classname+".methods."+method; //console.log(preLog, "called", data );
            const self = this;
            let mergedData = self.getData();
            //console.log(preLog, data, mergedData);
            self.$emit('input', mergedData );
            self.$emit('change', mergedData );
          },
          /*
          focusEvent: function(event){
            let method="focusEvent"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event );
            const self = this;
            let element = event.target;
            let elementVue = (self.$refs[element.getAttribute("elementKey")]||[])[0];
            if (!element.changeLock){
              element.valueOnFocus = elementVue.value;
              element.changeLock = true;
            }
            self.$emit('focus', event );
          },
          */
          blurEvent: function(event){
            let method="blurEvent"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event, this.getData() );
            const self = this;
          },
          /*
          blurEvent: function(event){
            let method="blurEvent"; let preLog=classname+".methods."+method; console.log(preLog, "called", this );
            const self = this;

            let element = event.target;
            let elementKey = element.getAttribute("elementKey");
            let elementVue = (self.$refs[elementKey]||[])[0];
            //console.log(preLog, 1, element.changeLock ,"&&", elementVue.value!=element.valueOnFocus, elementVue.value, "!=", element.valueOnFocus);
            setTimeout(function(){
              //console.log(preLog, 2, elementKey, self, element.changeLock ,"&&", elementVue.value!=element.valueOnFocus, elementVue.value, "!=", element.valueOnFocus);
              if ( element.changeLock && element.value!=element.valueOnFocus && typeof(self.value)==="object" ){
                self.value[elementKey] = element.valueOnFocus;
                delete element.valueOnFocus;
              }
              delete element.changeLock;
            },100);

            self.$emit('blur', event );
          },
          clickEvent: function(event){
            let method="focusEvent"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event );
            const self = this;
          },

          keydown_col: function(event){
            let method="keydown_col"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
            const self = this;
            self.keydown(event,"col")
          },
          */

          stopPropagation: function(event){
            event.stopPropagation();
            event.preventDefault();
          },

          keydown: function(event, target){
            let method="keydown"; let preLog=classname+".methods."+method; //console.log(preLog, "called", event, target, this);
            const self = this;

            let element = event.target;
            let elementKey = element.getAttribute("elementKey");
            let elementVue = (self.$refs[elementKey]||[])[0];
            //console.log(preLog, elementKey, element, element.changeLock, elementVue)

            function findElement(currentElement, move){
              if (!currentElement || currentElement.classList.contains("container") ){ return; }
              move = (move||"").toLowerCase();
              if ( !(move==="up" || move==="down") ){ move="down"; }
              if ( currentElement.tagName.toLowerCase()=="input" ){
                // is input element
                if ( currentElement.id!=element.id ){ return currentElement; }
                let hasSibling = false;
                if ( move==="up" && currentElement.previousSibling ){ hasSibling=true; currentElement = currentElement.previousSibling; }
                if ( move==="down" && currentElement.nextSibling ){ hasSibling=true; currentElement = currentElement.nextSibling; }
                if ( !hasSibling ){ currentElement = currentElement.parentElement; }
                return findElement(currentElement, move);
              }else{
                // div or other element
                let inputList = Array.from(currentElement.querySelectorAll("input:not([type=hidden]"));
                let inputIndex = inputList.findIndex(function(item,index){ if(item.id==element.id){ return true; } });
                if ( inputIndex==-1){
                  if ( move==="up" ){ inputIndex=inputList.length-1; }
                  if ( move==="down" ){ inputIndex=0; }
                }
                else{
                  if ( move==="up" ){ inputIndex--; }
                  if ( move==="down" ){ inputIndex++; }
                }
                if ( inputList[inputIndex] ){ return inputList[inputIndex]; }
                currentElement = currentElement.parentElement;
                return findElement(currentElement, move);
              }
            }

            function focueElement(move){
              //let elementVue = (self.$refs[element.getAttribute("elementKey")]||[])[0];
              self.stopPropagation(event);
              let newElement = findElement(element, move);
              //console.log(preLog, newElement);
              if (newElement){
                if ( typeof(elementVue.blur)==="function" ){ elementVue.blur(); }
                if ( typeof(element?.blur)==="function" ){ element.blur(); }
                let newElementVue = (self.$refs[newElement.getAttribute("elementKey")]||[])[0];
                if (typeof(newElement?.focus)==="function"){ newElement.focus(); }
                if (typeof(newElementVue?.focus)==="function"){ newElementVue.focus(); }
                //console.log(preLog, newElement, newElement.type, newElement.value)
              }
            }

            if ( event.keyCode==13 ){ //Enter
              self.stopPropagation(event);
              focueElement("down");
            }
            /*
            if ( event.keyCode==38 ){ //ArrowUp
              self.stopPropagation(event);
              if ( elementVue.$options.name=="v-select" && elementVue.isMenuActive ){ return; }
              focueElement("up");
            }

            if ( event.keyCode==40 ){ //ArrowDown
              self.stopPropagation(event);
              if ( elementVue.$options.name=="v-select" && elementVue.isMenuActive ){ return; }
              focueElement("down");
            }

            if ( event.keyCode==37 ){ //ArrowLeft
              //if ( elementVue.$options.name=="v-select" && elementVue.isMenuActive ){ return; }
              //focueElement("up");
            }

            if (  event.keyCode==39 ){ //ArrowRight
              //if ( elementVue.$options.name=="v-select" && elementVue.isMenuActive ){ return; }
              //focueElement("down");
            }
            */

          },

          getData: function(){
            let method="getData"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
            const self = this;

            let argValues = JSON.parse(JSON.stringify(self.value));
            let dataValues = JSON.parse(JSON.stringify(self.dataValues));

            function valuesParser(values){
              let resultItem = {};
              if ( Array.isArray(values) ){ resultItem = []; }
              for ( let valueKey in values ){
                let valueItem = values[valueKey];
                if ( typeof(valueItem)==="object" ){
                  if ( typeof(parseInt(valueKey))==="number" && !isNaN(parseInt(valueKey)) ){
                    valueKey = parseInt(valueKey);
                    if ( !Array.isArray(resultItem) ){ resultItem=[]; }
                  }
                  //console.log(preLog, valueKey, "isArray:", Array.isArray(valueItem), valueItem, typeof(valueItem) );
                  resultItem[valueKey] = valuesParser(valueItem);
                }else{
                  let valueKeyList = valueKey.split("_");
                  let valueKeyListItem = valueKeyList[0];
                  //console.log( preLog, valueKey, "isNumber", typeof( parseInt(valueKeyListItem) )==="number" ,"&&", !isNaN( parseInt(valueKeyListItem) ) )
                  if ( typeof( parseInt(valueKeyListItem) )==="number" && !isNaN( parseInt(valueKeyListItem) ) ){
                    if ( !Array.isArray(resultItem) ){ resultItem=[]; }
                    valueKeyListItem = parseInt(valueKeyListItem);
                  }
                  if ( valueKeyList[1] ){
                    if ( !resultItem[valueKeyListItem] ){ resultItem[valueKeyListItem] = {}; }
                    resultItem[valueKeyListItem][valueKeyList[1]] = valueItem;
                  }else{
                    resultItem[valueKeyListItem] = valueItem;
                  }
                }
              }
              return JSON.parse(JSON.stringify(resultItem));
            }

            let result = valuesParser(dataValues);
            argValues = valuesParser(argValues);
            result = Object.assign( argValues, result );
            /*
            console.log(preLog, result, typeof(result) );
            for (let valueKey in result){
              if (valueKey.startsWith("ComponentsElementItem") && !isNaN(valueKey.replace("ComponentsElementItem","")) ){
                result[valueKey.replace("ComponentsElementItem", "")] = result[valueKey];
                delete result[valueKey];
              }
            }
            */
            return result;
          }
        },

        //created: async function(){ let method="created"; let preLog=classname+"."+method; console.log(preLog, "called"); },

        mounted : function(){
            let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called", JSON.stringify(this.value) );
            const self=this;

            //console.log(preLog, self.value, "!!!");

            if (typeof(self.$nextTick)==="function"){
              self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
                let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
              });
            }
        },
    }

    return _public;
  })();
</script>
