import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const classname = 'router'

const APP = window.APP;
const _h = APP.helper;

const routes = []
for ( let templateName in APP.Templates ){
  let method="InitViewRoute."; let preLog=classname+"."+method; //console.log(preLog, 'templateName:',templateName);
  if ( templateName.endsWith("View") ){ routes.push({ path:'/'+templateName, name: templateName, component: APP.Templates[templateName], props: true }); }
}
if ( APP && APP.config && APP.config.startpage && typeof(APP.config.startpage)==="string" ){ routes.push( { path:"/", redirect:'/'+APP.config.startpage } ); }

const router = new VueRouter({
  mode: false,//'history', // false || 'history'
  base: process.env.BASE_URL,
  routes
});

router.beforeEach( async function(to, from, next){
  let method="beforeEach"; let preLog=classname+"."+method; //console.log( preLog, "called", "to:",to, "from:",from);
  if ( !to.path.endsWith("View") ){ console.warn(preLog, 'Invalid route call, must be a "View"-Template.', to.path); return; }
  if ( to && to.params && to.query && Object.keys(to.params).length<=0 && Object.keys(to.query).length>0 ){ Object.assign(to.params,to.query); }
  let route = routes.find(function(item,index){ if (item.path && to.path && item.path.toLowerCase()===to.path.toLowerCase() ){ return true; } } );
  if ( !route ){ console.warn(preLog, "No valid route found for:", to, 'Check Templates or Components initialized correctly.'); return; }
  //console.log(preLog, APP.AppView.component)
  next();
});

router.afterEach(function(to, from){
  let method="afterEach"; let preLog=classname+":router."+method; //console.log( preLog, "called", "to:",to, "from:",from);
  if ( APP && to.matched[0] && to.matched[0].components && to.matched[0].components.default ){ APP.view = to.matched[0].components.default.extendOptions||to.matched[0].components.default; }
});


router.public = {
  change: function(hash, paramObjOrForeceReload, callback){
      var method="change"; var preLog=classname+".public."+method; //console.log(preLog, "hash:",hash);
      if (!hash||typeof(hash)!=="string"){ console.warn(preLog, "Missing or invalid hash, must be typeof 'string' but is:", typeof(hash), "Hash:",hash); return; }
      if ( typeof(callback)!=="function" && typeof(paramObjOrForeceReload)==="function" ){ callback=paramObjOrForeceReload; paramObjOrForeceReload=undefined; }

      let forceReload = false;
      if ( paramObjOrForeceReload===true ){ forceReload=true; paramObjOrForeceReload=undefined; }

      let route = { name: hash, params: paramObjOrForeceReload, query:paramObjOrForeceReload }; //console.log(preLog, "new route:",route);
      router.push(route, callback, function(err){ /*console.error(preLog,err);*/ });

      if ( forceReload===true ){
        APP.global.getOverlay().show();
        window.location.reload();
      }
  },

  back: async function(counter){
    var method="back"; var preLog=classname+".public."+method;
    const history = router.history;
    history.go(-1);
  },

}

export default router
