<style>
    ._TemplateElement {}
</style>

<template>
    <element v-bind:class="classname">
        {{ computedArgs.demo }}
    </element>
</template>


<script type="text/javascript">
    /*
    *	@author		HM
    *	@date: 		2020-05-09
    *	@version	0.1.1
    *	@updated	2020-05-09
    *   @link       http://
    *
    *	@return 	{Object} 	_t.public 			Instance of this class.
    */
    'use strict';
    //import * as AllVuetifyComponents from 'vuetify/lib' // import for v-bind:is dynamical bindings

    export default (function(){
        // Private
        //------------
        const classname = "_TemplateElement";

        const APP = window.APP;
        const _h = APP.helper;

        // Public
        //------------
        const _public = {
            template: '#'+classname,

            components: (function(){
              let components = {};
              //for ( let componentKey in AllVuetifyComponents){ components[componentKey] = AllVuetifyComponents[componentKey]; } // enable for v-bind:is dynamical bindings
              return components;
            })(),

            props: {
              args: { type: Object, default:{ demo:"Hello World" }, }
            },

            data: function(){ return {
                classname: classname,
            } },

            computed: {
                argsComputed: function(){
                    let method="argsComputed"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
                    let computedArgs = this.args;
                    return computedArgs;
                }
            },

            watch: {},

            methods: {},

            created: async function(){
              let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
              const self = this;
            },

            mounted : function(){
                let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
                const self = this;

                if (typeof(self.$nextTick)==="function"){
                  self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
                    let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
                  });
                }
            },
        }

        return _public;
    })();
</script>
