<style>
    .SpreadsheetElement {}
    .SpreadsheetElement tr { border: 0px !important; }
    .SpreadsheetElement td {
      height: var(--cellheight) !important;
      border: 0px !important;
      padding: 0px !important;
    }
    .SpreadsheetElement td .v-text-field .v-input__control { border-radius: 0px; }
    .SpreadsheetElement td .v-text-field .v-input__slot {
      min-height: var(--cellheight) !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .SpreadsheetElement td .v-text-field, .SpreadsheetElement td .v-text-field .input__slot , .SpreadsheetElement td .v-text-field fieldset {
      transition-duration: 0s !important;
    }
    .SpreadsheetElement td .v-text-field .v-input__slot input {
      padding-top: 0px;
      padding-bottom: 0px;
      caret-color: auto;
    }
    .SpreadsheetElement td .v-input input { text-align: center; }
    .SpreadsheetElement td .v-input.text-left input{
      text-align: left;
      padding-left: 5px;
    }
    .SpreadsheetElement td .v-input.text-right input{
      text-align: right;
      padding-right: 5px;
    }

    .SpreadsheetElement .theme--light tr:hover { background-color: rgba(0, 0, 0, 0.05) !important; }
    .SpreadsheetElement .theme--dark tr:hover { background-color: rgba(255, 255, 255, 0.05) !important; }

    .SpreadsheetElement .theme--light tr.active { background-color: rgba(0, 0, 0, 0.12) !important; }
    .SpreadsheetElement .theme--dark tr.active { background-color: rgba(255, 255, 255, 0.12) !important; }

    .SpreadsheetElement .theme--light td .v-input .v-input__slot input:read-only { background-color: rgba(0, 0, 0, 0.05) !important; color: black; }
    .SpreadsheetElement .theme--dark td .v-input .v-input__slot input:read-only { background-color: rgba(255, 255, 255, 0.05) !important; color:white; }

    .SpreadsheetElement .v-input { margin-top: 0px; }
    .SpreadsheetElement input { height: 100%; }
    .SpreadsheetElement .theme--light td .v-input .v-input__slot input:disabled { background-color: rgba(0, 0, 0, 0.12) !important; color: black; }
    .SpreadsheetElement .theme--dark td .v-input .v-input__slot input:disabled { background-color: rgba(255, 255, 255, 0.12) !important; color:white; }
    .SpreadsheetElement td .v-input.v-input--is-disabled .v-input__slot::before { border-image: inherit !important; }

    .SpreadsheetElement td .v-input.plain .v-input__slot input:read-only, .SpreadsheetElement td .v-input.plain .v-input__slot input:disabled { background-color: transparent !important; }

    .SpreadsheetElement .theme--light.table-striped tr:nth-of-type(even) { background-color: rgba(0, 0, 0, .05); }
    .SpreadsheetElement .theme--dark.table-striped tr:nth-of-type(even) { background-color: rgba(255, 255, 255, .05); }

    ._SpreadsheetElement td .v-text-field .v-input__slot input[mode="focused"]{ caret-color: transparent; }
</style>

<template>
  <element :class="classname">

    <v-data-table class="_table-striped elevation-1" dense
      :disable-sort="disableSort"
      :headers="headerComp"
      :fixed-header="true"
      :height="600"
      mobile-breakpoint="0"
      :items="valueComp"
      :items-per-page="itemsPerPage"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        '_items-per-page-all-text': $t('vuetify.dataFooter.itemsPerPageAll'),
        'items-per-page-options': [ 10, 20, 50, 100, -1 ],
        _itemsPerPageText: $t('vuetify.dataFooter.itemsPerPageText'),
        _pageText: $t('vuetify.dataFooter.pageText'),
      }">

      <template v-slot:item="{ item, index }">
        <tr>
          <element v-for="(colItem, colKey, colIndex) in item" :key="'r'+index+'c'+colIndex">
            <td v-if="getHeader(colKey)" :ref="index+'_'+colIndex"  :id="index+'_'+colIndex">
              <v-text-field v-if="getHeader(colKey).is=='v-text-field'" :colKey="colKey" v-bind="(getHeader(colKey)||{}).bind" v-model="item[colKey]" v-on="(getHeader(colKey)||{}).on"                   full-width dense hide-details single-line @focus="focus" @blur="blur" @keydown="keydown" @click="clicked" color="accent" autocomplete="no" autocorrect="off" autocapitalize="off"></v-text-field>
              <v-text-field v-if="!getHeader(colKey).is" class="plain"  :colKey="colKey" v-bind="(getHeader(colKey)||{}).bind" v-model="item[colKey]" v-on="(getHeader(colKey)||{}).on" readonly disabled full-width dense hide-details single-line @focus="focus" @blur="blur" @keydown="keydown" @click="clicked" color="accent" autocomplete="no" autocorrect="off" autocapitalize="off"></v-text-field>
            </td>
          </element>
        </tr>
      </template>

    </v-data-table>

  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "SpreadsheetElement";

    const APP = window.APP;
    const _h = APP.helper;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    //const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:function(){ return { demo:"Hello World" } } },
        header: { type: Array, default:function(){ return []; } },
        value: { type: Array, default:function(){ return []; } },
        disableSort: { type: Boolean, default:function(){ return true; } }
      },

      data: function(){ return {
        classname: classname,
        itemsPerPage: 50,
      } },

      computed: {
        valueComp: function(){
          let method="valueComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called", this.$vnode.key, this.value);
          const self = this;
          return self.value;
        },

        headerComp: function(){
          let method="headerComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let viewModel = JSON.parse(JSON.stringify(self.header));
          viewModel.forEach(function(item, index){ item.text=i18n(item.text); })
          return viewModel;
        },

      },

      watch: {},

      methods: {
        blur: function(event){
          let method="blur"; let preLog=classname+".methods."+method; //console.log(preLog, event);
          event.target.removeAttribute("mode");
          event.target.closest("tr").classList.remove("active");
        },

        clicked: function(event){
          let method="clicked"; let preLog=classname+".methods."+method; //console.log(preLog, event);
          event.target.setAttribute("mode", 'selected');
        },

        focus: function(event){
          let method="focus"; let preLog=classname+".methods."+method; //console.log(preLog, event);
          let elementTr = event.target.closest("tr");
          let elementInput = event.target;
          elementInput.setAttribute("mode", 'focused');
          let hasActive = elementTr.classList.contains("active");
          if (!hasActive){ elementTr.classList.add("active"); }
          setTimeout(function(){
            if (hasActive){ elementTr.classList.add("active"); }
            if( elementInput.value.length>0 ){
              try{ elementInput.setSelectionRange(0, elementInput.value.length); }
              catch(err){ /*console.error(preLog, err);*/ }
            }
          }, 10 );
        },

        getHeader: function(colKey){
          let method="getHeader"; let preLog=classname+".methods."+method; //console.log(preLog, colKey);
          const self = this;
          return self.header.find(function(item, index){ return (item.value==colKey); });
        },

        keydown: function(event){
          let method="keydown"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;

          const skipReadonly = false;
          const elementId = event.target.closest("td").id;
          const refs = self.$refs; //console.log(preLog, refs)

          let elementMode = event.target.getAttribute("mode");
          //let keydownCounter = event.target.getAttribute("keydown");
          //try { keydownCounter=parseInt(keydownCounter); }catch(err){ console.error(preLog, err); }

          function nextElement(elementId, y=0, x=0, posY, posX){
            if ( typeof(posY)!=="number" ){ posY = parseInt(elementId.split("_")[0]) + y; }
            if ( typeof(posX)!=="number" ){ posX = parseInt(elementId.split("_")[1]) + x; }

            let nextElementId=posY+"_"+posX; //console.log(nextElementId);

            if ( refs[nextElementId] ){
              let elementInputNext = refs[nextElementId][0].getElementsByTagName("input")[0];
              if ( !elementInputNext ){ return; } // no input element found
              if ( elementInputNext.disabled ){ return nextElement(nextElementId, y, x); }
              if ( skipReadonly && elementInputNext.readOnly ){ return nextElement(nextElementId, y, x); }
              else{
                elementInputNext.focus();
                event.preventDefault();
                return elementInputNext;
              }
            }
          }

          //console.log(preLog, event, event.key, event.keyCode, elementId);

          if ( event.keyCode==13 ){ //Enter
            if ( nextElement(elementId, 0, 1) ){ return; }
            if ( nextElement(elementId, 1, null, undefined, 0) ){ return; }
            if ( elementId ){ refs[elementId][0].getElementsByTagName("input")[0].blur(); }
            return;
          }

          if ( event.keyCode==38 ){ /*ArrowUp*/
            if ( nextElement(elementId, -1, 0) ){ return; }
          }

          if ( event.keyCode==40 ){ /*ArrowDown*/
            if ( nextElement(elementId, 1, 0) ){ return; }
          }

          let enableMoveXleft = !!(event.target.selectionStart===event.target.selectionEnd && event.target.selectionStart<=0)||event.target.readOnly||elementMode=="focused";
          if ( enableMoveXleft && event.keyCode==37 ){ /*ArrowLeft*/
            if ( nextElement(elementId, 0, -1) ){ return; }
          }

          let enableMoveXright = !!(event.target.selectionStart===event.target.selectionEnd && event.target.selectionEnd>=event.target.value.length)||event.target.readOnly||elementMode=="focused";
          if ( enableMoveXright && event.keyCode==39 ){ /*ArrowRight*/
            if ( nextElement(elementId, 0, 1) ){ return; }
          }

          //event.target.setAttribute("keydown", keydownCounter+1);
          event.target.setAttribute("mode", 'edit');
        }
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
