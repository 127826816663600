<style>
  .SaldenOverviewView {  }
</style>

<template>
  <element :class="classname">
    <v-sheet class="view">

      <v-row dense no-gutter align="start" justify="center" class="h100">
        <v-col sm=12 md=10>
          <v-card class="pa-2">

            <h1 class="title">Salden&uuml;bersicht</h1>

              <v-row v-for="(balance, index) in (userSections.balanceSection||{}).accounts" :key="index" align="center" justify="center">
                <v-col class="subtitle-1 text-right">{{balance.name}} :</v-col>
                <v-col class="subtitle-2 grey--text">{{balance.value}} </v-col>
              </v-row>
              <v-row v-if="(userSections.balanceSection||{}).accounts && (userSections.balanceSection||{}).accounts.length<=0">
                <v-col xs=12 class="text-center"> - Keine Salden vorhanden - </v-col>
              </v-row>

          </v-card>
        </v-col>
      </v-row>

    </v-sheet>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-11-13
  *	@version	1.1.1
  *	@updated	2020-11-13
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "SaldenOverviewView";

    const APP = window.APP;
    const _h = APP.helper;

    //const i18n = APP.vue.i18n.getI18nMessage;
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      props: [],

      data: function(){ return {
          classname: classname,
      }; },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },
        userSections: function(){
          let method="userSections"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let userSections = {};
          if (self.appState.sections && typeof(self.appState.local?.userActive?.id)!="undefined"){ userSections = self.appState.sections[self.appState.local.userActive.id]; }
          return userSections||{};
        },
      },

      watch: {},

      methods: {},

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self=this; if( self && self._uid ){ _public.component=self; }
        APP.global.getOverlay().clear();
        APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this;

        APP.global.basepage.change("BaselineBasePage");

        if( self && typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
            APP.global.getOverlay().hide();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    return _public;
  })();
</script>
