<style>
    .QrScannerView { }
</style>

<template>
  <element :class="classname">

    <v-row dense no-gutter align="start" justify="center" class="h100">
      <v-col cols=12 md=10>
        <v-card class="pa-2 h100">

          <v-btn v-on:click="goBack" color="primary" fab> <v-icon x-large>mdi-arrow-left-circle</v-icon> </v-btn>

          <div v-if="cameraSelectProps.bind.items.length>0" class="w100">
            <v-row v-if="cameraSelectProps.bind.items.length>1"> <v-col cols=12> <v-select hide-details v-bind="cameraSelectProps.bind" v-model="cameraSelectProps.model" v-on="cameraSelectProps.on"></v-select> </v-col> </v-row>
            <v-row aling="center" justify="center">
              <v-col v-if="!scanActiveAutostart" class="text-center"> <v-btn v-on:click="scanStart" :disabled="scanActive" color="primary">Start</v-btn> </v-col>
              <v-col v-if="!scanActiveAutostart" class="text-center"> <v-btn v-on:click="scanStop" :disabled="!scanActive" color="error">Stop</v-btn> </v-col>
              <v-col class="text-center"> <v-btn v-on:click="reloadPage" color="warning">Reset</v-btn> </v-col>
            </v-row>
          </div>

          <v-row> <v-col>
            <!-- Fallback for iOS PWA -->
            <!-- <input id="fileinput" class="hidden" type="file" accept="image/*;capture=camera"  v-on:change="handleFile($event.target.files)"> -->
            <!-- Shows our camera stream -->
            <video id="video" v-bind:class="{ hidden: !scanActive }" width="100%"></video>
            <!-- Used to render the camera stream images -->
            <canvas id="canvas" class="hidden"></canvas>
          </v-col> </v-row>

        </v-card>
      </v-col>
    </v-row>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-01-01
  *	@version	0.0.1
  *	@updated	2020-02-19
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "QrScannerView";

    const APP = window.APP;
    const jsQR = window.jsQR;
    const _h = APP.helper;

    //const i18n = APP.vue.i18n.getI18nMessage;
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Protected
    //------------
    const _protected = {
      // deviceFilters = "videoinput" || "audiooutput" || "audioinput"
      getMediaDevicesList: async function(deviceFilter){
        let method="_protected.getCameras"; let preLog=classname+"."+method; //console.log(preLog, "called");
        var devices = await navigator.mediaDevices.enumerateDevices();
        //console.log(preLog, devices);
        if (!deviceFilter){ return devices; }
        let filteredDevices = devices.filter(function(device,index){ if(device.kind===deviceFilter){ return device; } });
        return filteredDevices;
      },

      proceedScanResult: function(qrResult){
        let method="_protected.proceedScanResult"; let preLog=classname+"."+method; console.log(preLog, "called", "qrResult:",qrResult);
        //alert("code:"+qrResult+"!");
        if( !qrResult ){ self.scanStart(); return; }
        try { qrResult=JSON.parse(qrResult); }
        catch(err){ qrResult=undefined; }
        if ( qrResult && qrResult.licenseId && qrResult.registerKey ){
          //alert("code:"+JSON.stringify(qrResult)+"!1");
          APP.global.getOverlay().show();
          notify( { type:"success", text:"Scan erfolgreich." } );
          APP.global.route.change("DeviceRegisterView", qrResult);
        }
        else{
          //alert("code:"+JSON.stringify(qrResult)+"!2");
          notify({type:"error", text:"Scan ungültig, bitte erneut scannen."});
          self.scanStart();
        }
      }
    }

    // Public
    //------------
    const _public = {
        template: '#'+classname,

        props: [],
        data: function(){ return {
          classname: classname,

          scanActiveAutostart : true,
          scanActive : false,
          fileInputElement: undefined,
          videoElement: undefined,
          canvasElement: undefined,
          cameraSelectProps: {
            bind: { label: "Kameraauswahl", items: [] },
            model: undefined,
            on: {
              change: function(deviceId){
                self.scanStop();
                self.scanStart(deviceId);
              }
            }
          },

          //qrResult : undefined,
        }; },
        computed: {},
        watch: {},
        methods: {

          goBack: function(){
            APP.global.route.back();
          },

          reloadPage: function(){
            APP.global.getOverlay().show();
            window.location.reload();
          },

          scanStart: async function(deviceId){
            let method="methods.scanStart"; let preLog=classname+"."+method; //console.log(preLog, "called", deviceId);
            const self=this;

            if( typeof(deviceId)!=="string" ){ deviceId=undefined; }

            /*
            if ( !deviceId ){
              let devices = await _protected.getMediaDevicesList("videoinput");
              deviceId = (devices.find(function(item,index){ if( typeof(item.label)==="string" && item.label.toLowerCase().indexOf("back")>=0){ return true; } })||{}).deviceId;
            }
            */

            var stream = undefined;
            if ( deviceId ){ stream = await navigator.mediaDevices.getUserMedia( { video: { deviceId: deviceId } } ) } // { video: { deviceId: myPreferredCameraDeviceId } }
            else{ stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode:'environment' } } ); } // {facingMode : <"user" || "environment"> }
            //console.log(preLog, "deviceId:"+deviceId,  "stream;",stream)

            let videoTrack = stream.getVideoTracks()[0]; //stream.getVideoTracks().forEach(function(track) { if(track.label && track.label.toLowerCase().indexOf("front")>=0 ){ self.videoElement.style.transform="scaleX(-1)"; } }); // style="transform: scaleX(-1);"
            if ( videoTrack && videoTrack.label.toLowerCase().indexOf("front")>=0 ){ self.videoElement.style.transform="scaleX(-1)"; }
            else{ self.videoElement.style.transform = "scaleX(1)"; }
            self.cameraSelectProps.model = videoTrack.getSettings().deviceId;

            self.videoElement.srcObject = stream;
            self.videoElement.setAttribute("playsinline", true);
            self.videoElement.play().catch(function(err){ console.log(preLog, err); })
            self.scanActive = true;
            window.requestAnimationFrame(self.scan);
          },

          scan: function(){
            let method="methods.scan"; let preLog=classname+"."+method; //console.log(preLog, "called");
            const self=this;
            if ( !document.getElementById("canvas") ){ self.scanStop(); }
            if ( !self.scanActive ){ return; }
            if ( self.videoElement.readyState === self.videoElement.HAVE_ENOUGH_DATA ){
              self.canvasElement.height = self.videoElement.videoHeight;
              self.canvasElement.width = self.videoElement.videoWidth;

              let canvasContext = self.canvasElement.getContext('2d');
              canvasContext.drawImage(self.videoElement, 0, 0, self.canvasElement.width, self.canvasElement.height);
              const imageData = canvasContext.getImageData(0, 0, self.canvasElement.width, self.canvasElement.height);

              const code = jsQR(imageData.data, imageData.width, imageData.height);
              if( code ){
                //console.log(preLog, code.data );
                self.scanStop();
                _protected.proceedScanResult(code.data);
              }
              else{ window.requestAnimationFrame(self.scan.bind(this)); }
            }else{ window.requestAnimationFrame(self.scan.bind(this)); }
          },

          scanStop: function(stream){
            let method="methods.scanStop"; let preLog=classname+"."+method; //console.log(preLog, "called");
            const self=this;
            if (!stream && self.videoElement && self.videoElement.srcObject && self.videoElement.srcObject ){ stream = self.videoElement.srcObject; }
            if( stream && stream.getTracks ){ stream.getTracks().forEach( function(track){ track.stop(); }); }
            self.scanActive = false;
          },

          /*
          captureImage : function(){
            let method="methods.captureImage"; let preLog=classname+"."+method; //console.log(preLog, "called");
            const self=this;
            $(self.fileInputElement).trigger("click");
          },

          handleFile : function(files){
            let method="methods.handleFile"; let preLog=classname+"."+method; //console.log(preLog, "called");
            const self=this;
            const file = files.item[0];
            var img = new Image();
          }
          */

        },

        mounted: async function(){
          let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
          const self=this; if( self && self._uid ){ _public.component=self; }

          APP.global.getOverlay().clear();
          APP.global.getOverlay().show();

          self.scanActive = false;

          self.cameraSelectProps.model = undefined;
          self.cameraSelectProps.lastModel = undefined;
          self.cameraSelectProps.bind.items = [];

          if( 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices &&  (await _protected.getMediaDevicesList("videoinput")).length>0 ){
            //getUserMedia({ video: { facingMode:'environment' } }) =>{ facingMode : <"user" || "environment"> }
            await navigator.mediaDevices.getUserMedia({ video: { facingMode:'environment' } }).then( async function(stream){
              self.scanStop(stream);
              let devices = await _protected.getMediaDevicesList("videoinput"); //console.log(devices);
              devices.forEach(function(device, index){ self.cameraSelectProps.bind.items.push({ text:device.label, value:device.deviceId }); });
            }).catch(function(err){
              alert("Kamera Zugriff wird benötigt.");
              //window.location.reload();
            });
            if(self.scanActiveAutostart){ self.scanStart(); }
          }else{
            alert("Keine Kamera gefunden.");
            APP.global.route.back();
          }

          if(typeof(self.$nextTick)==="function"){
            self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
              let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
              self.videoElement = document.getElementById("video");
              self.canvasElement = document.getElementById("canvas");
              APP.global.getOverlay().hide();
            });
          }
        },

    }

    return _public;
  })();
</script>
