<style>
   .AppOverlayElement {}
</style>

<template>
    <element v-bind:id="htmlId" v-bind:class="classname">
        <v-overlay v-if="overlay" v-model='overlay' z-index="10000">
            <v-alert v-if="overlayMsg" v-bind="overlayMsg.bind">
                <div v-if="overlayMsg.contentHtml" v-html="overlayMsg.contentHtml"></div>
                <div v-if="overlayMsg.component" v-bind:is="overlayMsg.component"></div>
            </v-alert>
            <v-progress-circular v-if="loader" color="accent" indeterminate size="64"></v-progress-circular>
        </v-overlay>

    </element>
</template>


<script type="text/javascript">
    /*
    *	@author		HM
    *	@date: 		2019-01-10
    *	@version	0.1.3
    *	@updated	2019-04-04
    *
    * @link       http://
    *
    *	@param		{Object} 	args 			Default argument object.
    *	@param		{type} 		args.key 		description
    *
    *	@return 	{Object} 	_t.public 			Instance of this class.
    */
    'use strict';
   export default (function(args){
    // Private
    //------------
    const classname = "AppOverlayElement";

    const APP = window.APP;
    const _h = APP.helper;

    // Public
    //------------
    const _public = {
        template: '#'+classname,
        props: [],

        data: function(){ return {
            classname: classname,
            htmlId: _h.generateGUID(),
            overlay: 0,
            loader: true,
            overlayMsg: undefined,
        }; },
        computed: {},
        watch: {},
        methods: {
            show: function(msgObjOrString){
                let method="show"; let preLog=classname+":"+method; //console.log(preLog, "called", msgObjOrString);

                let alertObj = undefined;
                this.loader = true;
                if ( msgObjOrString===false ){ this.loader=false; }
                if ( msgObjOrString && typeof(msgObjOrString)==="string" ){ msgObjOrString={ contentHtml:msgObjOrString } }
                if ( msgObjOrString && typeof(msgObjOrString)==="object" ){
                    alertObj=msgObjOrString;

                    if ( !alertObj.bind ){ alertObj.bind = {}; }
                    if ( !alertObj.bind.type && alertObj.type ){ alertObj.bind.type = alertObj.type; delete alertObj.type; }
                    if ( !alertObj.bind.color && alertObj.color ){ alertObj.bind.color = alertObj.color; delete alertObj.color; }
                    if ( !alertObj.bind.type && !alertObj.bind.color ){ alertObj.bind.type = "info"; }

                    if ( !alertObj.contentHtml && alertObj.msg ){ alertObj.contentHtml=alertObj.msg; delete alertObj.msg; }
                    if ( !alertObj.contentHtml && alertObj.text && typeof(alertObj.text)==="string" ){ alertObj.contentHtml=alertObj.text; delete alertObj.text; }
                    if ( !alertObj.contentHtml && alertObj.html && typeof(alertObj.html)==="string" ){ alertObj.contentHtml=alertObj.html; delete alertObj.html; }
                }

                if (alertObj){
                    this.overlayMsg=alertObj;
                    this.loader=false;
                }

                if (this.overlay<0){ this.overlay=0; }
                this.overlay++;
            },

            hide: function(msgObjOrString){
                let method="hide"; let preLog=classname+":"+method; //console.log(preLog, "called");
                if (this.overlay>0){ this.overlay--; }
                else{ this.overlay=0; }
                if ( !msgObjOrString ){ this.overlayMsg=undefined; }
            },

            clear: function(){
                let method="clear"; let preLog=classname+":"+method; //console.log(preLog, "called");
                this.overlay=0;
                this.overlayMsg=undefined;
            },
        },
        mounted : function(){
            let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
            if (!_public.component){ _public.component = this; }
        },
    }

    // MAIN
    //------------
    let method="MAIN"; let preLog=classname+":"+method; //console.log(preLog, "called");

    return _public;
})();
</script>
