import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import { de, en } from 'vuetify/es5/locale'
//const i18n = function(){ if (typeof(window.APP?.vue?.vm?.$t)==="function"){ return window.APP.vue.vm.$t(arguments); } }

export default new Vuetify({
  lang: {
    locales: { de, en },
    current: 'de',
    //t: function(key, ...params){ i18n(key, params); }
  },
  theme: {
    dark: false,
    themes: {
      light: { primary:"#1a1a1a", secondary:"#9d9d9d", accent:"#d10019", accent2:"#28566e", primarybg:'#5e5e5e', secondarybg:"#dadada", },
      dark: { primary:"#9d9d9d", secondary:"#1a1a1a", accent:"#d10019", accent2:"#8293bb" ,accent2b:"#22335b 28666e", primarybg:'#5e5e5e', secondarybg:"#dadada", },
    }
  }
})

/*
export default new Vuetify({
  _customVariables: ['~/scss/variables.scss'],
  _treeShake: true,
  lang: {
    locales: { de, en },
    current: 'de',
    //t: function(key, ...params){ i18n(key, params); }
  },
  theme: {
    _vuetify: {
      customVariables: ['~/scss/variables.scss'],
      treeShake: true
    },
    dark: false,
    themes: {
      _vuetify: {
        customVariables: ['~/scss/variables.scss'],
        treeShake: true
      },
      light: { primary: "#1a1a1a", secondary: "#9d9d9d", accent: "#d10019", primarybg: '#5e5e5e', secondarybg: "#dadada", },
      dark: { primary: "#9d9d9d", secondary: "#1a1a1a", accent: "#d10019", primarybg: '#5e5e5e', secondarybg: "#dadada", },
    }
  }
})
*/



