const classname="main";
let method="main"; let preLog=classname+":"+method; //console.log(preLog, "CALLED");
if ( !window.APP ){ window.APP={}; } const APP = window.APP;

import Vue from 'vue'
window.Vue = Vue;
import './templatesShared/index.js'
import './templates/index.js'
import VueI18n from 'vue-i18n'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'

import AppView from '@/AppView.vue'
APP.AppView = AppView;

Vue.config.productionTip = false

if ( !APP.vue ){ window.APP.vue = {}; }
APP.vue.store = store;
APP.vue.router = router;
// Create VueI18n instance with options
APP.vue.i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de',
  silentTranslationWarn: true,
  messages: APP.lang, // set locale messages
});

APP.vue.vm = function(){ return new Vue({
  router,
  store,
  vuetify,
  i18n: APP.vue.i18n,
  render: h => h(AppView)
}).$mount('#app'); }


APP.main = (async function(){
  'use strict';
  const $ = window.jQuery;
  const APP = window.APP;
  const _h = APP.helper;

  /*
  const getStore = function(){ if(APP.vue && APP.vue.store){ return APP.vue.store; } }
  const notify = function(args){
    if ( !args ){ return; }
    if( args && typeof(args)!=="object" ){ args={ text:args}; }
    if( !args.type ){ args.type="info"; }
    if( typeof((APP?.global?.getNotification||function(){})()?.add)==="function" ){ APP.global.getNotification().add(args); }
    else{ alert(args.type+": "+args.text||args.html); }
  }
  */

  // MAIN
  //------------
  let method="main"; let preLog=classname+":"+method; console.log( preLog, "called" );

  if ( typeof(APP.data)==="function" ){ APP.data = APP.data(); }
  if ( typeof(APP.api?.clientSaasRest)==="function" ){ APP.api.clientSaasRest = APP.api.clientSaasRest(); }
  //if ( typeof(APP.api?.clientSaasSocket)==="function" ){ APP.api.clientSaasSocket = APP.api.clientSaasSocket(); }
  //if ( typeof(APP.api?.clientSaasSocket.connect)==="function" ){ APP.api.clientSaasSocket.connect(); }

  if ( typeof(APP?.vue?.store?.loadFromStorage)==="function" ){ APP.vue.store.loadFromStorage(); }

  var pollingUpdate_loopLock = false;
  const pollingUpdate = function(){
    let method="pollingUpdate"; let preLog=classname+":"+method; //console.log(preLog, "called");
    pollingUpdate_loopLock = true;
    const recall = function(){ setTimeout(function(){ pollingUpdate_loopLock=false; pollingUpdate(); }, 10000 ); }
    if ( !APP?.state?.local_appStatus?.code && typeof(APP?.api?.clientSaasSocket?.isOnline)==="function" && APP?.api?.clientSaasSocket.isOnline() ){ recall(); return; }
    if ( APP.noPoll || typeof(APP?.api?.clientSaasRest?.isOnline)!=="function" || !APP.api?.clientSaasRest.isOnline() ){ recall(); return; }
    APP.api.send("App", "State", null, {
      //success: function(response){},
      error: function(err, eCode){ console.error(preLog, "eMsg="+err, "eCode="+eCode); },
      complete: function(){ recall(); }
    });
  }


  function initGlobal(callback){
    let method="initGlobal"; let preLog=classname+":"+method; console.log(preLog, "called");
    if ( typeof(APP.global)==="function" ){
      APP.global( {
        callback: function(){

          // Define: BasePage_baseline
          APP.initData.BaselineBasePage = {}

          APP.js.setAppStateParameter(APP.state);

          // Define: Dialog - layout
          APP.global.getDialog().addLayout({
            layoutName: "confirm",
            bind: { 'max-width': "900px" },
            title: "global.confirmationRequired",
            subtitle: "",
            body: "global.areYourSureRequest",
            cancel: { text: "global.cancel", bind: { color:"secondary" } },
            accept: { text: "global.accept", bind: { color:"accent" } },
            cardBind: { },
            //cancelOnClick: function(data, event, callback){ let method="cancelOnClick"; let preLog=classname+"."+method; console.log(preLog, "called"); if(typeof(callback)==="function"){ callback(); } }
            //acceptOnClick: function(data, event, callback){ let method="acceptOnClick"; let preLog=classname+"."+method; console.log(preLog, "called"); if(typeof(callback)==="function"){ callback(); } }
          });

          // Defaul LocalAppState
          APP.initData.local_appstate = {}

          $("#app-info").remove();
          if (typeof(callback)==="function"){ callback(); }
        }
      });
    }
  }

  window.onload = function(){
    $("body").append('<div id="app-info"class="absolute-middel-center" style="display: inline-table;"> <div class="css-loader" style="margin: 20px auto;"></div> <p></p> </div>');

    initGlobal();
    if ( APP.state?.tmp ){ delete APP.state.tmp; }

    function updateAppState(){
      if ( APP.state?.local?.auth_registerdevice ){
        APP.api.send("Auth","Info", null, {
          success: function(res){
            if ( !res?.success ){
              if ( typeof(APP.api.clientSaasSocket?.disconnect)==="function" ){ APP.api.clientSaasSocket?.disconnect(); }
              APP.api.send("Auth","Login", APP.state.local.auth_registerdevice, {
                success: function(res){
                  if ( res.success ){
                    if ( typeof(APP.api?.clientSaasSocket)==="function" ){ APP.api.clientSaasSocket = APP.api.clientSaasSocket(); }
                    //if ( typeof(APP.api.clientSaasSocket?.disconnect)==="function" ){ APP.api.clientSaasSocket?.disconnect(); }
                    if ( typeof(APP.api.clientSaasSocket?.connect)==="function" ){ APP.api.clientSaasSocket.connect(); }
                    APP.api.send("App","State", null, {
                      success: function(res){},
                      error: function(err){ },
                      //complete: function(){ if ( typeof(APP.js.sendLocalData)==="function" ){ APP.js.sendLocalData(); } },
                    }, "rest");
                  }else{
                    console.error(preLog, "invalid cookie", res );
                    APP.global.route.change(APP.config.startpage);
                  }
                },
                error: function(err,errorCode){
                  let eMsg = err; //i18n("error.failedDevicelogin")
                  console.error(preLog, "eMsg="+eMsg, "error=",err, "errorCode:",errorCode);
                  //APP.global.route.change(APP.config.startpage);
                },
                complete: function(){},
              }, "rest");
            }else{
              if ( typeof(APP.api?.clientSaasSocket)==="function" ){ APP.api.clientSaasSocket = APP.api.clientSaasSocket(); }
              //if ( typeof(APP.api.clientSaasSocket?.disconnect)==="function" ){ APP.api.clientSaasSocket?.disconnect(); }
              if ( typeof(APP.api.clientSaasSocket?.connect)==="function" ){ APP.api.clientSaasSocket.connect(); }
              APP.api.send("App","State", null, {
                success: function(res){},
                error: function(err){ console.error(preLog, err); },
                //complete: function(){ if ( typeof(APP.js.sendLocalData)==="function" ){ APP.js.sendLocalData(); } },
              }, "rest");
            }
          },
          error: function(err){ 
            console.error(preLog, err); 
            //APP.global.route.change(APP.config.startpage);
          },
          complete: function(){}
        });
      }else{ APP.global.route.change(APP.config.startpage); }
    }
    updateAppState();

    document.addEventListener('visibilitychange', function(){
      //console.log(preLog, document.visibilityState);
      if ( document.visibilityState == "visible" ){
        console.log(preLog, "visibilitychange", "updateAppState");
        if ( APP.vue?.router?.currentRoute?.name != "DeviceRegisterView" ){
          updateAppState(); 
          if( APP?.js?.sendLocalData ){ APP.js.sendLocalData(); }
        }
      }
    });

    /*
    window.onfocus = function(){
      if ( window.moment().diff(window.moment(APP.state?.general?.lastUpdateDateUtc), 'Seconds') > 60*5 ){
        console.log(preLog, "focus", "updateAppState");
        updateAppState(); 
      }
    }
    */

  };

})();
