<style>
  .UnkownDeviceStateView {  }
</style>

<template>
  <element :class="classname">
    <v-sheet class="view">

       <v-row align="center" justify="center" class="h100">
        <v-col sm=12 md=10 class="">
          <v-card light class="px-2 w100">

            <h1 class="title">Unbekannter Gerätezustand</h1>
            <v-row align="center" justify="center">
              <v-col cols="11" class="">Das Gerät befindet sich in einem Ubekannten Zustand.</v-col>
              <v-col cols="11" class="">Bitte versuchen Sie es später nocheinmal oder setzen Sie das Gerät zurück. Für weitere Informationen setzen sie sich bitte mit dem Support in Verbindung.</v-col>
              <v-col cols="11" class="text-center"> <v-btn class="primary" @click="refreshDeviceState"> Gerätestatus aktualisieren <v-icon class="ml-2">mdi-reload</v-icon> </v-btn> </v-col>
              <v-col cols="11" class="text-center"> <v-btn class="accent" @click="deviceReset"> Gerät zurücksetzen <v-icon class="ml-2">mdi-cellphone-erase</v-icon> </v-btn> </v-col>
            </v-row>

          </v-card>
        </v-col>
      </v-row>

    </v-sheet>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-11-13
  *	@version	1.1.1
  *	@updated	2020-11-13
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "UnkownDeviceStateView";

    const APP = window.APP;
    const _h = APP.helper;

    //const i18n = APP.vue.i18n.getI18nMessage;
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      props: [],

      data: function(){ return {
          classname: classname,
      }; },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },
      },

      watch: {},

      methods: {
        refreshDeviceState: function(){
          let method="refreshDeviceState"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self=this;
          APP.global.getOverlay().show();
          setTimeout(function(){ window.location.href="/"; }, 2000);
        },

        deviceReset: function(){
          let method="deviceReset"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self=this;
          APP.global.getDialog().show({
            layout: "confirm",
            title: "Gerät wirklich zurücksetzen?",
            body: "Wollen Sie das Gerät wirklich zurücksetzen, alle Daten werden bei diesem Vorgang gelöscht. Die Aktion kann nicht Rückgängig gemacht werden.",
            acceptOnClick: function(data, event, callback){
              let method="acceptOnClick"; let preLog=classname+"."+method; console.log(preLog, "called");
              APP.global.getOverlay().show();
              setTimeout(function(){
                APP.data.clear(true);
                self.refreshDeviceState();
                if(typeof(callback)==="function"){ callback(); }
              }, 1000);
            }
          });
        }
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self=this; if( self && self._uid ){ _public.component=self; }
        APP.global.getOverlay().clear();
        APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this;

        APP.global.basepage.change("BlankBasePage");

        if( self && typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
            APP.global.getOverlay().hide();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    return _public;
  })();
</script>
