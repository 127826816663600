<style>
  .BaselineBasePage header.v-sheet { z-index: 10; }
  .BaselineBasePage nav.v-navigation-drawer { z-index: 3; }
  .BaselineBasePage .container { height: 100%; }
  .BaselineBasePage footer.v-footer { z-index: 5; }
  .BaselineBasePage .v-main { background-color: #dadada; }
  .BaselineBasePage footer a.v-btn--active { background-color: #d10019 !important; color:white !important; }
</style>


<template>
  <element :class="classname">

    <header v-if="header" id="basepage-header" style="height: 42px;">
      <Statusbar></Statusbar>
    </header>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <v-container class="pa-0 mb-15">
        <!-- component matched by the route will render here -->
        <transition name="fade" mode="out-in">
          <v-row align="stretch" justify="center" dense no-gutters>
            <v-col cols=12 sm=11 md=10 lg=9 xl=8>
              <router-view></router-view>
            </v-col>
          </v-row>
        </transition>
      </v-container>

      <v-footer v-if="hasFooter" v-bind="footer_element.bind">
        <v-bottom-navigation grow dark background-color="primarybg" color="secondarybg" v-model="menuBottom_buttons_model">
          <v-btn v-for="(item, index) in menuBottom_buttons" :key="'btn-'+index" v-bind="item.bind" v-on="getOn(item.on)">
            <!--<span>{{item.text}}</span>-->
            <v-badge :value="item.counter||0" :content="item.counter||0" :color="item.counterColor||'primary'" overlap>
              <v-icon>{{item.icon}}</v-icon>
            </v-badge>
          </v-btn>
        </v-bottom-navigation>
      </v-footer>

    </v-main>



  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2019-02-10
  *	@version	0.1.0
  *	@updated	2019-02-10
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "BaselineBasePage";

    const APP = window.APP;
    const _h = APP.helper;

    const eventListener = {
      //windowResize: function(){ scaleBasePage(); },
      routerAfterEach : function(to, from){
        let method="routerAfterEach"; let preLog=classname+":eventListener."+method; console.log(preLog, "called", to, from);
        const self = _public.computed;
        self.footer_navigator_model = to?.name;
      },
    }

    // Public
    //------------
    const _public = {
      classname: classname,
      template: '#'+classname,

      components: {
        //Statusbar: APP.Templates.StatusbarConnectionElement,
        Statusbar: APP.Templates.StatusbarConnectionSnipped,
      },

      props: [ "args", "initData" ],

      data: function(){ return {
          classname: classname,

          title: undefined,

          header: true,

          hasBackBtn: false,
          hasHomeBtn: false,
          hasLogoutBtn: undefined,

          hasDrawer: true,
          drawer_model: true,
          drawer_mini: true,
          drawer_permanent: true,
          drawer_element: undefined,
          /*
          drawer_element: {
              icon: "mdi-view-grid-outline",
              title: "Alle Services",
              categories: [
                  {
                      //title: "",
                      items: [
                          { text:"Mein Profill", icon:"mdi-account-outline", to:"ProfileView", on:{}, counter:1, counterColor:"" },
                          { text:"Abmelden", icon:"mdi-logout", on:{ click:function(){_public.methods.logout();} }, counter:0, counterColor:"" },
                          { text:"FAQ", icon:"mdi-help", to:"", on:{}, counter:0, counterColor:"" },
                          { text:"Datenschutz", icon:"mdi-shield-account-outline", to:"", on:{}, counter:0, counterColor:"" },
                      ]
                  },
                  {
                      title: "Admin",
                      items: [
                          { icon:"mdi-account-supervisor-outline", title:"Mitarbeiter", to:"", on:{}, counter:0, counterColor:"" },
                          { icon:"mdi-check-circle-outline", title:"Checkliste", to:"", on:{}, counter:4, counterColor:"red" },
                      ]
                  },
              ]
          }
          */

          hasFooter: false,
          footer_element: {
              bind: {
                  //absolute: "absolute",
                  fixed:"fixed",
                  padless:"padless",
                  class: ""
              }
          },
          menuBottom_buttons_model: undefined,
          menuBottom_buttons: [
            { text:"Buchen", icon:"mdi-home-account", bind:{ to:"BookingView", value:"BookingView" }, counter:0, counterColor:"" },
            { text:"Buchungen", icon:"mdi-playlist-edit", bind:{ to:"BookingStatsView", value:"BookingStatsView" }, counter:0, counterColor:"" },
            { text:"Salden", icon:"mdi-format-list-bulleted-square", bind:{ to:"SaldenOverviewView", value:"SaldenOverviewView" }, counter:0, counterColor:"" },
            { text:"Info", icon:"mdi-information-variant", bind:{ to:"AboutView", value:"AboutView" }, counter:0, counterColor:"" },
          ],
          /*
          menuBottom_buttons: [
              { text:"Terminal", icon:"mdi-home-account", bind:{ to:"booking", value:"booking" }, counter:1, counterColor:"" },
              { text:"Buchungen", icon:"mdi-playlist-edit", bind:{ to:"bookingstats", value:"bookingstats" }, counter:0, counterColor:"" },
              { text:"Salden", icon:"mdi-format-list-bulleted-square", bind:{ to:"saldenoverview", value:"saldenoverview" }, counter:0, counterColor:"" },
              { text:"Info", icon:"mdi-information-variant", bind:{ to:"about", value:"about" }, counter:0, counterColor:"" },
          ]
          */

      } },

      computed: {
        getCurrentBreakpoint: function(){
          let method="getCurrentBreakpoint"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let breakpoint = self.$vuetify.breakpoint.name; //console.log(preLog, "breakpoint:",breakpoint);
          switch (breakpoint) {
            case 'xs': breakpoint=220; break;
            case 'sm': breakpoint=400; break;
            case 'md': breakpoint=500; break;
            case 'lg': breakpoint=600; break;
            case 'xl': breakpoint=800; break;
          }
          //console.log(preLog, "breakpoint:",breakpoint);
          return breakpoint;
        }

      },

      watch: {
        args: function(newVal, oldVal){
          let method="args"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
          const self = this;
          self.setVm(self.args);
        },

        getCurrentBreakpoint: function(newVal, oldVal){
          let method="getCurrentBreakpoint"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
          const self = this;
          self.setupMenu();
        },
      },

      methods: {
        setVm: function(_args){
            let method="setVm"; let preLog=classname+"."+method; //console.log(preLog, "called", "args:",JSON.stringify(_args));
            const self = this;

            let defaulArgsSetupName = _args?.layout||"default";
            let defaultArgs = (self?.initData||{})[defaulArgsSetupName];
            let args = Object.assign({}, defaultArgs, _args);
            //console.log(preLog, "args:",JSON.stringify(args));

            if (args && typeof(args)==="object"){
                if ( typeof(args.title)==="string" ){ self.title=args.title; }
                if ( (args.header && typeof(args.header)==="object") || args.header===false ){ self.header=args.header; }
                if ( (args.footer && typeof(args.footer)==="object") || args.footer===false ){ self.footer=args.footer; }

                if ( typeof(args.hasBackBtn)==="boolean" ){ self.hasBackBtn=args.hasBackBtn; }
                if ( typeof(args.hasHomeBtn)==="boolean" ){ self.hasHomeBtn=args.hasHomeBtn; }
                if ( typeof(args.hasLogoutBtn)==="boolean" ){ self.hasLogoutBtn=args.hasLogoutBtn; }

                if ( args.drawer_element && typeof(args.drawer_element)==="object" ){ self.drawer_element=args.drawer_element; }
                if ( args.menuBottom_buttons && typeof(args.menuBottom_buttons)==="object" ){ self.menuBottom_buttons=args.menuBottom_buttons; }
            }
        },

        setupMenu: function(){
            let method="currentBreakpoint"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            const breakpoint = self.getCurrentBreakpoint;
            if ( breakpoint <= 400 || breakpoint ){
              //self.hasDrawer = false;
              self.drawer_mini = false;
              self.drawer_permanent = false;
              self.hasFooter = true;
            }else{
              //self.hasDrawer = true;
              self.drawer_mini = true;
              self.drawer_permanent = true;
              self.hasFooter = false;
            }
          },

        getOn: function(on){
            let method="getOn"; let preLog=classname+".methods."+method; //console.log(preLog, "called", "on:",on);
            const self = this;
            for (let key in on){
                let item = on[key]; //console.log(preLog, typeof(item), key, item);
                if ( typeof(item)==="string" ){ on[key]=new Function(item); }
            }
            return on;
        },

        goBack: async function(){
            let method="goBack"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
            const self = this;
            APP.global.getOverlay().show();
            await APP.global.route.back();
            //APP.global.getOverlay().hide();
        },
        goHome: async function(){
            let method="goBack"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
            const self = this;
            APP.global.getOverlay().show();
            await APP.global.route.change("HomeView");
            //APP.global.getOverlay().hide();
        },

        logout: function(){
            let method="logout"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
            const self = this;
            APP.global.getDialog().show({
            layoutName:"confirm",
            body:"Möchten Sie sich wirklich ausloggen?",
            acceptOnClick: function(dialog, event, callback){
                    APP.global.route.change("LogoutView");
                    if (typeof(callback)==="function"){ callback(); }
                }
            });
        },

        uiAdminSettings_clicked: function(){
            let method="uiAdminSettings_clicked"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
            const self = this;

            if ( !this.uiAdminInfo ){ this.uiAdminInfo={ timeoutPid:undefined, counter:0 }; }
            let uiAdminInfo = this.uiAdminInfo;
            function resetUImode(){
                clearTimeout( uiAdminInfo.timeoutPid );
                uiAdminInfo.timeoutPid=undefined;
                uiAdminInfo.counter=0;
            }
            uiAdminInfo.counter = (uiAdminInfo.counter||0)+1;
            if ( !uiAdminInfo.timeoutPid ){ uiAdminInfo.timeoutPid=setTimeout(function(){ resetUImode(); }, 5000) }
            if ( uiAdminInfo.counter>=9 ){
                resetUImode();
                APP.global.route.change("UiAdminView");
            }
        },
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this; if( this && this._uid ){ _public.component=this; }
        //APP.global.getOverlay().clear();
        //APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: function(){
          let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
          const self = this; _public.component = self;

          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
              let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "called");
              self.setVm( self.args );
              self.setupMenu();
          });
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; //console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeRouteEnter : function(to, from, next){ let method="beforeRouteEnter"; let preLog=classname+"."+method; console.log(preLog, "called", to, from); },
      //beforeRouteUpdate: function(to, from, next){ let method="beforeRouteUpdate"; let preLog=classname+"."+method; console.log(preLog, "called", to, from); },
      //beforeRouteLeave : function(to, from, next){ let method="beforeRouteLeave"; let preLog=classname+"."+method; console.log(preLog, "called", to, from); },
    }

    return _public;
})();
</script>
