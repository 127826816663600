<style>
    .StatusbarConnectionSnipped .v-system-bar { 
      height: 42px !important; 
      z-index: 10;
    }
</style>

<template>
  <element :class="classname">
    <v-system-bar lights-out fixed dark color="primarybg">
      <span>{{time}}</span>
      <v-spacer></v-spacer>
      <span class="font-weight-bold subtitle-2">
        <span v-if="title" class="secondary--text">{{title}}</span>
        <span v-else>
          <span class="accent--text">Time</span>
          <span class="secondary--text">Design</span>
        </span>
      </span>
      <v-spacer></v-spacer>

      <span style="min-width: 100px" :class="statusColor+'--text'+' text-center'">
        <span class="" v-html="statusText"></span>
        <v-icon class="ml-2 mb-1" :color="statusColor">{{statusIcon}}</v-icon>
      </span>

    </v-system-bar>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "StatusbarConnectionSnipped";

    const APP = window.APP;
    const _h = APP.helper;

    const moment = window.moment;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    //const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:{ demo:"Hello World" }, }
      },

      data: function(){ return {
        classname: classname,

        timeoutPid: undefined,

        time: undefined,
        title: "",

        statusColor: "muted",
        statusText: "unbekannt",
        statusIcon: "mdi-help", // mdi-access-point-network, mdi-access-point-network-off, mdi-lan-connect, mdi-lan-disconnect, mdi-lan-pending
      } },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },
      },

      watch: {
        appState: {
          immediate: true,
          deep: true,
          handler: function(){
            let method="appState"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            const appStatus = self.appState?.tmp?.appStatus||{};
            // "mdi-help", mdi-access-point-network, mdi-access-point-network-off, mdi-lan-connect, mdi-lan-disconnect, mdi-lan-pending

            self.statusColor = "muted";
            self.statusText = appStatus.code||"unkown";
            self.statusIcon = "mdi-help";

            if ( !appStatus?.code ){
              self.statusColor = "info";
              self.statusText = i18n(classname+".waitForUpdate");
              self.statusIcon = "mdi-lan-pending";
              return;
            }

            if ( appStatus.code==0 || appStatus.code==200 ){
              self.statusColor = "success";
              self.statusText = i18n(classname+".connected");
              self.statusIcon = "mdi-access-point-network";
              return;
            }

            if ( appStatus?.code==901 ){
              self.statusColor = "lime";
              self.statusText = i18n(classname+".connected");
              self.statusIcon = "mdi-access-point-network";
              return;
            }

            if ( appStatus?.code=="error" || appStatus?.code==2 || appStatus?.code==900 ){
              self.statusColor = "error";
              self.statusText = i18n(classname+".offline");
              self.statusIcon = "mdi-access-point-network-off";
            }

            if ( self.statusText==="" ){ return; }

            let lastModelUpdateTimestamp = self.appState.general?.lastUpdateDateUtc;
            if (lastModelUpdateTimestamp){

              self.statusIcon = "mdi-access-point-network-off";

              let ts = moment(lastModelUpdateTimestamp);
              let printTs = "";
              if ( ts.isSame(moment(), 'day') ){
                // last update on the same date
                printTs = "heute "+ts.format("HH:mm");
              }else{
                // last update before today
                printTs = ts.format("DD:MM:YYYY HH:mm");
              }
              if ( ts.diff(Date.now(), 'hours')<0 ){
                // last update older than 1hour
                self.statusColor = "error";
              }
              else {
                // last update in the 1hour
                self.statusColor = "warning";
              }
              self.statusText = "zuletzt aktualisiert:<br />"+printTs;
            }

          },
        }
      },

      methods: {
        updateTimestamp: function(){
          let method="updateTimestamp"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          self.time = moment().format("DD.MM.YY HH:mm");
          self.timeoutPid = setTimeout(self.updateTimestamp, 1000 );
        },
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this; _public.component = self;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
            self.updateTimestamp();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      beforeDestroy: async function(){
        let method="beforeDestroy"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
        clearTimeout(self.timeoutPid);
        self.timeoutPid=undefined;
        if (typeof(self.onReadyStateChangedRemoveCallbackSocket)==="function"){ self.onReadyStateChangedRemoveCallbackSocket(); }
      },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
