<style>
  .LoginView {  }
</style>

<template>
  <element v-bind:class="classname">

  </element>
</template>


<script type="text/javascript">
/*
*	@author		HM
*	@date: 		2020-05-08
*	@version	0.0.1
*	@updated	2020-05-08
*
* @link       http://
*
*	@param		{Object} 	args 			Default argument object.
*	@param		{type} 		args.key 		description
*
*	@return 	{Object} 	_t.public 			Instance of this class.
*/
'use strict';
export default (function(args){
  // Private
  //------------
  const classname = "LogoutView";

  const APP = window.APP;
  const _h = APP.helper;
  //const i18n = APP.vue.i18n.getI18nMessage;
  const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

  // Public
  //------------
  const _public = {
    template: '#'+classname,

    props: [],
    data: function(){ return {
      classname: classname,
    }; },

    computed: {
      appState: function(){
        let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
        const self = this;
        let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
        return appState;
      },
    },

    watch: {},

    methods: {},

    //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //created: async function(){ let method="created"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    mounted: async function(){
      let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
      const self=this; if( self._uid ){ _public.component=self; }

      APP.global.basepage.change("BlankBasePage");

      APP.global.getOverlay().clear();
      APP.global.getOverlay().show();

      delete self.appState.local.userActive;
      self.$store.update();

      if(typeof(self.$nextTick)==="function"){
        self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
          let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");

          if ( self.appState.local.userActive ){
            notify({type:"error", text:"Logout not successful."});
          }
          APP.global.route.change(APP.config.startpage);

          //APP.global.getOverlay().hide();
        });
      }
    },

    //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },


    //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
  }
  return _public;
})();
</script>
