<style>
  .AboutView {  }
</style>

<template>
  <element :class="classname">
    <v-sheet class="view">

      <v-row dense no-gutter align="start" justify="center" class="h100">
        <v-col sm=12 md=10>
          <v-card class="pa-2">

            <h1 class="title">Systeminformationen</h1>

            <v-row v-for="(item, index) in infosComp" :key="'infosComp_'+index">
              <v-col class="subtitle-1 text-right">{{item.name}} :</v-col>
              <v-col class="subtitle-2 grey--text mt-1">{{item.value}} </v-col>
            </v-row>

            <v-row v-for="(item, index) in uiInfo" :key="'uiInfo_'+index" @click="uiInfo_clicked(item)">
              <v-col class="subtitle-1 text-right">{{item.name}} :</v-col>
              <v-col class="subtitle-2 grey--text mt-1">{{item.value}} </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols=6 class="text-center"> <v-btn color="success" @click="uiReload"> UI-Reload <v-icon class="ml-2">mdi-reload</v-icon> </v-btn> </v-col>
              <v-col cols=6 class="text-center"> <v-btn color="info" :disabled="sendDebugInfoBtnDisabled" :loading="sendDebugInfoBtnDisabled" @click="sendDebugInfo"> <span>Send Debug</span> <v-icon class="ml-2">mdi-bug</v-icon> </v-btn> </v-col>
              <v-col v-if="isLocalServiceConnected" cols=6 class="text-center"> <v-btn color="primary" to="PasswordChangeView"> <span>Password &auml;ndern</span> <v-icon class="ml-2">mdi-account-key</v-icon> </v-btn> </v-col>
              <v-col v-else cols=6 class="text-center"> <v-btn color="muted" @click="passwordChangeBtnClicked"> <span>Password &auml;ndern</span> <v-icon class="ml-2">mdi-account-key</v-icon> </v-btn> </v-col>
              <v-col cols=12 class="text-center"> <v-btn color="accent" @click="btnLogoutClicked"> <span>Logout</span> <v-icon>mdi-exit-to-app</v-icon> </v-btn> </v-col>
            </v-row>

          </v-card>
        </v-col>
      </v-row>

    </v-sheet>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-11-13
  *	@version	1.1.1
  *	@updated	2020-11-13
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "AboutView";

    const APP = window.APP;
    const _h = APP.helper;

    //const i18n = APP.vue.i18n.getI18nMessage;
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      props: [],

      data: function(){ return {
          classname: classname,

          sendDebugInfoBtnDisabled: false,

          uiInfo : [
            { id:"0", name:"Ui-Version", value: APP.config.buildVersion },
          ],
      }; },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self=this; //const mutation=self.$store.getters.mutation;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },

        isLocalServiceConnected: function(){
          let method="isLocalServiceConnected"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this; //const mutation=self.$store.getters.mutation;
          return ( self.appState.general?.isLocalServiceConnected && APP.helper.isOnline() );
        },

        infosComp: function(){
          let method="computed.infosComp"; let preLog=classname+"."+method; //console.log(preLog, "called");
          const self = this;
          const appState = self.appState;
          let result = [];
          if ( appState.device ){
            let device = appState.device;
            result.push( { name:"Geräte Id", value:device.id||appState.deviceId  } )
            result.push( { name:"Geräte Name", value:device.name } )
          }

          if ( appState.general ){
            let general = appState.general;
            result.push( { name:"Server Version", value:general.serverVersion } )
            result.push( { name:"Erforderliche App Version", value:general.minRequiredAppVersion } )
          }

          if ( appState.local.userActive ){
            let user = appState.local.userActive;
            result.push( { name:"Personalnummer", value:user.id } )
            result.push( { name:"Kartennummer", value:user.cardId } )
            result.push( { name:"Zeitzone", value:user.timeZone } )
            result.push( { name:"Offset", value:user.baseUtcOffset } )
          }

          return result;
        }
      },

      watch: {},

      methods: {
        uiInfo_clicked: function(item){
        let method="methods.uiInfo_clicked"; let preLog=classname+"."+method; //console.log(preLog, "called");
        function resetUImode(){
          clearTimeout(item.timeoutPid);
          item.timeoutPid=undefined;
          item.counter=0;
        }
        if ( item.id && item.id.toString()==="0" ){
          item.counter = (item.counter||0)+1;
          if ( !item.timeoutPid ){ item.timeoutPid=setTimeout(function(){ resetUImode(); }, 5000) }
          if ( item.counter>=9 ){
            resetUImode();
            APP.global.route.change("UiAdminView");
          }
        }
      },

      uiReload: function(){
        let method="methods.uiReload"; let preLog=classname+"."+method; //console.log(preLog, "called");
        window.location.reload(true);
      },

      sendDebugInfo: function(){
        let method="methods.sendDebugInfo"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self = this;
        let payload = JSON.parse(JSON.stringify(this.appState));
        //if ( payload?.local?.auth_registerdevice?.deviceKey ) { payload.local.auth_registerdevice.deviceKey = "----"; }
        payload = JSON.stringify({
          bookings: payload.local.bookings,
          bookingsLog: payload.local.bookingsLog,
        });
        self.sendDebugInfoBtnDisabled=true;
        APP.api.send("App","Log", {message: payload }, {
          success: function(response){
            if (!response?.success){
              console.error(preLog, response);
              notify({ type:"error", text:"Debug-Informationen konnten nicht versendet werden."});
              self.sendDebugInfoBtnDisabled=false;
              return;
            }
            console.log(preLog, response);
            setTimeout(function(){
              notify({ type:"success", text:"Debug-Informationen wurden erfolgreich versendet."})
              self.sendDebugInfoBtnDisabled=false; 
            }, 3000);
          },
          error: function(error, code){ 
            console.error(preLog, error, code);
            notify({ type:"error", text:(error||"Senden nicht möglich, server error.")+" ("+code+")" });
            self.sendDebugInfoBtnDisabled=false;
          },
        }, "rest");
      },

      passwordChangeBtnClicked: function(){
        let method="passwordChangeBtnClicked"; let preLog=classname+".methods."+method; console.log(preLog, "called");
        notify({ type:"warning", text:"Dieser Service steht derzeit nicht zur Verfügung, bitte versuchen Sie es später noch einmal."})
      },

      btnLogoutClicked: function(){
        let method="methods.btnLogoutClicked"; let preLog=classname+"."+method; console.log(preLog, "called");
        APP.global.getDialog().show({
          layoutName: "confirm",
          title: "Logout",
          body: "Möchten Sie sich wirklich ausloggen?",
          acceptOnClick: function(data,e,callback){
            console.log(preLog, "Logout clicked");
            APP.global.route.change("LogoutView");
            if(typeof(callback)==="function"){ callback(); }
          }
        });
      }
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self=this; if( self && self._uid ){ _public.component=self; }
        APP.global.getOverlay().clear();
        APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this;

        APP.global.basepage.change("BaselineBasePage");

        if( self && typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
            APP.global.getOverlay().hide();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    return _public;
  })();
</script>
