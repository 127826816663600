<style>
    .NotificationAlertElement > div { position: fixed; z-index: 10002; bottom: 0px; right:1px; width:100% }
    .NotificationAlertElement .row { background: rgba(0, 0, 0, 0.25); }
</style>

<template>
    <element :class="classname">
      <div>
        <v-row dense _no-gutters align="center" justify="center" v-for="(notification, index) in notifications" v-bind:key="index" v-show="notification.value">
          <v-col cols=12 sm=10 md=8 lg=6 xl=4>
            <v-alert v-model="notification.value" v-bind="notification.bind" class="ma-0 w100" @input="closeNotification(notification)">
              {{notification.text}}
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </element>
</template>


<script type="text/javascript">
    /*
    *	@author		HM
    *	@date: 		2019-01-10
    *	@version	0.1.2
    *	@updated	2019-01-28
    *
    * @link       http://
    *
    *	@param		{Object} 	args 			Default argument object.
    *	@param		{type} 		args.key 		description
    *
    *	@return 	{Object} 	_t.public 			Instance of this class.
    */
    'use strict';
    export default (function(args){
        // Private
        //------------
        const classname = "NotificationAlertElement";

        const APP = window.APP;
        const _h = APP.helper;

        const defaultDisplayTime = 5000;

        // Public
        //------------
        const _public = {
          props: [],
          data: function(){ return {
            classname: classname,
            notifications : [
                //{ timeout:0, type:"success", color="success" text: "hello1" },
            ],
          } },

          computed: {},

          watch: {},

          methods: {
            add: function(args){
              let method="add"; let preLog=classname+".methods."+method; //console.log(preLog, args);
              const self=this;
              if ( !args ){ return; }
              let data = {
                text: undefined,
                value: true,
                timeout: defaultDisplayTime,
                bind: {
                  type: "info",
                  width: "100%",
                  dismissible: true,
                }
              }
              if ( typeof(args)!=="object" ){ data.text = args; }
              else{
                if ( args.bind ){ Object.merge(data, args); }
                if ( args.text ){ data.text=args.text; }
                if ( args.timeout ){ data.timeout=args.timeout; }

                if ( args.color ){ data.bind.color=args.color; }
                if ( args.type ){ data.bind.type=args.type; }
              }
              data.setTimeout = setTimeout(function(){ self.closeNotification(data); }, data.timeout);
              self.notifications.push(data);
            },

            closeNotification: function(data){
              let method="closeNotification"; let preLog=classname+":"+method; //console.log(preLog, "called", data);
              const self=this;
              data.value=false;
              clearTimeout(data.setTimeout);
              let activeNotification = self.notifications.find(function(item,index){ return item.value; });
              if (!activeNotification){ self.notifications=[]; }
            }
          },

          mounted : function(){
            let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
            const self=this; _public.component=self;
          },
        }

    window.xxx = _public
    return _public;
  })();
</script>
