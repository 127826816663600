import Vue from 'vue'
import Vuex from 'vuex'

const APP = window.APP;
const getStore = function(){ return APP.vue.store; };

Vue.use(Vuex)

const classname="store"; let method="main"; let preLog=classname+":"+method;

const vuex = new Vuex.Store({
  //modules: {},
  state: {
    app_state: {},
    mutation: 0,
  },
  mutations: {
    app_state: function(state, payload){
      payload = JSON.parse(JSON.stringify(payload)); //console.trace(preLog, "payload:",payload);
      state.app_state = payload;
      state.mutation = state.mutation++;
      getStore().save(state.app_state);
      //APP.state = getStore().getters["app_state"];
      APP.state = state.app_state;
    }
  }, // mutations -> sycronous
  actions: {}, // actions -> asycronuus & sycronous
  getters: {
    app_state: function(state){ return state.app_state; },
    mutation: function(state){ return state.mutation; },
  },
});


vuex.loadFromStorage = async function(){
  let method="loadFromStorage"; let preLog=classname+"."+method; //console.trace(preLog, "called");
  let _path="App"; let _key="State"; //console.log(preLog, _path, _key, data);
  let _pathKey = _path+"_"+_key; _pathKey=_pathKey.toLowerCase();
  if (typeof(APP.storage?.get)==="function" ){
    let result = (await APP.storage.get(_path, _key))||{};
    if ( result && typeof(result)==="object" ){ getStore().commit(_pathKey, result); }
  }
  //APP.state=getStore().getters["app_state"];
}

vuex.update = async function(data, _path, _key){
  let method="update"; let preLog=classname+"."+method; //console.log(preLog, "called", data);
  if ( !_path ){ _path="App"; }
  if ( !_key ){ _key="State"; }
  let _pathKey = _path+"_"+_key; _pathKey=_pathKey.toLowerCase();
  //console.log(preLog, _path, _key, data);
  if ( !data ){ data = APP.state||vuex.getters[_pathKey]; }
  if ( data && typeof(data)==="object" && (data.errorMsg||data.errorCode||data.errorDetails) ){ return; }
  getStore().commit(_pathKey, data);
  //vuex.save(data, _path, _key);
}

vuex.save = async function(data, _path, _key){
  let method="save"; let preLog=classname+"."+method; //console.log(preLog, "called", data);
  if ( !_path ){ _path="App"; }
  if ( !_key ){ _key="State"; }
  let _pathKey = _path+"_"+_key; _pathKey=_pathKey.toLowerCase();
  //console.log(preLog, _path, _key, data);
  if ( !data ){ data=vuex.getters[_pathKey]; } //console.log(preLog, _path, _key, data);
  if ( data && typeof(data)==="object" && (data.errorMsg||data.errorCode||data.errorDetails) ){ return; }
  if (typeof(APP.storage?.set)==="function" ){ return await APP.storage.set(_path, _key, data); }
}

const dataSubscriptionList = [];
const appStateUpdate = function(payload, _path, _key){
  let method = "appStateUpdate"; let preLog=classname+"."+method; //console.log(preLog, _path, _key, JSON.parse(JSON.stringify(payload)) );
  let appState = getStore().getters["app_state"]||{};
  let newAppState = appState;
  if (Array.isArray(payload)){
    payload.forEach(function(payloadItem, payloadIndex){
      let pathList = payloadItem.path.split(".");
      let currentNewAppState = newAppState;
      pathList.forEach(function(pathItem,pathIndex){
        //console.log(preLog, pathItem, pathIndex,"<", pathList.length-1)
        if ( !([pathItem] in currentNewAppState) ){ currentNewAppState[pathItem] = {} }
        if ( pathIndex<pathList.length-1 ){ currentNewAppState = currentNewAppState[pathItem]; }
      });
      currentNewAppState[pathList.pop()] = payloadItem.value;
    });
  }else{ newAppState = Object.assign({}, appState, payload||{}); }
  getStore().commit("app_state", newAppState);
  if (APP && typeof(APP)==="object" ){ APP.state=getStore().getters["app_state"]; }
  //vuex.save(newAppState);
}
dataSubscriptionList.push( APP.data.subscribe("*", "*", async function(payload, _path, _key, totalPayload){
  let method = "*_*"; let preLog=classname+"."+method; //console.log(preLog, _path, _key, payload, totalPayload);
  if ( _path.toLowerCase()=="app" && _key.toLowerCase()=="state" ){ appStateUpdate(payload, _path, _key); }
  else{ if ( totalPayload?.appState ){ appStateUpdate(totalPayload.appState, _path, _key); } }
}) );
/*
dataSubscriptionList.push( APP.data.subscribe("App", "State", async function(payload, _path, _key){
  let method = "app_state"; let preLog=classname+"."+method; //console.log(preLog, _path, _key, payload);
  let appState = getStore().getters[method];
  let newAppState = Object.assign({},appState, payload||{});
  getStore().commit(method, newAppState);
  if (APP && typeof(APP)==="object" ){ APP.state=getStore().getters[method]; }
  //vuex.save(newAppState);
}) );
*/

export default vuex
