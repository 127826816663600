<style>
    .TemplateSnipped {}
</style>

<template>
  <element :class="classname">
    {{ classname }}
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "TemplateSnipped";

    const APP = window.APP;
    const _h = APP.helper;

    //const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    //const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:function(){ return { demo:"Hello World" } } },
      },

      data: function(){ return {
        classname: classname,
      } },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },
      },

      watch: {},

      methods: {},

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
