var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('element',{class:_vm.classname},[_c('v-data-table',{staticClass:"_table-striped elevation-1",attrs:{"dense":"","disable-sort":_vm.disableSort,"headers":_vm.headerComp,"fixed-header":true,"height":600,"mobile-breakpoint":"0","items":_vm.valueComp,"items-per-page":_vm.itemsPerPage,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      '_items-per-page-all-text': _vm.$t('vuetify.dataFooter.itemsPerPageAll'),
      'items-per-page-options': [ 10, 20, 50, 100, -1 ],
      _itemsPerPageText: _vm.$t('vuetify.dataFooter.itemsPerPageText'),
      _pageText: _vm.$t('vuetify.dataFooter.pageText'),
    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('tr',_vm._l((item),function(colItem,colKey,colIndex){return _c('element',{key:'r'+index+'c'+colIndex},[(_vm.getHeader(colKey))?_c('td',{ref:index+'_'+colIndex,refInFor:true,attrs:{"id":index+'_'+colIndex}},[(_vm.getHeader(colKey).is=='v-text-field')?_c('v-text-field',_vm._g(_vm._b({attrs:{"colKey":colKey,"full-width":"","dense":"","hide-details":"","single-line":"","color":"accent","autocomplete":"no","autocorrect":"off","autocapitalize":"off"},on:{"focus":_vm.focus,"blur":_vm.blur,"keydown":_vm.keydown,"click":_vm.clicked},model:{value:(item[colKey]),callback:function ($$v) {_vm.$set(item, colKey, $$v)},expression:"item[colKey]"}},'v-text-field',(_vm.getHeader(colKey)||{}).bind,false),(_vm.getHeader(colKey)||{}).on)):_vm._e(),(!_vm.getHeader(colKey).is)?_c('v-text-field',_vm._g(_vm._b({staticClass:"plain",attrs:{"colKey":colKey,"readonly":"","disabled":"","full-width":"","dense":"","hide-details":"","single-line":"","color":"accent","autocomplete":"no","autocorrect":"off","autocapitalize":"off"},on:{"focus":_vm.focus,"blur":_vm.blur,"keydown":_vm.keydown,"click":_vm.clicked},model:{value:(item[colKey]),callback:function ($$v) {_vm.$set(item, colKey, $$v)},expression:"item[colKey]"}},'v-text-field',(_vm.getHeader(colKey)||{}).bind,false),(_vm.getHeader(colKey)||{}).on)):_vm._e()],1):_vm._e()])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }