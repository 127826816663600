<style>
    .v-time-pickerElement {}
</style>

<template>
  <element :class="classname">
    <v-menu ref="picker" v-model="picker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="time" :label="label" persistent-hint prepend-icon="mdi-clock-outline" placeholder="HH:mm" v-bind="attrs" v-on="on" autocomplete="no" autocorrect="off" autocapitalize="off"></v-text-field>
      </template>
      <v-time-picker v-model="time" color="accent" format="24hr"></v-time-picker>
    </v-menu>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "v-time-pickerElement";

    const APP = window.APP;
    const _h = APP.helper;

    const moment = window.moment;

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:function(){ return { demo:"Hello World" } } },
        value: { type: Date, _default: function(){ return moment().format("HH:mm"); }, },
        label: { type: String, default: function(){ return ""; }, },
      },

      data: function(){ return {
        classname: classname,
        picker: false,
        time: undefined,
      } },

      computed: {},

      watch: {
        value: {
          immediate: true,
          handler: function(newVal, oldVal){
            let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            self.time = newVal;
          }
        },
        time: function(newVal, oldVal){
          this.$emit('input', newVal);
        }
      },

      methods: {},

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
