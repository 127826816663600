<style>
  .PasswordChangeView {  }
</style>

<template>
  <element :class="classname">
    <v-sheet class="view">

      <v-row dense no-gutter align="start" justify="center" class="h100">
        <v-col sm=12 md=10>
          <v-card class="pa-2">

            <h1 class="title">{{$t(classname+".changePassword")}}</h1>
            <v-row align="center" justify="center">
              <v-col cols=10> <ComponentsElement ref="passwordForm" :view="passwordFormModel" :error="passwordFormErrors" v-model="passwordFormValues"></ComponentsElement> </v-col>
              <v-col cols=10 class="text-center"> <v-btn color="accent" @click="changePassword"> <v-icon left>mdi-lock-reset</v-icon> {{$t(classname+".changePassword")}} </v-btn> </v-col>
            </v-row>

          </v-card>
        </v-col>
      </v-row>

    </v-sheet>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-11-13
  *	@version	1.1.1
  *	@updated	2020-11-13
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "PasswordChangeView";

    const APP = window.APP;
    const _h = APP.helper;

    const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      props: [],

      data: function(){ return {
          classname: classname,
          
          passwordFormModel: {
            oldPassword: { is:"v-text-field", bind: { label:i18n(classname+".currentPassword"), type:"password" } },
            newPassword: { is:"v-text-field", bind: { label:i18n(classname+".newPassword"), type:"password", } },
            newPasswordRepeat: { is:"v-text-field", bind: { label:i18n(classname+".newPasswordRepeat"), type:"password", } },
        },
          passwordFormValues: {},
          passwordFormErrors: {},
      }; },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          if (!appState.general?.isLocalServiceConnected){ APP.global.route.change(APP.config.startpage); }
          return appState;
        },
      },

      watch: {},

      methods: {
        changePassword: function(){
          let method="changePassword"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          const appState = self.appState;

          let formData = JSON.parse(JSON.stringify(self.passwordFormValues));

          self.passwordFormErrors = {}

          //console.log(preLog, formData?.oldPassword, _h.SHA1(formData?.oldPassword||"") ,"!==", appState.local.userActive?.passwordHash )

          if ( !formData?.oldPassword || _h.SHA1(formData?.oldPassword) !== appState.local.userActive?.passwordHash ){
            let eMsg = i18n(classname+".currentPasswordInvalid");
            self.passwordFormErrors = { oldPassword: eMsg }
            return;
          }

          if ( !formData?.newPassword || formData.newPassword.trim().length<=0  ){
            let eMsg = i18n(classname+".newPasswordMinLength");
            self.passwordFormErrors = { newPassword: eMsg }
            return;
          }

          if ( !formData?.newPasswordRepeat || formData.newPasswordRepeat.trim().length<=0  ){
            let eMsg = i18n(classname+".newPasswordRepeat");
            self.passwordFormErrors = { newPasswordRepeat: eMsg }
            return;
          }

          if ( formData?.newPassword !== formData?.newPasswordRepeat ){
            let eMsg = i18n(classname+".newPasswordRepeatNotMatch");
            self.passwordFormErrors = { newPassword: eMsg, newPasswordRepeat:eMsg }
            //notify( {type:"error", text:eMsg} );
            return;
          }
          if ( _h.SHA1(formData?.oldPassword) === _h.SHA1(formData?.newPassword) ){
            let eMsg = i18n(classname+".newAndOldPasswordAreSame");
            notify( {type:"info", text:eMsg} );
            return;
          }

          APP.global.getOverlay().show();
          let sendObj = {
            personId:  appState.local?.userActive?.id,
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
          }

          APP.api.send("App", "UpdatePassword", sendObj, {
            success: function(response){
              //console.log(preLog, response);
              if ( response && response.success ){ APP.global.route.change(APP.config.startpage, true); }
              else{
                let eMsg=i18n(classname+".onChangeError");
                notify({ type:"error", text:eMsg });
              }
            },
            error: function(eMsg, eCode, err){
              console.error(preLog, "eMsg="+eMsg, "eCode=",eCode, "err=",err);
              notify({ type:"error", text:eMsg });
            },
            complete: function(data){
              self.passwordFormValues = {};
              self.passwordFormErrors = {};
              APP.global.getOverlay().hide();
            }
          });
          
        }
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self=this; if( self && self._uid ){ _public.component=self; }
        APP.global.getOverlay().clear();
        APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this;

        APP.global.basepage.change("BaselineBasePage");

        if( self && typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
            APP.global.getOverlay().hide();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    return _public;
  })();
</script>
