<style>
  .BookingStatsView .booking{ border-bottom: 1px solid #c4c4c4; }
  .BookingStatsView .v-window__prev{ top: 0px; }
  .BookingStatsView .v-window__next{ top: 0px; }
  .BookingStatsView .canceled { text-decoration: line-through; }
</style>

<template>
  <element :class="classname">
    <v-sheet class="view">

      <v-row dense no-gutter align="start" justify="center">
        <v-col sm=12 md=10>
          <v-card class="pa-2">

            <h1 class="title">
              {{$t(classname+".dayBookings")}} 
              <v-btn color="info" :loading="manuelServerSnycBtnLoading" :disabled="manuelServerSnycBtnLoading" class="float-right" @click="manuelServerSnyc"><v-icon>mdi-sync</v-icon></v-btn>
            </h1>
            <v-carousel height="auto" style="min-height: 75vh;" class="mt-2" :show-arrows=true :continuous="false" hide-delimiters :value="groupedUserBookings.length-1">
              <v-carousel-item v-for="(group, index) in groupedUserBookings" :key="index">

                <v-row>
                  <v-col cols="12" class="subtitle-2 text-center">{{group.title}}</v-col>
                </v-row>

                <v-row class="text-center">
                  <v-col> <h3 class="subtitle-2 grey--text">{{$t(classname+".time")}}</h3> </v-col>
                  <v-col> <h3 class="subtitle-2 grey--text">{{$t(classname+".action")}}</h3> </v-col>
                  <v-col> <h3 class="subtitle-2 grey--text"></h3> </v-col>
                  <v-col> <h3 class="subtitle-2 grey--text"></h3> </v-col>
                </v-row>

                <div v-if="group && group.bookings && group.bookings.length>0">
                  <v-row class="booking text-center" v-for="(booking, index) in group.bookings" :key="index">
                    <!-- <v-col> <v-chip :style="booking.style">{{booking.codeA}}</v-chip> </v-col> -->
                    <!-- <v-col> {{getTimestamp(booking)}} </v-col> -->

                    <v-col> <span style="display: block; padding-top: 2px;">{{booking.bookingDateDisplay}}</span> </v-col>

                    <v-col> <v-chip :style="booking.style">{{booking.codeA}}</v-chip> </v-col>

                    <v-col> <v-chip color="info" v-if="booking.codeB">{{booking.codeB}}</v-chip> </v-col>

                    <v-col class="text-center">
                      <v-icon v-if="booking.coordinates && booking.hasCoordinates" color="primary" style="transform: rotate(35deg);">mdi-navigation</v-icon>
                      <v-icon :color="getDeliveryStateType(booking).color">{{getDeliveryStateType(booking).icon}}</v-icon>
                    </v-col>

                    <div v-if="booking.isNotAssignable" class="w100" style="position: relative; top: -30px;"> <div style="width: 90%; height: 1px; margin:auto; background-color: gray;"></div> </div>
                  </v-row>
                </div>
                <v-row v-else>
                  <v-col xs=12 class="text-center"> - {{ $t(classname+".noBookings") }} - </v-col>
                </v-row>

              </v-carousel-item>
            </v-carousel>

          </v-card>
        </v-col>
      </v-row>

    </v-sheet>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-11-13
  *	@version	1.1.1
  *	@updated	2020-11-13
  *
  * @link       http://
  *
  *	@param		{Object} 	args 			Default argument object.
  *	@param		{type} 		args.key 		description
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';
  export default (function(args){
    // Private
    //------------
    const classname = "BookingStatsView";

    const APP = window.APP;
    const _h = APP.helper;
    const moment = window.moment;

    //const i18n = function(args){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(args); } }
    const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      props: [],

      data: function(){ return {
          classname: classname,

          manuelServerSnycBtnLoading: false,
      }; },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self=this;
          const appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },
        userSections: function(){
          let method="userSections"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let userSections = undefined;
          if (self.appState.sections && typeof(self.appState.local.userActive?.id)!="undefined"){ userSections = self.appState.sections[self.appState.local.userActive.id]; }
          return userSections;
        },

        userBookings: function(){
          let method="userBookings"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let userId = self.appState.local?.userActive?.id;
          let userBookings = (self.appState.bookings||[]).filter(function(bookingItem, bookingIndex){ return bookingItem.userId == userId; });
          userBookings = userBookings.concat((self.appState.local?.bookings||[]).filter(function(bookingItem, bookingIndex){ return bookingItem.userId == userId; }));
          userBookings = userBookings.sort(function(a, b){
            if ( a.bookingDateUtc > b.bookingDateUtc ){ return -1; }
            if ( a.bookingDateUtc < b.bookingDateUtc ){ return 1; }
            return 0;
          });
          return userBookings;
        },

        groupedUserBookings: function(){
          let method="groupedUserBookings"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let groupedBookings = [];
          let dayHistoryLength = self.appState.general.dayHistoryLength||7;
          while ( dayHistoryLength>=0 ){
            let groupDate = moment().subtract(dayHistoryLength, 'days');
            groupedBookings.push({
              bookings: self.userBookings.filter( function(item){ return moment(item.bookingDateUtc).isSame(groupDate.format("YYYY-MM-DD"), 'day'); } ),
              dateTime: groupDate.format("YYYY-MM-DDT00:00:00"),
              title: groupDate.format("dd L"),
            });
            dayHistoryLength--;
          }
          return groupedBookings;
        }

      },

      watch: {},

      methods: {
        getDeliveryStateType: function(booking){
          let method="getDeliveryStateType"; let preLog=classname+".methods."+method; //console.log(preLog, "called", booking);
          let result = { color:"warning", icon:"mdi-check" };
          // 1,2,3,4,8 (15 überall)
          //  1 = OnMobileDevice -> orangener haken
          //  2 = OnTerminalHost -> nicht verfügbar auf app
          //  4 = OnLocalService (nicht beachten) -> nicht verfügbar auf app
          //  8 = OnTidePro -> nicht verfügbar auf app
          // 14 =
          //if ( booking && booking.deliveryStateType && booking.deliveryStateType.toString()!=="3" ){ result={ color:"success", icon:"mdi-check" } };
          if ( booking && booking.deliveryStateType && booking.deliveryStateType ){
            let state = booking.deliveryStateType;
            try {
              state = parseInt(booking.deliveryStateType);
              //console.log(booking.bookingDateUtc, booking.bookingDateDisplay, state)
              if ( (state & 1) == 1 ){ result={ color:"warning", icon:"mdi-check" } }
              if ( (state & 3) == 3 ){ result={ color:"success", icon:"mdi-check" } }
              if ( (state & 8) == 8 ){ result={ color:"success", icon:"mdi-check-all" } }
            }catch(err){ console.error(preLog, err); }
          }
          return result;
        },

        manuelServerSnyc: function(){
          const self=this; let method="manuelServerSnyc"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          self.manuelServerSnycBtnLoading=true;
          APP.js.sendLocalBookings(true, function(){
            setTimeout(function(){ 
              APP.api.send("APP","State", null, {
                success:function(res){
                  notify({ type:"success", title:"Server Sync", text:"Server Sync abgeschlossen" });
                },
                error: function(err, code){
                  let postMessage = "";
                  if ( !isNaN(code) ){ postMessage = " ("+code+")"; }
                  notify({ type:"error", title:"Server Sync", text:"Server Sync fehlgeschlagen" + postMessage });
                },
                complete: function(){
                  self.manuelServerSnycBtnLoading=false;
                }
              }, "rest");
            }, 2500);
          });
        }
      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self=this; if( self && self._uid ){ _public.component=self; }
        APP.global.getOverlay().clear();
        APP.global.getOverlay().show();
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted: async function(){
        let method="mounted"; let preLog=classname+"."+method; console.log(preLog, "called");
        const self=this;

        if ( APP.global.basepage.change("BaselineBasePage") ){ return; }

        if ( APP.js?.sendLocalData ){ APP.js.sendLocalData(); }

        self.manuelServerSnycBtnLoading=false;

        if( self && typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="mounted"+"-"+"$nextTick"; let preLog=classname+"."+method; //console.log(preLog, "uiRendered");
            APP.global.getOverlay().hide();
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //errorCaptured: async function(){ let method="errorCaptured"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //activated: async function(){ let method="activated"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //deactivated: async function(){ let method="deactivated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

    }

    return _public;
  })();
</script>
