<style>
    .MultilanguageInputFormElement .tdcard .card-body, .MultilanguageInputFormElement .card-footer { padding: 0px 20px 0px 20px; }
    /* .MultilanguageInputFormElement .tdcard.form-only .card-body, .MultilanguageInputFormElement .tdcard.form-only .card-footer { padding: 0px 0px 0px 0px; } */
    /*
    .MultilanguageInputFormElement .no-header .card-header { display: none; }
    .MultilanguageInputFormElement .no-header .card-body { padding: 0px 20px 0px 20px; }
    */
</style>

<template>
  <element :class="classname">

    <v-card :class="[formOnly ? 'tdcard form-only' : 'tdcard']" elevation="0">

      <div v-if="!formOnly" class="card-header">
        <v-card-title> {{$t("global.edit")}} </v-card-title>
        <v-card-subtitle class="hidden"> {{$t("global.edit")}} </v-card-subtitle>
      </div>

      <div class="card-body">
        <v-row dense no-gutters>
          <v-col cols=12>
            <v-row dense> <v-col> <v-subheader class="accent--text text-h6 pa-0">{{$t(label)}}</v-subheader> </v-col></v-row>
            <ComponentsElement v-model="languageData" :view="labelViewModel" :error="errorComp"></ComponentsElement>
          </v-col>
        </v-row>
      </div>

      <div class="card-footer">
        <v-card-actions v-if="!formOnly" class="py-5">
          <v-btn v-if="_events.cancel||onCancel" class="mr-2" color="secondary" @click="cancelClicked">{{$t("global.cancel")}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="_events.previous" class="mr-2" color="accent" @click="_events.previous"> {{$t("global.back")}} </v-btn>
          <v-btn v-if="_events.accept||onAccept" color="accent" @click="acceptClicked">{{$t("global.apply")}} </v-btn>
        </v-card-actions>
      </div>

    </v-card>

  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "MultilanguageInputFormElement";

    const APP = window.APP;
    const _h = APP.helper;

    const i18n = function(key, langCode){ if (typeof(APP?.vue?.vm?.$t)==="function"){ return APP.vue.vm.$t(key, langCode); } }
    //const notify = function(args){ if( APP.global?.getNotification()?.add ){ APP.global.getNotification().add(args); } }

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:function(){ return { demo:"Hello World" } } },
        label: { type:String, default:function(){ return "global.label"; } },
        value: { type:String, default:function(){ return "";} }, // "global.edit"
        error: { type:Object, default:function(){ return {}; } },
        formOnly: { type:Boolean, default:function(){ return false; } },
        onAccept: { type:Function, _default:function(){ return function(){}; } },
        onCancel: { type:Function, _default:function(){ return function(){}; } },
      },

      data: function(){ return {
        classname: classname,
        dataInitStr: undefined,

        //labelsData: {}
        languageData: {},
        labelViewModel: undefined,
      } },

      computed: {
        appState: function(){
          let method="appState"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let appState = self.$store.getters.app_state||{}; //console.log(preLog, appState);
          return appState;
        },

        errorComp: function(){
          let method="errorComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let result = {};
          for ( let errorKey in self.error ){
            result[errorKey.split(".").pop()] = self.error[errorKey];
          }
          //console.log(preLog, result)
          return result;
        },

      },

      watch: {

        value: {
          deep: true,
          immediate: true,
          handler: function(newVal, oldVal){
            let method="value"; let preLog=classname+".watch."+method; //console.log(preLog, "called");
            const self = this;
            self.createLabelViewModel();
            let languages = self.appState.languages;
            self.languageData = {}
            for ( let langKey in languages ){
              let lang = languages[langKey];
              if ( !([langKey] in self.languageData) ){
                self.languageData[langKey] = "";
                if ( self.value && i18n(self.value, langKey)!==self.value ){ self.languageData[langKey]=i18n(self.value, langKey); }
              }
            }
          }
        },

      },

      methods: {
        restoreInit : function(){
          let method="restoreInit"; let preLog=classname+".methods."+method; //console.log(preLog, "called");
          const self = this;
          let initStr = self.dataInitStr||{};
          Object.assign( self, JSON.parse(initStr) );
          self.dataInitStr = initStr;
        },

        createLabelViewModel: function(){
          let method="labelViewModelComp"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          let viewModel = {};
          let languages = self.appState.languages;
          for ( let langKey in languages ){
            let lang = languages[langKey];
            viewModel[langKey] = {
              is:"v-text-field", cbind:{ cols:12, sm:6 },
              bind: { name:lang.key, label:i18n(lang.name), labelleft:true, },
            }
          }
          self.labelViewModel = viewModel;
        },

        acceptClicked: function(event){
          let method="acceptClicked"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          self.$emit("accept", self.languageData, self);
          if (typeof(self.onAccept)==="function"){ self.onAccept(self.languageData, self); }
        },

        cancelClicked: function(event){
          let method="cancelClicked"; let preLog=classname+".computed."+method; //console.log(preLog, "called");
          const self = this;
          self.$emit("cancel", self);
          if (typeof(self.onCancel)==="function"){ self.onCancel(self); }
        },

      },

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this; window.xx = self;

        self.dataInitStr = JSON.stringify(self.$data);

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
