<style>
  .v-date-pickerElement .v-input.text-center input{ text-align: center; }
  .v-date-pickerElement .v-input.text-right input{ text-align: right; }
</style>

<template>
  <element :class="classname">
    <v-menu ref="datePicker" v-model="datePicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field 
          v-bind="attrs" 
          v-on="on"
          v-model="date"
          :label="label" 
          :hide-details="hideDetails"
          :class="inputClass"
          :style="inputStyle"
          persistent-hint 
          :prepend-icon="prependIcon" 
          autocomplete="no" autocorrect="off" autocapitalize="off">
        </v-text-field>
      </template>
      <v-date-picker v-model="date" :type="type" :class="classStyle" color="accent" @input="datePicker=false"></v-date-picker>
    </v-menu>
  </element>
</template>


<script type="text/javascript">
  /*
  *	@author		HM
  *	@date: 		2020-05-09
  *	@version	0.1.1
  *	@updated	2020-05-09
  * @link       http://
  *
  *	@return 	{Object} 	_t.public 			Instance of this class.
  */
  'use strict';

  export default (function(){
    // Private
    //------------
    const classname = "v-date-pickerElement";

    const APP = window.APP;
    const _h = APP.helper;
    const moment = window.moment;

    // Public
    //------------
    const _public = {
      template: '#'+classname,

      components: {},

      props: {
        //args: { type: Object, default:function(){ return { demo:"Hello World" } } }
        value: { type:String, _default: function(){ return moment().format("YYYY-MM-DD"); }, },
        label: { type:String, default: function(){ return ""; }, },
        type: { type:String, default: function(){ return "date"; }, },
        classStyle: { type:String, default: function(){ return ""; }, },
        inputClass: { type:String, default: function(){ return ""; }, },
        inputStyle: { type:String, default: function(){ return ""; }, },
        'prepend-icon' : { type:String, default: function(){ return "mdi-calendar"; }, },
        'hide-details': { type:Boolean, default: function(){ return false; }, },
      },

      data: function(){ return {
        classname: classname,
        datePicker: false,
        date: "",
      } },

      computed: {
        //hideDetails: function(){ return self['hide-details']; }
      },

      watch: {
        date: function(newVal, oldVal){
          //console.log("watch", newVal, self.value)
          this.$emit('input', newVal);
          this.$emit('change', newVal);
        }
      },

      methods: {},

      //beforeCreate: async function(){ let method="beforeCreate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      created: async function(){
        let method="created"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;
      },

      //beforeMount: async function(){ let method="beforeMount"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      mounted : function(){
        let method="mounted"; let preLog=classname+"."+method; //console.log(preLog, "called");
        const self = this;

        self.date = self.value;

        if (typeof(self.$nextTick)==="function"){
          self.$nextTick(function(){ // Code that will run only after the entire view has been rendered
            let method="$nextTick"; let preLog=classname+".mounted-"+method; //console.log(preLog, "uiRendered");
          });
        }
      },

      //beforeUpdate: async function(){ let method="beforeUpdate"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //updated: async function(){ let method="updated"; let preLog=classname+"."+method; console.log(preLog, "called"); },

      //beforeDestroy: async function(){ let method="beforeDestroy"; let preLog=classname+"."+method; console.log(preLog, "called"); },
      //destroyed: async function(){ let method="destroyed"; let preLog=classname+"."+method; console.log(preLog, "called"); },
    }

    return _public;
  })();
</script>
